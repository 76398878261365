import FuzzySearch from "fuzzy-search";
import { removeWhiteSpace } from "utils/string";

interface ICommonAttributes {
  firstName: string;
  lastName: string;
  [key: string]: any; // This is for allowing any other properties
}

export default function userFuzzySearch<TData extends ICommonAttributes>(
  users: TData[],
  searchQuery: string
): TData[] {
  const usersWithFullName = users.map((user) => ({
    ...user,
    fullName: `${user.firstName} ${user.lastName}`,
  }));

  return new FuzzySearch(usersWithFullName, [
    "firstName",
    "lastName",
    "fullName",
    "email",
    "profile.company",
  ]).search(removeWhiteSpace(searchQuery));
}
