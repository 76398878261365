import { useState, useEffect } from 'react'
import { postcodeValidator } from 'postcode-validator';


// const useValidatePostalCode = (props={}: Props) => {
const useValidatePostalCode = ({postalCode, country}: any) => { 
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // Because postalCode is not required input field and shouldn't stop going forward
    if (postalCode === '') {
      setIsValid(true);
      return
    }

    setIsValid(postcodeValidator(postalCode.trim(), country || "CA"));
  }, [postalCode, country]);

  return {
    isValid
  }
}

export default useValidatePostalCode