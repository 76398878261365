/////////////////////
// Desired Assets Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC, useEffect } from "react";

// Design Imports
import { FormControl, FormLabel, Text, Textarea } from "@chakra-ui/react";

// Layout and Section Imports
import AssetsCheckBoxes from "./AssetsCheckBoxes";

// Data Imports
import WrapStep from "components/ui/steps/WrapStep";
import useDesiredAssetsStep from "./useDesiredAssetsStep";

// Context API

// Interfaces
interface Props {
  assets?: any[];
  prevStep?: () => any;
  nextStep?: () => any;
  onSelected?:any;
  onStepClick?: () => void;
  isStepLoading?:any;
}

// Functions

const DesiredAssetsStep: FC<Props> = ({
  assets,
  prevStep,
  nextStep,
  onSelected,
  onStepClick,
  isStepLoading,
}) => {
  const {
    defaultValues,
    assetDescription,
    setSelectedAssets,
    setAssetDescription,
    isLoading,
    selectedAssets,
  } = useDesiredAssetsStep();

  // Purpose of this hook to stop user to perform backend action until user select any of the desired assets
  useEffect(() => {
    if (!onSelected) return;
    // This is a comment that was written by the assistant to help the user
    onSelected(selectedAssets);
  }, [selectedAssets]);

  useEffect(() => {
    if (!isStepLoading) return;

    isStepLoading(isLoading);

    
  }, [isLoading]);

  if (!prevStep && !nextStep && !onStepClick)
    return (
      <RenderDesireAssets
        assets={assets}
        defaultValues={defaultValues}
        assetDescription={assetDescription}
        setSelectedAssets={setSelectedAssets}
        setAssetDescription={setAssetDescription}
      />
    );

  return (
    <WrapStep
      title="Desired Assets"
      stepLoading={isLoading}
      prevStep={prevStep}
      nextStepLoading={isLoading}
      onStepClick={onStepClick}
      disableNextButton={selectedAssets.length === 0}
      disableNextButtonReason={"Select at least one desired asset."}
      nextStep={() => {
        nextStep && nextStep();
      }}
    >
      <RenderDesireAssets
        assets={assets}
        defaultValues={defaultValues}
        assetDescription={assetDescription}
        setSelectedAssets={setSelectedAssets}
        setAssetDescription={setAssetDescription}
      />
    </WrapStep>
  );
};

function RenderDesireAssets({
  assets,
  defaultValues,
  setSelectedAssets,
  assetDescription,
  setAssetDescription,
}: any) {
  return (
    <>
      {/* Title */}
      <Text>What is your request?</Text>

      {/* Assets Options */}
      <AssetsCheckBoxes
        assets={assets}
        defaultValues={defaultValues}
        onSelectedAssets={(selectedAssets: any) => setSelectedAssets(selectedAssets)}
      />

      {/* Asset description */}
      <FormControl id="AssetDescription">
        <FormLabel>Asset Description</FormLabel>
        <Textarea
          value={assetDescription}
          onChange={(e) => setAssetDescription(e.target.value)}
          placeholder="Any comments on what you want to us to do for you?"
        />
      </FormControl>
    </>
  );
}

export default DesiredAssetsStep;
