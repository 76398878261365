/////////////////////
// Output Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";
import _ from "lodash";

// Design Imports
import { BoxProps, SimpleGrid, Stack } from "@chakra-ui/react";

// Layout and Section Imports
import { OutputCardForSteps } from "components/ui/common/OutputCardForSteps";
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";

// Data Imports
import { assets, AssetProps } from "data/projectWizardData";

// Interfaces
interface Props {
  styles: BoxProps;
  data: any;
  projectId?: string;
  readOnly?: boolean;
}

// Functions

const OutputCard: FC<Props> = ({ data, styles, projectId, readOnly }) => {
  return (
    <Card id={projectId} {...styles}>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "6", md: "14" }}
      >
        <CardContent>
          <CardHeader title="Output Selected" action={<></>} />
          <SimpleGrid
            maxW="7xl"
            columns={{ base: 1, md: 2 }}
            spacing={5}
            py={5}
          >
            {_.intersectionBy(assets, data, "title").map(
              (currentValue: AssetProps) => {
                const { title, id, actionData } = currentValue;
                return (
                  <OutputCardForSteps
                    key={id}
                    id={id}
                    title={title}
                    data={actionData}
                    readOnly={readOnly}
                    selectedData={data.find(
                      (selected: any) => selected.title === title
                    )}
                  />
                );
              }
            )}
          </SimpleGrid>
        </CardContent>
      </Stack>
    </Card>
  );
};

export default OutputCard;
