import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    tabIndex: 0,
    clientIndex: -1,
    projectIndex: -1,
    addTask: false,
    taskId: "",
    subTaskId: "",
    updatedSubtask: null,
    updatedStaff: "",
    shouldShowStaffAssignee: false,
    createProject: false,
  },
  reducers: {
    changeShouldShowStaffAssignee: (state, action) => {
      state.shouldShowStaffAssignee = action.payload;
    },
    changeTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
    changeClientIndex: (state, action) => {
      state.clientIndex = action.payload;
    },
    changeProjectIndex: (state, action) => {
      state.projectIndex = action.payload;
    },
    changeAddTask: (state, action) => {
      state.addTask = action.payload;
    },
    changeTaskId: (state, action) => {
      state.taskId = action.payload;
    },
    changeSubTaskId: (state, action) => {
      state.subTaskId = action.payload;
    },
    changeUpdatedSubtask: (state, action) => {
      state.updatedSubtask = action.payload;
    },
    updatedStaff: (state, action) => {
      state.updatedStaff = action.payload;
    },
    createProject: (state, action) => {
      state.createProject = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeTabIndex,
  changeClientIndex,
  changeProjectIndex,
  changeAddTask,
  changeTaskId,
  changeSubTaskId,
  changeUpdatedSubtask,
  updatedStaff,
  createProject,
  changeShouldShowStaffAssignee,
} = settingsSlice.actions;

export default settingsSlice.reducer;
