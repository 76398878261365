export const Stage = () =>{
    return(
        <svg id="Group_122" data-name="Group 122" xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 31 31">
        <circle id="Ellipse_27" data-name="Ellipse 27" cx="15.5" cy="15.5" r="15.5" fill="#526bd8"/>
        <path id="ic_timeline_24px" d="M17.67,7.515a1.52,1.52,0,0,1-1.515,1.515,1.288,1.288,0,0,1-.386-.053l-2.7,2.69a1.339,1.339,0,0,1,.053.394,1.515,1.515,0,0,1-3.031,0,1.338,1.338,0,0,1,.053-.394L8.214,9.736a1.49,1.49,0,0,1-.788,0L3.978,13.191a1.288,1.288,0,0,1,.053.386,1.515,1.515,0,1,1-1.515-1.515,1.288,1.288,0,0,1,.386.053L6.357,8.667A1.338,1.338,0,0,1,6.3,8.273a1.515,1.515,0,1,1,3.031,0,1.338,1.338,0,0,1-.053.394L11.214,10.6a1.49,1.49,0,0,1,.788,0l2.69-2.7a1.288,1.288,0,0,1-.053-.386,1.515,1.515,0,1,1,3.031,0Z" transform="translate(6.165 5.407)" fill="#fff"/>
        </svg>

    )
    

}
export const Person = () => {
    return(
    <svg id="Group_28" data-name="Group 28" xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 32 32">
    <circle id="Ellipse_28" data-name="Ellipse 28" cx="16" cy="16" r="16" fill="#e74f4f"/>
    <path id="ic_view_quilt_24px" d="M9.107,16.065h4.256V10.958H9.107ZM4,16.065H8.256V5H4Zm10.214,0H18.47V10.958H14.214ZM9.107,5v5.107H18.47V5Z" transform="translate(5 4.973)" fill="#fff"/>
    </svg>
    )

}

export const TimeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 31 31">
        <g id="Group_138" data-name="Group 138" transform="translate(-1339.564 -541.5)">
            <g id="Group_123" data-name="Group 123" transform="translate(1339.564 541.5)">
            <circle id="Ellipse_27" data-name="Ellipse 27" cx="15.5" cy="15.5" r="15.5" fill="#6a30de"/>
            </g>
            <path id="ic_update_24px" d="M17.943,8.911H12.314L14.589,6.57a5.843,5.843,0,0,0-8.2-.083,5.707,5.707,0,0,0,0,8.127,5.827,5.827,0,0,0,8.2,0,5.407,5.407,0,0,0,1.693-4.059h1.66a7.754,7.754,0,0,1-2.192,5.222,7.516,7.516,0,0,1-10.559,0A7.338,7.338,0,0,1,5.175,5.333a7.461,7.461,0,0,1,10.5,0L17.943,3Zm-7.056-1.76v3.528l2.905,1.727-.6,1L9.641,11.3V7.151Z" transform="translate(1344.592 546.529)" fill="#fff"/>
        </g>
        </svg>

    )
}
export const EditIcon = () => {
    return (
        <svg cursor="pointer" id="ic_border_color_24px" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 19.034 19.036">
        <path id="Path_81" data-name="Path 81" d="M14.9,5.551,11.931,2.577,4,10.508v2.974H6.974ZM17.252,3.2a.79.79,0,0,0,0-1.118L15.4.229a.79.79,0,0,0-1.118,0L12.724,1.784,15.7,4.758Z" transform="translate(-0.828 0.002)"/>
        <path id="Path_82" data-name="Path 82" d="M0,20H19.034v3.172H0Z" transform="translate(0 -4.137)" fill="rgba(0,0,0,0.36)"/>
        </svg>

    )

}