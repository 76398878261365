import { useDispatch, useSelector } from "react-redux";
import {
  resetUserState,
  changeUserState,
  changeNewsLetterNotifications,
  changeAppUpdatesNotifications,
  changePromotionalNotifications,
} from "redux/reducers/userReducer";
import { RootState } from "redux/store";
import { IUser } from "types/user";

const useUserStore = () => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  return {
    user,
    updateUser: (user: IUser) => dispatch(changeUserState(user)),
    resetUser: () => dispatch(resetUserState()),
    changeNewsLetterNotifications: (value: boolean) =>
      dispatch(changeNewsLetterNotifications(value)),
    changeAppUpdatesNotifications: (value: boolean) =>
      dispatch(changeAppUpdatesNotifications(value)),
    changePromotionalNotifications: (value: boolean) =>
      dispatch(changePromotionalNotifications(value)),
  };
};

export default useUserStore;
