import { ITaskStage } from "types/task";

const useTaskStages = () => {
  const floorPlan3D: ITaskStage[] = [
    { label: "Information Gathering", completion: 7.14 },
    { label: "General Layout", completion: 14.32 },
    { label: "General Area Considerations", completion: 21.46 },
    { label: "Drafting", completion: 28.6 },
    { label: "Modeling", completion: 35.74 },
    { label: "Detailing", completion: 42.88 },
    { label: "Material Selection", completion: 50 },
    { label: "Texturing", completion: 57.16 },
    { label: "Lighting", completion: 64.3 },
    { label: "Camera Placements", completion: 71.44 },
    { label: "Rendering", completion: 78.58 },
    { label: "Final Touches", completion: 85.72 },
    { label: "Waiting Customer Approval", completion: 92.86 },
    { label: "Finished Current Draft", completion: 100 },
  ];
  const floorPlan2D: ITaskStage[] = [
    { label: "Information Gathering", completion: 7.14 },
    { label: "General Layout", completion: 14.32 },
    { label: "General Area Considerations", completion: 21.46 },
    { label: "Walls", completion: 28.6 },
    { label: "Windows and Doors", completion: 35.74 },
    { label: "Kitchens", completion: 42.88 },
    { label: "Bathrooms and Laundry", completion: 50 },
    { label: "Bedrooms", completion: 57.16 },
    { label: "Other types of rooms", completion: 64.3 },
    { label: "Exterior Spaces", completion: 71.44 },
    { label: "Changes to Representation", completion: 78.58 },
    { label: "Publishing", completion: 85.72 },
    { label: "Waiting Customer Approval", completion: 92.86 },
    { label: "Finished Current Draft", completion: 100 },
  ];
  const others: ITaskStage[] = [
    { label: "Information Gathering", completion: 11 },
    { label: "Layout Creation or Template", completion: 22 },
    { label: "Color Scheme Adjustments", completion: 33 },
    { label: "Images", completion: 44 },
    { label: "Content", completion: 55 },
    { label: "Grammar/Spell Checking", completion: 66 },
    { label: "Final Touches", completion: 77 },
    { label: "Customer Approval", completion: 88 },
    { label: "Publishing", completion: 100 },
  ];
  return {
    floorPlan3D,
    floorPlan2D,
    others,
  };
};

export default useTaskStages;
