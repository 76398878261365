import { useDispatch, useSelector } from "react-redux";
import { resetStaffState, changeStaffState } from "redux/reducers/staffReducer";
import { RootState } from "redux/store";
import { IStaff } from "types/staff";

const useStaffStore = () => {
  const staffMember = useSelector((state: RootState) => state.staff);
  const dispatch = useDispatch();

  return {
    staffMember,
    updateStaffMember: (staffMember: IStaff) =>
      dispatch(changeStaffState(staffMember)),
    resetStaffMember: () => dispatch(resetStaffState()),
  };
};

export default useStaffStore;
