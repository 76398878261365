export default function getFileFormat({
  format,
  url,
}: {
  format: string;
  url: string;
}) {
  if (!format) {
    const totalBricks = url.split(".");
    const format = totalBricks[totalBricks.length - 1];

    return format;
  }

  return format;
}
