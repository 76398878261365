import { Stack } from "@chakra-ui/react";
import useClientStore from "hooks/useClientStore";
import useProjectStore from "hooks/useProjectStore";
import useSettingStore from "hooks/useSettingStore";
import NewProjectPage from "pages/NewProjectPage";
import MainSide from "../ui/Views/MainSide";
import Status from "../ui/Views/Status";

const ViewBar = () => {
  const { client } = useClientStore();
  const { project } = useProjectStore();
  const { createProject } = useSettingStore();


  if (!!createProject && !!client)
    return (
      <Stack spacing={{ base: "6", md: "8" }} h="full">
       <NewProjectPage/>
      </Stack>
    )

  return (
    <Stack spacing={{ base: "6", md: "8" }} h="full">
      <MainSide />
      {(!client || !project) && <Status />}
    </Stack>
  );
};

export default ViewBar;
