import {
  AvatarGroup,
  Box,
  Circle,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdTextSnippet } from "react-icons/md";
import { IUser } from "types/user";
import { FaUser } from "react-icons/fa";
import useFetchUsers from "hooks/useFetchUsers";
import TooltipAvatar from "../common/TooltipAvatar";
import getFullName from "utils/getFullName";
import { motion } from "framer-motion";
import { FC, useEffect } from "react";
import { TiArrowSortedUp } from "react-icons/ti";
import { BiTask } from "react-icons/bi";
import getStatusColorScheme from "utils/getStatusColorScheme";
import { IProject } from "types/project";

interface Props {
  user: IUser;
}
const UserNav: FC<Props> = ({ user }) => {
  const nav = useDisclosure();
  const { staff, clients } = useFetchUsers();

  useEffect(() => {
    return () => {
      nav.onClose();
    };
  }, [user]);

  return (
    <>
      <Box
        bg={nav.isOpen ? "gray.100" : "inherit"}
        px="4"
        overflow="hidden"
        rounded="md"
      >
        <Flex align="center" justify="space-between" height="3rem" w="full">
          <HStack
            alignItems="center"
            spacing="6"
            w="full"
            justify="space-between"
          >
            <HStack flex="3" spacing="2" align="flex-start">
              <Tooltip
                label={user.role === "customer" ? "Client" : "Staff Member"}
                placement="top"
                hasArrow
              >
                <Circle
                  size="21px"
                  bg={
                    !!clients.isClientPaused(user._id)
                      ? "blackAlpha.700"
                      : "green"
                  }
                  color="white"
                >
                  <FaUser fontSize="14px" />
                </Circle>
              </Tooltip>
              <HStack spacing={4}>
                <Text noOfLines={1} fontSize="md" fontWeight="semibold">
                  {user.firstName} {user.lastName}
                </Text>
                {!!clients.isClientPaused(user._id) ? (
                  <Tag colorScheme={getStatusColorScheme("paused")}>Paused</Tag>
                ) : null}
              </HStack>
            </HStack>
            <HStack flex="1" w="3rem" align="flex-start">
              <Tooltip
                label={"No. of projects client have"}
                placement="top"
                hasArrow
              >
                <Circle size="21px" bg={"blue.500"} color="white">
                  <MdTextSnippet fontSize="14px" />
                </Circle>
              </Tooltip>
              <Stack spacing="0">
                <Tooltip label={"Testing"} hasArrow>
                  <Text as="span" fontSize="md">
                    {user.projects.length}
                  </Text>
                </Tooltip>
              </Stack>
            </HStack>

            <Flex flex="2" justifyContent="flex-end">
              <AvatarGroup size="sm" fontSize="10">
                {staff
                  .filterByIds(clients.getAllAssignees(user))
                  .map((assignee: IUser) => (
                    <TooltipAvatar
                      key={assignee._id}
                      name={getFullName(assignee.firstName, assignee.lastName)}
                      src={assignee.profile.avatar}
                    />
                  ))}
              </AvatarGroup>
            </Flex>

            <IconButton
              initial={false}
              as={motion.div}
              cursor="pointer"
              variants={{
                open: {
                  transform: "rotate(0deg)",
                  transition: { ease: [0.75, 0, 0.25, 0.5] },
                },
                closed: { transform: "rotate(180deg)" },
              }}
              animate={nav.isOpen ? "open" : "closed"}
              fontSize="20px"
              size="md"
              variant="ghost"
              icon={<TiArrowSortedUp />}
              aria-label="Toggle menu"
              {...nav.getButtonProps()}
            />
          </HStack>
        </Flex>
      </Box>

      <Collapse in={nav.isOpen} animateOpacity>
        {user.projects.map((project: IProject) => (
          <Flex
            key={project._id}
            id={project._id}
            p={4}
            justify="space-between"
          >
            <HStack spacing="2">
              <Circle size="21px" bg={"blue.500"} color="white">
                <BiTask fontSize="14px" />
              </Circle>
              <Text noOfLines={1} color={"blue.600"}>
                {project.name}
              </Text>
              <Tag colorScheme={getStatusColorScheme(project.status)}>
                {_.capitalize(project.status)}
              </Tag>
            </HStack>
            <HStack spacing="2" w="14rem">
              Assignee
            </HStack>
            <Flex>
              <AvatarGroup size="sm" fontSize="10">
                {staff.filterByIds(project.assignee).map((assignee: IUser) => (
                  <TooltipAvatar
                    key={assignee._id}
                    name={getFullName(assignee.firstName, assignee.lastName)}
                    src={assignee.profile.avatar}
                  />
                ))}
              </AvatarGroup>
            </Flex>
          </Flex>
        ))}
      </Collapse>
    </>
  );
};

export default UserNav;
