/////////////////////
// App View
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Flex, Box, Container, Spacer } from "@chakra-ui/react";
import SideBar from "../section/SideBar";
import ViewBar from "../section/ViewBar";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services

// Interfaces
interface Props {
  title?: string;
}

// Functions
const AppView: FC<Props> = () => {
  return (
    <Box w="full" minH="100%" h="full">
      <Container maxW="1440px" py={{ base: "4", lg: "5" }} h="calc(85vh)">
        <Flex direction="row" align="start" flex="1" h="full">
          <Box w="25%" h="full" bg={"#F4F7FA"} borderRadius={"3xl"}>
            <SideBar />
          </Box>
          <Spacer />
          <Box w={{ base: "75%", md: "73%" }} h="full">
            <ViewBar />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default AppView;
