import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

// const { definePartsStyle, defineMultiStyleConfig } =
//   createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = defineStyle({
  // fontFamily: "mono", // change the font family
  // color: "red.500", // change the input text color
});
const outlineVariant = defineStyle({
  border: "1px solid red",
});
const errorVariant = defineStyle({
  border: "1px solid red",
  _focus: {
    boxShadow: "0px 0px 0px 1px red",
  },
});

export const customTextareaTheme = defineStyleConfig({
  // defaultProps: {},
  baseStyle,
  variants: { outline: outlineVariant, error: errorVariant },
});
