import { Stack } from "@chakra-ui/react";
import ProjectInfo from "components/ui/Views/ProjectInfo";
import useClientStore from "hooks/useClientStore";
import useProjectStore from "hooks/useProjectStore";
import Status from "../ui/Views/Status";

const DeleteProjectViewBar = () => {
  const { client } = useClientStore();
  const { project } = useProjectStore();

  return (
    <Stack spacing={{ base: "6", md: "8" }} h="full">
      {!!project && !!client ? <ProjectInfo project={project} /> : <Status />}
    </Stack>
  );
};

export default DeleteProjectViewBar;
