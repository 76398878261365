/* eslint-disable no-mixed-spaces-and-tabs */

/////////////////////
// Logo2 (uses iconColor for words)
/////////////////////
/* Eric: Hide .io
<path fill="#000000" d="M2366.94,376V327.76h22.68V376Z"/>
<path fill="#000000" d="M2440.29,139.26V376h-22.68V139.26Z"/>
<path fill="#000000" d="M2570.68,196.3V317.48c0,40.75-16.66,61.65-50.67,61.65-36.5,0-48.9-23.73-48.9-61.29V196.3c0-39,17.36-60.23,50.32-60.23C2556.15,136.07,2570.68,160.87,2570.68,196.3Zm-76.54-.7V319.25c0,28.35,10.28,39.33,26.93,39.33,17.36,0,26.58-10.62,26.58-40.39V196c0-26.57-9.22-39.33-26.93-39.33C2502.65,156.62,2494.14,168.67,2494.14,195.6Z"/>
*/

// Basic Imports
import { chakra, HTMLChakraProps } from "@chakra-ui/react";

// Design Imports

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
export const Logo = (
  props: HTMLChakraProps<"svg"> & { iconColor?: string }
) => {
  const { iconColor = "currentColor", ...rest } = props;

  return (
    <chakra.svg
      aria-hidden
      viewBox="0 0 2800 500"
      fill="none"
      h="6"
      flexShrink={0}
      {...rest}
    >
      <g>
        <g>
          <path
            fill={iconColor}
            d="M847.59,131.46a5.22,5.22,0,0,1,5.32,5.32V371a5.15,5.15,0,0,1-5.32,5H781.33a5.07,5.07,0,0,1-5-5V291.62h-49.6V371a5.15,5.15,0,0,1-5.32,5H655.19a5.33,5.33,0,0,1-5-5V136.78a5.4,5.4,0,0,1,5-5.32h66.26a5.22,5.22,0,0,1,5.32,5.32v92.48h49.6V136.78a5.15,5.15,0,0,1,5-5.32Z"
          />
          <path
            fill={iconColor}
            d="M1000.66,380.91c-75.47,0-125.78-51-125.78-127.2,0-76.89,50.31-127.92,125.78-127.92s125.79,51,125.79,127.92C1126.45,329.88,1076.13,380.91,1000.66,380.91Zm0-183.54c-29,0-48.54,22.67-48.54,56.34S971.61,310,1000.66,310s48.54-22.67,48.54-56.33S1029.72,197.37,1000.66,197.37Z"
          />
          <path
            fill={iconColor}
            d="M1423.72,131.46a5.15,5.15,0,0,1,5,5.32V371a5.07,5.07,0,0,1-5,5h-65.54a5.4,5.4,0,0,1-5.32-5V266.81l-35.79,103.82c-1.06,2.84-5.67,5.32-8.85,5.32h-39c-3.19,0-7.8-2.48-8.86-5.32L1224.6,266.81V371a5.41,5.41,0,0,1-5.32,5h-65.55a5.07,5.07,0,0,1-5-5V136.78a5.15,5.15,0,0,1,5-5.32h74a15,15,0,0,1,12,7.09l48.9,135.35,48.89-135.35a15,15,0,0,1,12.05-7.09Z"
          />
          <path
            fill={iconColor}
            d="M1623.92,312.17c2.84,0,5.32,2.12,5.32,4.6V371a5.4,5.4,0,0,1-5.32,5H1459.16c-2.48,0-5-2.84-5.67-5.32V136.42c0-3.19,1.77-5,5-5h161.22c3.19,0,5.67,2.48,5.67,5v53.51c0,3.19-2.48,5.31-5.67,5.31h-92.48v28h75.12c3.19,0,5,2.48,4.61,4.61l-5,49.6a6.23,6.23,0,0,1-6,4.61h-68.74v30.12Z"
          />
          <path
            fill={iconColor}
            d="M1654,139.26h39.33c35.43,0,52.09,15.59,52.09,53.86v11.33c0,31.18-10.28,45-24.1,50,15.24,5.32,22,17,22,48.55,0,20.19-.35,57.75,2.48,73h-22c-3.19-14.89-2.48-54.92-2.48-71.22,0-31.18-4.6-40.4-28.34-40.4h-16.3V376H1654Zm22.68,105.59h17c23.39,0,29.06-14.18,29.06-39.33v-11c0-22.67-6.38-35.78-30.83-35.78h-15.23Z"
          />
          <path
            fill={iconColor}
            d="M1850.69,259.73h-53.15V355.4h60.24L1854.59,376h-79.72V139.26h79v20.9h-56.34v79h53.15Z"
          />
          <path
            fill={iconColor}
            d="M1879,376V139.26h27.28l42.17,150.94a426.07,426.07,0,0,1,9.56,41.81h.71c-1.42-32.24-1.77-65.55-1.77-104.17V139.26h21.26V376H1952L1904.9,204.1c-3.18-12-5-21.26-6-27.64h-.35c1.41,28.35,2.12,67.68,2.12,110.55V376Z"
          />
          <path
            fill={iconColor}
            d="M2014.39,139.26h41.46c51,0,56.34,33,56.34,69.09v93.9c0,36.49-5.67,73.7-58.47,73.7h-39.33Zm22.68,216.84H2052c29.76,0,37.2-16.29,37.2-48.89v-102c0-28.7-5.31-46.06-36.85-46.06h-15.23Z"
          />
          <path
            fill={iconColor}
            d="M2218.48,259.73h-53.15V355.4h60.24L2222.38,376h-79.72V139.26h79v20.9h-56.34v79h53.15Z"
          />
          <path
            fill={iconColor}
            d="M2246.83,139.26h39.33c35.43,0,52.09,15.59,52.09,53.86v11.33c0,31.18-10.28,45-24.1,50,15.24,5.32,22,17,22,48.55,0,20.19-.36,57.75,2.48,73h-22c-3.19-14.89-2.48-54.92-2.48-71.22,0-31.18-4.6-40.4-28.35-40.4h-16.29V376h-22.68Zm22.68,105.59h17c23.39,0,29.06-14.18,29.06-39.33v-11c0-22.67-6.38-35.78-30.83-35.78h-15.23Z"
          />
        </g>
        <g>
          <path d="M434.49,185.48V298.83L591.34,208V95.1Z" fill="#0083cf" />
          {/* <path d="M434,299.68V185.2l.25-.14,157.58-90.8V208.31l-.25.14Zm1-113.91V298l155.87-90.24V96Z" fill="#fff"/> */}
          {/* <polygon points="434.74 185.91 434.25 185.06 586.8 97.16 440.59 146.77 440.28 145.84 591.18 94.64 591.58 95.53 434.74 185.91" fill="#fff"/> */}
          <polygon
            points="264.83 336.19 264.37 498.38 513.7 355.05 526.57 347.62 526.57 336.19 264.83 336.19"
            fill="#0083cf"
          />
          {/* <path d="M263.89,499.23v-.85l.46-162.68H527.06v12.2L514,355.47Zm1.43-162.55-.46,160.86,261.23-150.2V336.68Z" fill="#fff"/> */}
          <polygon
            points="264.82 336.19 264.82 487.35 210.25 455.83 200.23 450.06 200.23 461.44 15.94 355.06 3.06 347.63 3.06 336.19 264.82 336.19"
            fill="#005cb8"
          />
          {/* <path d="M265.31,488.19l-.73-.42L200.72,450.9v11.38l-.73-.42L2.58,347.91V335.7H265.31Zm-65.57-39,10.75,6.19,53.84,31.1V336.68H3.55v10.67l12.63,7.29,183.56,106Z" fill="#fff"/> */}
          <polygon
            points="526.58 336.19 264.82 487.35 210.25 455.83 200.23 450.06 131.14 410.15 107.4 396.43 3.06 336.19 247.8 194.89 264.82 185.06 460.59 298.09 526.58 336.19"
            fill="#299ef2"
          />
          {/* <path d="M264.82,487.91,2.09,336.19l.73-.42,262-151.27.24.14L527.55,336.19l-.73.42ZM4,336.19l260.78,150.6L525.6,336.19,264.82,185.63Z" fill="#fff"/> */}
          <path
            d="M279.67,65.79,444.76,161.3l153.07-56.06V95.78Z"
            fill="#0083cf"
          />
          {/* <path d="M444.71,161.84l-.19-.12L277.5,65.1,598.31,95.34v10.24l-.32.11ZM281.84,66.48l163,94.29L597.34,104.9V96.22Z" fill="#fff"/> */}
          <path
            d="M279.67,56.33l165.09,95.52L597.83,95.78,432.74.54Z"
            fill="#0083cf"
          />
          {/* <path d="M444.71,152.38l-.19-.11-166-96,1-.36L432.78,0l.2.11L599,95.88l-1,.36ZM280.82,56.43l164,94.88L596.68,95.68l-164-94.61Z" fill="#fff"/> */}
          <path
            d="M279.93,133.61,247.8,194.89l-47.57,90.75V450.06l260.36-150V135.62Zm-79.7,328,.16-.1-.16-.1Z"
            fill="#0083cf"
          />
          {/* <path d="M199.74,462.49v-1.91l1.59,1Zm0-11.59.06-165.48,79.84-152.3h.3l181.14,2V300.33l-.24.14Zm1-165.14V449.22L460.1,299.77V136.1l-179.88-2Z" fill="#fff"/> */}
          <polygon
            points="235.76 405.99 261.64 391.87 261.64 325.13 235.76 338.74 235.76 405.99"
            fill="#0083cf"
          />
          {/* <path d="M235.27,406.81V338.45l.26-.14,26.6-14v67.83l-.25.14Zm1-67.78v66.14l24.91-13.59V325.93Z" fill="#fff"/> */}
          <polygon
            points="259.15 390.48 259.15 329.24 238.25 340.64 238.25 401.88 259.15 390.48"
            fill="#0083cf"
          />
          {/* <path d="M237.77,402.7V340.35l.25-.14,21.62-11.79v62.35l-.26.14Zm1-61.78v60.14l19.92-10.87V330.06Z" fill="#fff"/> */}
          <polygon
            points="255.95 389.88 255.95 330.43 259.15 328.7 259.15 390.48 238.25 401.88 238.25 399.54 255.95 389.88"
            fill="#0083cf"
          />
          {/* <path d="M237.77,402.7v-3.45l17.69-9.66V330.14l4.18-2.26v62.89l-.26.14Zm1-2.87v1.23l19.92-10.87V329.52l-2.22,1.2v59.44Z" fill="#fff"/> */}
          <polygon
            points="233.83 338.62 235.76 338.47 235.76 407.35 233.83 406.57 233.83 338.62"
            fill="#0083cf"
          />
          {/* <path d="M236.25,408.07l-2.9-1.17V338.18l2.9-.24Zm-1.93-1.82,1,.38V339l-1,.08Z" fill="#fff"/> */}
          <polygon
            points="261.64 325.13 235.76 338.74 233.83 338.62 259.94 324.93 261.64 325.13"
            fill="#0083cf"
          />
          {/* <path d="M235.87,339.23l-3.81-.22,27.79-14.58,3.41.39Zm-.26-1h0l21.67-11.4Z" fill="#fff"/> */}
          <polygon
            points="247.74 335.46 249.89 334.19 249.89 394.81 247.74 395.96 247.74 335.46"
            fill="#0083cf"
          />
          {/* <path d="M247.25,396.78v-61.6l3.13-1.84V395.1Zm1-61v59.41l1.18-.64V335Z" fill="#fff"/> */}
          <polygon
            points="246.05 336.38 247.74 335.54 247.74 395.96 246.05 395.67 246.05 336.38"
            fill="#0083cf"
          />
          {/* <path d="M248.23,396.54l-2.67-.46v-60l2.67-1.33Zm-1.69-1.28.71.13V336.33l-.71.35Z" fill="#fff"/> */}
          <polygon
            points="249.89 368.19 258.52 363.27 258.52 361.09 249.89 366 249.89 368.19"
            fill="#0083cf"
          />
          {/* <path d="M249.41,369v-3.3l9.6-5.47v3.31Zm1-2.73v1.06L258,363v-1.06Z" fill="#fff"/> */}
          <polygon
            points="249.89 364.99 249.89 366 258.52 361.09 257.07 361.01 249.89 364.99"
            fill="#0083cf"
          />
          {/* <polygon points="249.41 366.84 249.41 364.7 256.95 360.52 260.21 360.69 249.41 366.84" fill="#fff"/> */}
          <polygon
            points="238.25 374.75 247.54 369.18 247.54 367 238.25 372.39 238.25 374.75"
            fill="#0083cf"
          />
          {/* <path d="M237.77,375.61v-3.5l10.26-6v3.3Zm1-2.94v1.22l8.32-5v-1.06Z" fill="#fff"/> */}
          <polygon
            points="238.25 371.55 238.25 372.39 247.54 367 246.09 366.92 238.25 371.55"
            fill="#0083cf"
          />
          {/* <polygon points="237.77 373.24 237.77 371.28 245.97 366.43 249.2 366.6 237.77 373.24" fill="#fff"/> */}
          <polygon
            points="236.3 410.97 263.55 395.1 263.55 391.52 236.3 406.84 236.3 410.97"
            fill="#0083cf"
          />
          {/* <path d="M235.82,411.82v-5.27l.25-.13,28-15.73v4.69l-.24.14Zm1-4.7v3l26.28-15.31v-2.47Z" fill="#fff"/> */}
          <polygon
            points="233.83 405.77 236.3 406.63 236.3 410.97 233.83 409.85 233.83 405.77"
            fill="#0083cf"
          />
          {/* <path d="M236.79,411.73l-3.44-1.57v-5.07l3.44,1.2Zm-2.47-2.2,1.5.69V407l-1.5-.52Z" fill="#fff"/> */}
          <polygon
            points="234.8 406.38 236.3 406.84 263.55 391.52 261.64 391.36 234.8 406.38"
            fill="#0083cf"
          />
          {/* <path d="M236.36,407.37l-2.8-.86,28-15.65,3.64.3Zm-.33-1.12.22.06,25.68-14.44h-.18Z" fill="#fff"/> */}
          <polygon
            points="342.36 371.79 346.06 369.77 346.06 366.26 386.09 343.12 386.09 346.53 389.8 344.37 389.8 258.05 342.36 283.07 342.36 371.79"
            fill="#0083cf"
          />
          {/* <path d="M341.87,372.61V282.78l.26-.14,48.15-25.4v87.4l-4.67,2.74V344l-39.06,22.59v3.51Zm1-89.24V371l2.72-1.49V366l41-23.7v3.4l2.73-1.59V258.85Z" fill="#fff"/> */}
          <polygon
            points="386.09 343.12 386.09 265.15 346.06 286.28 346.06 366.26 386.09 343.12"
            fill="#0083cf"
          />
          {/* <path d="M345.57,367.11V286l.26-.13,40.75-21.5v79Zm1-80.54v78.85l39.06-22.58V266Z" fill="#fff"/> */}
          <polygon
            points="339.28 282.46 342.36 283.07 342.36 371.79 339.28 370.12 339.28 282.46"
            fill="#0083cf"
          />
          {/* <path d="M342.85,372.61l-4.06-2.2V281.87l4.06.8Zm-3.09-2.78,2.11,1.14v-87.5l-2.11-.42Z" fill="#fff"/> */}
          <polygon
            points="383.94 266.29 386.09 265.15 386.09 346.53 383.94 344.37 383.94 266.29"
            fill="#0083cf"
          />
          {/* <path d="M386.58,347.7l-3.13-3.13V266l3.13-1.65Zm-2.16-3.53,1.19,1.18V266l-1.19.63Z" fill="#fff"/> */}
          <polygon
            points="389.8 258.05 342.36 283.2 339.28 282.46 386.95 257.45 389.8 258.05"
            fill="#0083cf"
          />
          {/* <path d="M342.43,283.72l-4.52-1.09,49-25.69,4.33.9Zm-1.78-1.43,1.64.4,46.09-24.44L387,258Z" fill="#fff"/> */}
          <polygon
            points="366.6 284.01 380.93 276.31 380.93 330.35 366.6 338.46 366.6 284.01"
            fill="#0083cf"
          />
          {/* <path d="M366.11,339.3V283.72l15.3-8.23v55.14Zm1-55v53.32l13.35-7.57V277.12Z" fill="#fff"/> */}
          <polygon
            points="362.47 286.23 362.47 340.8 347.64 349.2 347.64 294.21 362.47 286.23"
            fill="#0083cf"
          />
          {/* <path d="M347.16,350V293.92l15.8-8.5v55.66Zm1-55.54v53.87L362,340.52V287.05Z" fill="#fff"/> */}
          <polygon
            points="379.11 329.63 379.11 277.28 380.93 276.31 380.93 330.35 366.6 338.46 366.6 336.72 379.11 329.63"
            fill="#0083cf"
          />
          {/* <path d="M366.11,339.3v-2.86l12.51-7.09V277l2.79-1.5v55.14Zm1-2.29v.62l13.35-7.57V277.12l-.84.46v52.33Z" fill="#fff"/> */}
          <polygon
            points="360.66 340.09 360.66 287.21 362.47 286.23 362.47 340.8 347.64 349.2 347.64 347.46 360.66 340.09"
            fill="#0083cf"
          />
          {/* <path d="M347.16,350v-2.86l13-7.37V286.92l2.79-1.5v55.66Zm1-2.29v.62L362,340.52V287.05l-.84.45v52.87Z" fill="#fff"/> */}
          <polygon
            points="362.82 333.77 364.19 333.05 364.19 327.03 362.82 327.75 362.82 333.77"
            fill="#0083cf"
          />
          {/* <path d="M362.33,334.58v-7.12l2.35-1.24v7.12Zm1-6.53V333l.4-.21v-4.93Z" fill="#fff"/> */}
          <polygon
            points="365.39 332.5 366.81 331.74 366.81 325.51 365.39 326.26 365.39 332.5"
            fill="#0083cf"
          />
          {/* <path d="M364.91,333.3V326l2.39-1.27V332Zm1-6.74v5.13l.45-.24v-5.13Z" fill="#fff"/> */}
          <polygon
            points="264.82 487.35 264.82 498.73 200.4 461.54 200.23 461.44 200.23 450.06 210.25 455.83 264.82 487.35"
            fill="#005cb8"
          />
          {/* <path d="M265.31,499.57l-.73-.42-64.84-37.44V449.22l10.75,6.19,54.82,31.66Zm-64.59-38.4,63.61,36.72V487.63L200.72,450.9Z" fill="#fff"/> */}
          <polygon
            points="19.29 356.94 200.23 461.63 200.23 286.1 19.29 181.84 19.29 356.94"
            fill="#005cb8"
          />
          {/* <path d="M200.72,462.47l-.73-.42L18.8,357.22V181l.73.43,181.19,104.4ZM19.78,356.66l180,104.12V286.38l-180-103.7Z" fill="#fff"/> */}
          <polygon
            points="5.35 194.36 201.58 307.88 283.32 147.99 285.07 129.28 5.35 194.36"
            fill="#0083cf"
          />
          {/* <path d="M201.78,308.56,4,194.16l281.58-65.5-1.87,19.55ZM6.66,194.55,201.39,307.21l81.46-159.36,1.68-17.94Z" fill="#fff"/> */}
          <polygon
            points="479.24 142.12 479.24 151.57 283.32 147.99 245.63 63.9 479.24 142.12"
            fill="#0083cf"
          />
          {/* <path d="M479.73,152.07h-.5L283,148.47l-.12-.29-38.15-85.1,1.05.36,234,78.33ZM283.64,147.5l195.11,3.58v-8.61L246.52,64.71Z" fill="#fff"/> */}
          <polygon
            points="0.65 191.26 196.88 304.78 283.32 138.53 87.09 25.46 0.65 191.26"
            fill="#08a3fc"
          />
          {/* <path d="M197.07,305.46l-.44-.26L0,191.45,86.89,24.78l.44.26L284,138.34l-.22.41ZM1.29,191.07l195.39,113,86-165.39L87.29,26.13Z" fill="#fff"/> */}
          <polygon
            points="283.01 28.59 479.24 142.12 283.32 138.53 87.09 25.46 283.01 28.59"
            fill="#0083cf"
          />
          {/* <path d="M481.11,142.64,283.19,139l-.11-.06L85.22,24.94l197.92,3.17.11.06ZM283.45,138l193.92,3.55L282.88,29.08,89,26Z" fill="#fff"/> */}
          <polygon
            points="570.06 220.35 481.98 271.73 481.98 189.7 570.06 138.43 570.06 220.35"
            fill="#0083cf"
          />
          {/* <path d="M481.49,272.58V189.42l.24-.15,88.82-51.69v83l-.24.14Zm1-82.6v80.9l87.11-50.8V139.27Z" fill="#fff"/> */}
          <polygon
            points="570.06 214.87 481.98 266.65 481.98 259.59 570.06 207.84 570.06 214.87"
            fill="#0083cf"
          />
          {/* <path d="M481.49,267.51v-8.2l.24-.14L570.55,207v8.16l-.24.14Zm1-7.64v5.94l87.11-51.22v-5.9Z" fill="#fff"/> */}
          <polygon
            points="570.06 208.06 481.98 259.81 481.98 252.16 570.06 200.44 570.06 208.06"
            fill="#0083cf"
          />
          {/* <path d="M481.49,260.66v-8.78l.24-.14,88.82-52.15v8.75l-.24.14Zm1-8.22V259l87.11-51.18v-6.49Z" fill="#fff"/> */}
          <polygon
            points="570.06 200.66 481.98 252.38 481.98 244.73 570.06 193.05 570.06 200.66"
            fill="#0083cf"
          />
          {/* <path d="M481.49,253.23v-8.78l.24-.14,88.82-52.11v8.74l-.24.14Zm1-8.22v6.52l87.11-51.14V193.9Z" fill="#fff"/> */}
          <polygon
            points="570.06 193.27 481.98 244.95 481.98 237.3 570.06 185.65 570.06 193.27"
            fill="#0083cf"
          />
          {/* <path d="M481.49,245.8V237l89.06-52.21v8.74l-.24.14Zm1-8.22v6.53L569.57,193V186.5Z" fill="#fff"/> */}
          <polygon
            points="570.06 185.88 481.98 237.52 481.98 229.87 570.06 178.26 570.06 185.88"
            fill="#0083cf"
          />
          {/* <path d="M481.49,238.37v-8.78l89.06-52.18v8.74Zm1-8.22v6.53l87.11-51.08v-6.49Z" fill="#fff"/> */}
          <polygon
            points="570.06 178.48 481.98 230.09 481.98 222.44 570.06 170.87 570.06 178.48"
            fill="#0083cf"
          />
          {/* <path d="M481.49,230.94v-8.78L570.55,170v8.74Zm1-8.22v6.53l87.11-51.05v-6.49Z" fill="#fff"/> */}
          <polygon
            points="570.06 171.09 481.98 222.67 481.98 215.01 570.06 163.47 570.06 171.09"
            fill="#0083cf"
          />
          {/* <path d="M481.49,223.51v-8.77l89.06-52.12v8.75Zm1-8.22v6.53l87.11-51v-6.49Z" fill="#fff"/> */}
          <polygon
            points="570.06 163.69 481.98 215.24 481.98 207.59 570.06 156.08 570.06 163.69"
            fill="#0083cf"
          />
          {/* <path d="M481.49,216.09v-8.78l.24-.15,88.82-51.93V164Zm1-8.23v6.53l87.11-51v-6.5Z" fill="#fff"/> */}
          <polygon
            points="570.06 156.3 481.98 207.81 481.98 200.16 570.06 148.68 570.06 156.3"
            fill="#0083cf"
          />
          {/* <path d="M481.49,208.66v-8.78l.24-.14,88.82-51.9v8.74Zm1-8.23V207L569.57,156v-6.49Z" fill="#fff"/> */}
          <polygon
            points="570.06 148.91 481.98 200.38 481.98 192.73 570.06 141.29 570.06 148.91"
            fill="#0083cf"
          />
          {/* <path d="M481.49,201.23v-8.78l.24-.14,88.82-51.87v8.74l-.24.15Zm1-8.22v6.52l87.11-50.9v-6.49Z" fill="#fff"/> */}
          <polygon
            points="570.06 141.51 481.98 192.95 481.98 185.3 570.06 133.9 570.06 141.51"
            fill="#0083cf"
          />
          {/* <path d="M481.49,193.8V185l.24-.14,88.82-51.83v8.74l-.24.14Zm1-8.22v6.52l87.11-50.87v-6.49Z" fill="#fff"/> */}
          <polygon
            points="565.37 136.59 570.06 133.9 570.06 220.34 565.37 217.56 565.37 136.59"
            fill="#0083cf"
          />
          {/* <path d="M570.55,221.19l-5.67-3.35V136.31l5.67-3.26Zm-4.7-3.9,3.72,2.2V134.73l-3.72,2.14Z" fill="#fff"/> */}
          <polygon
            points="527.08 237.85 535.62 232.73 535.62 229.33 527.08 234.45 527.08 237.85"
            fill="#0083cf"
          />
          {/* <path d="M526.6,238.71v-4.53l9.5-5.71V233Zm1-4V237l7.56-4.53v-2.27Z" fill="#fff"/> */}
          <rect
            x="255.75"
            y="381.73"
            width="19.21"
            height="92.78"
            fill="#0083cf"
          />
          {/* <path d="M275.44,475H255.26V381.25h20.18Zm-19.21-1h18.23V382.22H256.23Z" fill="#fff"/> */}
          <polygon
            points="255.75 380.03 265.09 380.03 265.09 461.9 255.75 457.42 255.75 380.03"
            fill="#005cb8"
          />
          {/* <path d="M265.58,462.68l-10.32-5V379.54h10.32Zm-9.35-5.57,8.37,4V380.51h-8.37Z" fill="#fff"/> */}
          <polygon
            points="264.37 498.38 280.16 489.6 280.16 455.22 264.37 464.37 264.37 498.38"
            fill="#0083cf"
          />
          {/* <path d="M263.89,499.21V464.09l16.76-9.71v35.5l-.25.14Zm1-34.56v32.91l14.81-8.25V456.07Z" fill="#fff"/> */}
          <polygon
            points="274.95 452.24 280.16 455.22 264.37 464.37 251.68 457.69 255.75 455.16 255.75 456.61 265.09 461.9 274.95 456.02 274.95 452.24"
            fill="#0083cf"
          />
          {/* <path d="M264.39,464.92l-13.69-7.19,5.53-3.45v2.05l8.86,5,9.37-5.6V451.4l6.68,3.82Zm-11.74-7.26,11.71,6.15,14.83-8.58-3.75-2.15v3.21l-10.34,6.18-9.84-5.57V456Z" fill="#fff"/> */}
          <polygon
            points="251.68 457.69 264.37 464.37 264.37 498.38 251.68 491.39 251.68 457.69"
            fill="#005cb8"
          />
          {/* <path d="M264.86,499.21l-13.67-7.54V456.89l13.67,7.18Zm-12.7-8.11,11.73,6.46v-32.9l-11.73-6.16Z" fill="#fff"/> */}
          <rect
            x="501.08"
            y="241.23"
            width="19.21"
            height="92.78"
            fill="#0083cf"
          />
          {/* <path d="M520.78,334.5H500.6V240.75h20.18Zm-19.21-1H519.8v-91.8H501.57Z" fill="#fff"/> */}
          <polygon
            points="501.08 239.52 510.43 239.52 510.43 321.4 501.08 316.92 501.08 239.52"
            fill="#0083cf"
          />
          {/* <path d="M510.92,322.17l-10.32-4.95V239h10.32Zm-9.35-5.56,8.37,4V240h-8.37Z" fill="#fff"/> */}
          <polygon
            points="509.71 357.88 525.5 349.09 525.5 314.72 509.71 323.87 509.71 357.88"
            fill="#0083cf"
          />
          {/* <path d="M509.22,358.71V323.58l16.77-9.7v35.5l-.25.14Zm1-34.57v32.92L525,348.81V315.57Z" fill="#fff"/> */}
          <polygon
            points="520.29 311.74 525.5 314.72 509.71 323.87 497.01 317.19 501.08 314.65 501.08 316.11 510.43 321.4 520.29 315.51 520.29 311.74"
            fill="#0083cf"
          />
          {/* <path d="M509.72,324.42,496,317.23l5.53-3.45v2.05l8.85,5,9.38-5.6V310.9l6.67,3.82ZM498,317.15l11.71,6.16,14.82-8.58-3.74-2.15v3.21L510.43,322l-9.83-5.57v-.86Z" fill="#fff"/> */}
          <polygon
            points="497.01 317.19 509.71 323.87 509.71 357.88 497.01 350.88 497.01 317.19"
            fill="#0083cf"
          />
          {/* <path d="M510.2,358.71l-13.67-7.54V316.39l13.67,7.18Zm-12.7-8.11,11.72,6.46v-32.9L497.5,318Z" fill="#fff"/> */}
          <polygon
            points="532.84 244.12 271.95 394.28 199.87 352.78 199.87 333.71 532.84 224.72 532.84 244.12"
            fill="#0083cf"
          />
          {/* <path d="M272,394.84l-.25-.14-72.31-41.64V333.35l.33-.11L533.33,224V244.4l-.24.14ZM200.36,352.5,272,393.72l260.4-149.88V225.39l-332,108.67Z" fill="#fff"/> */}
          <polygon
            points="199.87 333.71 271.95 375.2 271.95 394.28 199.87 352.78 199.87 333.71"
            fill="#005cb8"
          />
          {/* <path d="M272.43,395.12l-.73-.42-72.31-41.64V332.87l.73.42,72.31,41.63ZM200.36,352.5l71.1,40.94V375.49l-71.1-40.94Z" fill="#fff"/> */}
          <polygon
            points="532.84 231.24 271.95 381.73 199.87 340.23 199.87 333.71 532.84 224.72 532.84 231.24"
            fill="#0083cf"
          />
          {/* <path d="M272,382.29l-.25-.14-72.31-41.64v-7.16l.33-.11L533.33,224v7.48l-.24.14ZM200.36,340,272,381.16,532.35,231v-5.57l-332,108.67Z" fill="#fff"/> */}
          <polygon
            points="199.87 333.71 271.95 375.2 532.84 224.72 460.54 183.34 199.87 333.71"
            fill="#299ef2"
          />
          {/* <path d="M272,375.77l-.25-.14-72.8-41.92.73-.42L460.54,182.78l73.28,41.93-.73.43Zm-71.1-42.06L272,374.64,531.87,224.72,460.54,183.9Z" fill="#fff"/> */}
          <polygon
            points="199.87 340.23 271.95 381.73 271.95 375.2 199.87 333.71 199.87 340.23"
            fill="#005cb8"
          />
          {/* <path d="M272.43,382.57l-.73-.42-72.31-41.64v-7.64l.73.42,72.31,41.63ZM200.36,340l71.1,40.94v-5.4l-71.1-40.94Z" fill="#fff"/> */}
          <polygon
            points="156.52 380.59 130.64 366.47 130.64 299.73 156.52 313.34 156.52 380.59"
            fill="#0083cf"
          />
          {/* <path d="M157,381.41l-26.86-14.65V298.92L157,313.05Zm-25.88-15.23L156,379.77V313.64l-24.91-13.11Z" fill="#fff"/> */}
          <polygon
            points="133.13 365.08 133.13 303.84 154.03 315.24 154.03 376.48 133.13 365.08"
            fill="#0083cf"
          />
          {/* <path d="M154.52,377.3l-21.87-11.93V303L154.52,315Zm-20.9-12.51,19.92,10.87V315.53l-19.92-10.87Z" fill="#fff"/> */}
          <polygon
            points="136.33 364.48 136.33 305.03 133.13 303.3 133.13 365.08 154.03 376.48 154.03 374.14 136.33 364.48"
            fill="#0083cf"
          />
          {/* <path d="M154.52,377.3l-21.87-11.93V302.48l4.17,2.26v59.45l17.7,9.66Zm-20.9-12.51,19.92,10.87v-1.23l-17.7-9.67V305.32l-2.22-1.2Z" fill="#fff"/> */}
          <polygon
            points="158.45 313.23 156.52 313.07 156.52 381.95 158.45 381.18 158.45 313.23"
            fill="#0083cf"
          />
          {/* <path d="M156,382.68V312.54l2.89.24V381.5Zm1-69.08v67.63l1-.38V313.67Z" fill="#fff"/> */}
          <polygon
            points="130.64 299.73 156.52 313.34 158.45 313.23 132.34 299.53 130.64 299.73"
            fill="#0083cf"
          />
          {/* <path d="M156.42,313.84,129,299.42l3.42-.39,27.78,14.57ZM137,302.53l19.62,10.32h.05Z" fill="#fff"/> */}
          <polygon
            points="144.54 310.06 142.39 308.8 142.39 369.41 144.54 370.57 144.54 310.06"
            fill="#0083cf"
          />
          {/* <path d="M145,371.38l-3.13-1.68V308l3.13,1.83Zm-2.16-2.26,1.18.63V310.34l-1.18-.69Z" fill="#fff"/> */}
          <polygon
            points="146.23 310.99 144.54 310.14 144.54 370.57 146.23 370.28 146.23 310.99"
            fill="#0083cf"
          />
          {/* <path d="M144.05,371.14V309.35l2.67,1.33v60Zm1-60.21V370l.72-.12V311.29Z" fill="#fff"/> */}
          <polygon
            points="142.39 342.79 133.76 337.88 133.76 335.69 142.39 340.61 142.39 342.79"
            fill="#0083cf"
          />
          {/* <path d="M142.87,343.63l-9.6-5.47v-3.3l9.6,5.46Zm-8.63-6L141.9,342v-1.06l-7.66-4.36Z" fill="#fff"/> */}
          <polygon
            points="142.39 339.59 142.39 340.61 133.76 335.69 135.21 335.62 142.39 339.59"
            fill="#0083cf"
          />
          {/* <polygon points="142.87 341.44 132.07 335.29 135.32 335.12 142.87 339.31 142.87 341.44" fill="#fff"/> */}
          <polygon
            points="154.03 349.35 144.74 343.78 144.74 341.6 154.03 346.99 154.03 349.35"
            fill="#0083cf"
          />
          {/* <path d="M154.52,350.21l-10.27-6.15v-3.31l10.27,6Zm-9.29-6.71,8.31,5v-1.22l-8.31-4.83Z" fill="#fff"/> */}
          <polygon
            points="154.03 346.16 154.03 346.99 144.74 341.6 146.19 341.52 154.03 346.16"
            fill="#0083cf"
          />
          {/* <polygon points="154.52 347.84 143.08 341.2 146.31 341.03 154.52 345.88 154.52 347.84" fill="#fff"/> */}
          <polygon
            points="155.98 385.57 128.73 369.7 128.73 366.12 155.98 381.44 155.98 385.57"
            fill="#0083cf"
          />
          {/* <path d="M156.47,386.42,128.24,370v-4.69l28.23,15.87Zm-27.26-17,26.28,15.31v-3L129.21,367Z" fill="#fff"/> */}
          <polygon
            points="158.45 380.37 155.98 381.24 155.98 385.57 158.45 384.45 158.45 380.37"
            fill="#0083cf"
          />
          {/* <path d="M155.49,386.33v-5.44l3.44-1.2v5.07Zm1-4.75v3.24l1.49-.68v-3.08Z" fill="#fff"/> */}
          <polygon
            points="157.49 380.98 155.98 381.44 128.73 366.12 130.64 365.96 157.49 380.98"
            fill="#0083cf"
          />
          {/* <path d="M155.92,382,127.1,365.76l3.65-.29,28,15.64Zm-25.57-15.5L156,380.92l.21-.07-25.72-14.39Z" fill="#fff"/> */}
          <polygon
            points="82.22 338.24 56.33 324.12 56.33 257.37 82.22 270.99 82.22 338.24"
            fill="#0083cf"
          />
          {/* <path d="M82.71,339.05,55.85,324.4V256.57l26.86,14.12ZM56.82,323.83l24.91,13.59V271.28l-24.91-13.1Z" fill="#fff"/> */}
          <polygon
            points="58.83 322.73 58.83 261.49 79.72 272.88 79.72 334.13 58.83 322.73"
            fill="#0083cf"
          />
          {/* <path d="M80.21,334.94,58.34,323V260.67L80.21,272.6Zm-20.89-12.5L79.24,333.3V273.17L59.32,262.31Z" fill="#fff"/> */}
          <polygon
            points="62.03 322.12 62.03 262.68 58.83 260.95 58.83 322.73 79.72 334.13 79.72 331.79 62.03 322.12"
            fill="#0083cf"
          />
          {/* <path d="M80.21,334.94,58.34,323V260.13l4.17,2.26v59.44l17.7,9.67Zm-20.89-12.5L79.24,333.3v-1.22l-17.7-9.67V263l-2.22-1.21Z" fill="#fff"/> */}
          <polygon
            points="84.14 270.87 82.22 270.72 82.22 339.6 84.14 338.82 84.14 270.87"
            fill="#0083cf"
          />
          {/* <path d="M81.73,340.32V270.19l2.9.23v68.73Zm1-69.08v67.64l1-.39V271.32Z" fill="#fff"/> */}
          <polygon
            points="56.33 257.37 82.22 270.99 84.14 270.87 58.04 257.18 56.33 257.37"
            fill="#0083cf"
          />
          {/* <path d="M82.11,271.48l-27.4-14.41,3.42-.39,27.78,14.57ZM62.23,259.93l20.1,10.57h0Z" fill="#fff"/> */}
          <polygon
            points="70.24 267.71 68.08 266.44 68.08 327.05 70.24 328.21 70.24 267.71"
            fill="#0083cf"
          />
          {/* <path d="M70.73,329l-3.13-1.68V265.59l3.13,1.84Zm-2.16-2.27,1.18.64V268l-1.18-.7Z" fill="#fff"/> */}
          <polygon
            points="71.93 268.63 70.24 267.79 70.24 328.21 71.93 327.92 71.93 268.63"
            fill="#0083cf"
          />
          {/* <path d="M69.75,328.79V267l2.66,1.33v60Zm1-60.22v59.07l.71-.13V268.93Z" fill="#fff"/> */}
          <polygon
            points="68.08 300.44 59.45 295.52 59.45 293.34 68.08 298.25 68.08 300.44"
            fill="#0083cf"
          />
          {/* <path d="M68.57,301.27,59,295.81V292.5l9.6,5.47Zm-8.63-6,7.66,4.36v-1.06l-7.66-4.37Z" fill="#fff"/> */}
          <polygon
            points="68.08 297.24 68.08 298.25 59.45 293.34 60.91 293.26 68.08 297.24"
            fill="#0083cf"
          />
          {/* <polygon points="68.57 299.09 57.77 292.94 61.02 292.77 68.57 296.95 68.57 299.09" fill="#fff"/> */}
          <polygon
            points="79.72 307 70.43 301.43 70.43 299.25 79.72 304.64 79.72 307"
            fill="#0083cf"
          />
          {/* <path d="M80.21,307.86,70,301.7v-3.3l10.26,6Zm-9.29-6.71,8.32,5v-1.22l-8.32-4.83Z" fill="#fff"/> */}
          <polygon
            points="79.72 303.8 79.72 304.64 70.43 299.25 71.89 299.17 79.72 303.8"
            fill="#0083cf"
          />
          {/* <polygon points="80.21 305.49 68.78 298.84 72.01 298.67 72.14 298.75 80.21 303.53 80.21 305.49" fill="#fff"/> */}
          <polygon
            points="81.67 343.22 54.42 327.35 54.42 323.76 81.67 339.09 81.67 343.22"
            fill="#0083cf"
          />
          {/* <path d="M82.16,344.07,53.94,327.63v-4.7L82.16,338.8Zm-27.25-17,26.28,15.3v-3L54.91,324.6Z" fill="#fff"/> */}
          <polygon
            points="84.14 338.02 81.67 338.88 81.67 343.22 84.14 342.1 84.14 338.02"
            fill="#0083cf"
          />
          {/* <path d="M81.19,344v-5.44l3.44-1.21v5.08Zm1-4.75v3.23l1.5-.68V338.7Z" fill="#fff"/> */}
          <polygon
            points="83.18 338.63 81.67 339.09 54.42 323.76 56.33 323.61 83.18 338.63"
            fill="#0083cf"
          />
          {/* <path d="M81.62,339.61l-.18-.1L52.8,323.41l3.64-.3,28,15.65ZM56.05,324.12l25.68,14.44.22-.06L56.23,324.11Z" fill="#fff"/> */}
          <polygon
            points="371.7 145.92 371.77 234.64 248.18 305.97 248.41 217.04 371.7 145.92"
            fill="#0083cf"
          />
          {/* <path d="M247.69,306.81V306l.24-89.21.24-.14,124-71.54v.84l.07,89-.25.14Zm1.21-89.49-.24,87.8,122.62-70.76-.06-87.6Z" fill="#fff"/> */}
          <polygon
            points="253.27 220.81 366.85 155.29 366.9 237.5 253.06 303.21 253.27 220.81"
            fill="#299ef2"
          />
          {/* <path d="M252.57,304.05v-.84l.21-82.68.24-.14,114.31-65.94v.84l.06,82.49-.24.14Zm1.18-83-.2,81.27,112.87-65.14-.06-81.09Z" fill="#fff"/> */}
          <polygon
            points="253.27 220.81 366.85 155.29 366.9 232.91 253.06 298.61 253.27 220.81"
            fill="#0083cf"
          />
          {/* <path d="M252.57,299.46v-.85l.21-78.08.24-.14,114.31-65.94v.84l.06,77.9-.24.14Zm1.18-78.37-.2,76.68,112.87-65.14-.06-76.5Z" fill="#fff"/> */}
          <polygon
            points="323.04 180.56 323.05 258.2 327.09 260.53 327.08 178.21 323.04 180.56"
            fill="#0083cf"
          />
          {/* <path d="M327.58,261.37l-5-2.89v-78.2l5-2.92Zm-4-3.45,3.06,1.77V179.06l-3.06,1.78Z" fill="#fff"/> */}
          <polygon
            points="327.08 178.21 327.09 260.53 330.58 258.5 330.57 176.19 327.08 178.21"
            fill="#0083cf"
          />
          {/* <path d="M326.6,261.38V177.93l4.47-2.59v83.45Zm1-82.89,0,81.2,2.52-1.46,0-81.2Z" fill="#fff"/> */}
          <polygon
            points="285.09 202.47 285.11 280.1 289.14 282.44 289.13 200.12 285.09 202.47"
            fill="#005cb8"
          />
          {/* <path d="M289.63,283.28l-5-2.9V202.19l5-2.92Zm-4-3.46,3.07,1.77,0-80.63-3.06,1.79Z" fill="#fff"/> */}
          <polygon
            points="289.13 200.12 289.14 282.44 292.64 280.41 292.63 198.09 289.13 200.12"
            fill="#0083cf"
          />
          {/* <path d="M288.66,283.28l0-83.45,4.47-2.59v83.45Zm1-82.88v81.19l2.52-1.46v-81.2Z" fill="#fff"/> */}
          <polygon
            points="498.38 187.48 498.63 211.66 502.67 213.99 502.42 189.81 498.38 187.48"
            fill="#0083cf"
          />
          {/* <path d="M503.16,214.84l-5-2.9-.26-25.31,5,2.9Zm-4.05-3.46,3.06,1.76-.24-23-3.06-1.77Z" fill="#fff"/> */}
          <polygon
            points="502.42 189.81 502.67 213.99 506.13 211.99 505.88 187.81 502.42 189.81"
            fill="#0083cf"
          />
          {/* <path d="M502.19,214.83l-.26-25.3,4.43-2.56.26,25.3Zm.72-24.74.23,23.06,2.5-1.44-.24-23.07Z" fill="#fff"/> */}
          <polygon
            points="472.25 170.35 472.5 194.54 476.54 196.87 476.29 172.68 472.25 170.35"
            fill="#0083cf"
          />
          {/* <path d="M477,197.71l-5-2.89-.26-25.31,5,2.89ZM473,194.25l3.06,1.77L475.81,173l-3.06-1.77Z" fill="#fff"/> */}
          <polygon
            points="476.29 172.68 476.54 196.87 480 194.87 479.75 170.68 476.29 172.68"
            fill="#0083cf"
          />
          {/* <path d="M476.06,197.7l-.26-25.3,4.43-2.56.26,25.3Zm.72-24.74L477,196l2.49-1.44-.24-23.07Z" fill="#fff"/> */}
          <polygon
            points="268.12 341.92 268.36 370.81 272.4 373.14 272.15 344.25 268.12 341.92"
            fill="#005cb8"
          />
          {/* <path d="M272.89,374l-5-2.9v-.27l-.26-29.75,5,2.9v.27Zm-4-3.46,3.06,1.77-.24-27.77-3.06-1.76Z" fill="#fff"/> */}
          <polygon
            points="272.15 344.25 272.4 373.14 275.86 371.14 275.61 342.25 272.15 344.25"
            fill="#0083cf"
          />
          {/* <path d="M271.92,374v-.83L271.67,344l4.42-2.56v.83l.25,29.18Zm.72-29.45.24,27.77,2.5-1.44-.24-27.77Z" fill="#fff"/> */}
          <polygon
            points="286.07 331.58 286.32 360.48 290.36 362.81 290.11 333.91 286.07 331.58"
            fill="#005cb8"
          />
          {/* <path d="M290.85,363.65l-5-2.89v-.28l-.25-29.74,5,2.89v.28Zm-4.05-3.46,3.06,1.77-.24-27.76-3.05-1.77Z" fill="#fff"/> */}
          <polygon
            points="290.11 333.91 290.36 362.81 293.82 360.81 293.57 331.91 290.11 333.91"
            fill="#0083cf"
          />
          {/* <path d="M289.88,363.65v-.84l-.25-29.17,4.43-2.57v.84l.25,29.17Zm.72-29.46.23,27.78,2.5-1.44-.24-27.78Z" fill="#fff"/> */}
          <polygon
            points="304.03 321.25 304.28 350.14 308.31 352.47 308.06 323.58 304.03 321.25"
            fill="#005cb8"
          />
          {/* <path d="M308.81,353.32l-5-2.9v-.28l-.26-29.74,5,2.9v.27Zm-4.05-3.46,3.06,1.77-.24-27.77-3.06-1.76Z" fill="#fff"/> */}
          <polygon
            points="308.06 323.58 308.31 352.47 311.77 350.47 311.53 321.58 308.06 323.58"
            fill="#0083cf"
          />
          {/* <path d="M307.83,353.31v-.83l-.25-29.18,4.42-2.56v.83l.25,29.18Zm.72-29.45.24,27.77,2.5-1.44-.24-27.77Z" fill="#fff"/> */}
          <polygon
            points="321.98 310.91 322.23 339.81 326.27 342.14 326.02 313.24 321.98 310.91"
            fill="#005cb8"
          />
          {/* <path d="M326.76,343l-5-2.89v-.28l-.25-29.74,5,2.89v.28Zm-4-3.46,3.06,1.77-.24-27.76-3.06-1.77Z" fill="#fff"/> */}
          <polygon
            points="326.02 313.24 326.27 342.14 329.73 340.13 329.48 311.24 326.02 313.24"
            fill="#0083cf"
          />
          {/* <path d="M325.79,343v-.83L325.53,313,330,310.4v.84l.25,29.17Zm.72-29.45.24,27.78,2.5-1.44L329,312.08Z" fill="#fff"/> */}
          <polygon
            points="339.94 300.58 340.19 329.47 344.23 331.8 343.98 302.91 339.94 300.58"
            fill="#005cb8"
          />
          {/* <path d="M344.72,332.65l-5-2.9v-.28l-.25-29.74,5,2.9v.27Zm-4.05-3.46,3.06,1.76-.24-27.76-3.05-1.77Z" fill="#fff"/> */}
          <polygon
            points="343.98 302.91 344.23 331.8 347.69 329.8 347.44 300.91 343.98 302.91"
            fill="#0083cf"
          />
          {/* <path d="M343.75,332.64v-.83l-.25-29.18,4.43-2.56v.83l.25,29.18Zm.72-29.45L344.7,331l2.5-1.44L347,301.75Z" fill="#fff"/> */}
          <polygon
            points="357.9 290.24 358.15 319.13 362.18 321.46 361.93 292.57 357.9 290.24"
            fill="#0083cf"
          />
          {/* <path d="M362.68,322.31l-5-2.89v-.28l-.26-29.74,5,2.89v.28Zm-4.05-3.46,3.06,1.77-.24-27.77-3.06-1.76Z" fill="#fff"/> */}
          <polygon
            points="361.93 292.57 362.18 321.46 365.64 319.46 365.39 290.57 361.93 292.57"
            fill="#0083cf"
          />
          {/* <path d="M361.7,322.3v-.83l-.24-29.18,4.42-2.56v.84l.25,29.17Zm.72-29.45.24,27.78,2.5-1.45-.24-27.77Z" fill="#fff"/> */}
          <polygon
            points="375.85 279.91 376.1 308.8 380.14 311.13 379.89 282.24 375.85 279.91"
            fill="#0083cf"
          />
          {/* <path d="M380.64,312l-5-2.9v-.28l-.25-29.74,5,2.9v.27Zm-4.05-3.46,3.06,1.76-.24-27.76-3.06-1.77Z" fill="#fff"/> */}
          <polygon
            points="379.89 282.24 380.14 311.13 383.6 309.13 383.35 280.24 379.89 282.24"
            fill="#0083cf"
          />
          {/* <path d="M379.66,312v-.84L379.4,282l4.43-2.56v.83l.25,29.18Zm.72-29.45.24,27.77,2.49-1.44-.24-27.78Z" fill="#fff"/> */}
          <polygon
            points="393.81 269.57 394.06 298.46 398.1 300.79 397.85 271.9 393.81 269.57"
            fill="#0083cf"
          />
          {/* <path d="M398.59,301.64l-5-2.89v-.28l-.25-29.75,5,2.9v.28Zm-4-3.46L397.6,300l-.24-27.77-3.06-1.76Z" fill="#fff"/> */}
          <polygon
            points="397.85 271.9 398.1 300.79 401.56 298.79 401.31 269.9 397.85 271.9"
            fill="#0083cf"
          />
          {/* <path d="M397.62,301.63v-.83l-.25-29.18,4.43-2.56v.84l.25,29.17Zm.72-29.45.24,27.78,2.49-1.45-.24-27.77Z" fill="#fff"/> */}
          <polygon
            points="411.77 259.24 412.01 288.13 416.05 290.46 415.8 261.57 411.77 259.24"
            fill="#0083cf"
          />
          {/* <path d="M416.55,291.31l-5-2.9v-.28l-.26-29.74,5,2.89v.28Zm-4.05-3.46,3.06,1.76-.24-27.76-3.06-1.77Z" fill="#fff"/> */}
          <polygon
            points="415.8 261.57 416.05 290.46 419.51 288.46 419.26 259.56 415.8 261.57"
            fill="#0083cf"
          />
          {/* <path d="M415.58,291.3v-.84l-.25-29.17,4.42-2.56v.83l.25,29.17Zm.71-29.46.24,27.78,2.5-1.44-.24-27.78Z" fill="#fff"/> */}
          <polygon
            points="429.72 248.9 429.97 277.79 434.01 280.12 433.76 251.23 429.72 248.9"
            fill="#0083cf"
          />
          {/* <path d="M434.5,281l-5-2.9v-.27l-.25-29.75,5,2.9v.28Zm-4.05-3.46,3.07,1.77-.24-27.77-3.06-1.76Z" fill="#fff"/> */}
          <polygon
            points="433.76 251.23 434.01 280.12 437.47 278.12 437.22 249.23 433.76 251.23"
            fill="#0083cf"
          />
          {/* <path d="M433.53,281v-.83L433.27,251l4.43-2.56v.83L438,278.4Zm.72-29.45.24,27.78,2.49-1.45-.24-27.77Z" fill="#fff"/> */}
          <polygon
            points="447.68 238.56 447.93 267.46 451.97 269.79 451.72 240.9 447.68 238.56"
            fill="#0083cf"
          />
          {/* <path d="M452.46,270.63l-5-2.89v-.28l-.25-29.74,5,2.89v.28Zm-4-3.45,3.06,1.76-.24-27.76-3.06-1.77Z" fill="#fff"/> */}
          <polygon
            points="451.72 240.9 451.97 269.79 455.43 267.79 455.18 238.89 451.72 240.9"
            fill="#0083cf"
          />
          {/* <path d="M451.49,270.63v-.84l-.25-29.17,4.43-2.57v.84l.25,29.17Zm.71-29.46.25,27.78,2.49-1.44-.24-27.78Z" fill="#fff"/> */}
          <polygon
            points="465.64 228.23 465.88 257.12 469.92 259.45 469.67 230.56 465.64 228.23"
            fill="#0083cf"
          />
          {/* <path d="M470.42,260.3l-5-2.9v-.27l-.26-29.75,5,2.9v.27Zm-4.05-3.46,3.06,1.77-.24-27.77-3.06-1.76Z" fill="#fff"/> */}
          <polygon
            points="469.67 230.56 469.92 259.45 473.38 257.45 473.13 228.56 469.67 230.56"
            fill="#0083cf"
          />
          {/* <path d="M469.45,260.29v-.83l-.25-29.18,4.42-2.56v.83l.25,29.18Zm.71-29.45.24,27.77,2.5-1.44-.24-27.77Z" fill="#fff"/> */}
          <polygon
            points="483.59 217.89 483.84 246.79 487.88 249.12 487.63 220.22 483.59 217.89"
            fill="#0083cf"
          />
          {/* <path d="M488.37,250l-5-2.89v-.28l-.26-29.74,5,2.89v.28Zm-4-3.46,3.06,1.77-.24-27.76-3.06-1.77Z" fill="#fff"/> */}
          <polygon
            points="487.63 220.22 487.88 249.12 491.34 247.12 491.09 218.22 487.63 220.22"
            fill="#0083cf"
          />
          {/* <path d="M487.4,250v-.84L487.14,220l4.43-2.57v.84l.25,29.17Zm.72-29.46.24,27.78,2.49-1.44-.24-27.78Z" fill="#fff"/> */}
          <polygon
            points="501.55 207.56 501.8 236.45 505.83 238.78 505.59 209.89 501.55 207.56"
            fill="#0083cf"
          />
          {/* <path d="M506.33,239.63l-5-2.9v-.27l-.25-29.75,5,2.9v.27Zm-4.05-3.46,3.06,1.76-.24-27.76L502,208.4Z" fill="#fff"/> */}
          <polygon
            points="505.59 209.89 505.83 238.78 509.3 236.78 509.05 207.89 505.59 209.89"
            fill="#0083cf"
          />
          {/* <path d="M505.36,239.62v-.83l-.25-29.18,4.43-2.56v.83l.25,29.18Zm.71-29.45.24,27.77,2.5-1.44-.24-27.77Z" fill="#fff"/> */}
          <polygon
            points="519.5 197.22 519.75 226.12 523.79 228.45 523.54 199.55 519.5 197.22"
            fill="#0083cf"
          />
          {/* <path d="M524.29,229.29l-5-2.89v-.28L519,196.38l5,2.89v.28Zm-4-3.46,3.06,1.77-.24-27.77L520,198.07Z" fill="#fff"/> */}
          <polygon
            points="523.54 199.55 523.79 228.45 527.25 226.44 527 197.55 523.54 199.55"
            fill="#0083cf"
          />
          {/* <path d="M523.31,229.29v-.84l-.25-29.18,4.42-2.56v.84l.25,29.17Zm.72-29.46.24,27.78,2.5-1.44-.24-27.78Z" fill="#fff"/> */}
          <polygon
            points="243.86 327.73 244.11 356.62 248.14 358.95 247.89 330.06 243.86 327.73"
            fill="#005cb8"
          />
          {/* <path d="M248.64,359.8l-5-2.9v-.28l-.26-29.74,5,2.9v.27Zm-4-3.46,3.06,1.76-.24-27.76-3.06-1.77Z" fill="#fff"/> */}
          <polygon
            points="247.89 330.06 248.14 358.95 251.6 356.95 251.35 328.06 247.89 330.06"
            fill="#0083cf"
          />
          {/* <path d="M247.66,359.79V359l-.26-29.18,4.43-2.56v.83l.25,29.18Zm.72-29.45.24,27.77,2.5-1.44-.25-27.77Z" fill="#fff"/> */}
          <polygon
            points="217.73 310.6 217.98 339.5 222.02 341.83 221.77 312.93 217.73 310.6"
            fill="#005cb8"
          />
          {/* <path d="M222.51,342.67l-5-2.89v-.28l-.25-29.74,5,2.89v.28Zm-4-3.46,3.06,1.77-.24-27.76-3.06-1.77Z" fill="#fff"/> */}
          <polygon
            points="221.77 312.93 222.02 341.83 225.48 339.82 225.23 310.93 221.77 312.93"
            fill="#0083cf"
          />
          {/* <path d="M221.54,342.67v-.84l-.25-29.17,4.43-2.57v.84L226,340.1Zm.71-29.46L222.5,341l2.49-1.44-.24-27.78Z" fill="#fff"/> */}
          <polygon
            points="272.14 349.65 526.99 202.99 526.99 197.57 272.14 344.24 272.14 349.65"
            fill="#0083cf"
          />
          {/* <path d="M271.65,350.49V344l.25-.14L527.47,196.73v6.54l-.24.14Zm1-6v4.29L526.5,202.7v-4.29Z" fill="#fff"/> */}
          <polygon
            points="272.14 344.24 526.99 197.57 522.96 195.25 268.12 341.92 272.14 344.24"
            fill="#299ef2"
          />
          {/* <path d="M272.14,344.8l-5-2.88L523,194.69l5,2.88Zm-3.05-2.88,3.05,1.76L526,197.57l-3-1.76Z" fill="#fff"/> */}
          <polygon
            points="212.69 304.31 274.1 339.19 272.19 344.23 207.38 307.36 212.69 304.31"
            fill="#299ef2"
          />
          {/* <path d="M272.44,344.93l-.49-.28-65.54-37.29,6.27-3.61.25.13L274.7,339Zm-64.08-37.57,63.58,36.16,1.56-4.11-60.81-34.54Z" fill="#fff"/> */}
          <polygon
            points="207.38 307.36 272.19 344.23 268.17 346.55 207.35 311.94 207.38 307.36"
            fill="#0083cf"
          />
          {/* <path d="M268.17,347.11l-.24-.14-61.07-34.75,0-5.69.72.41,65.54,37.28Zm-60.34-35.46L268.16,346l3-1.76-63.35-36Z" fill="#fff"/> */}
          <polygon
            points="456.82 157.71 522.96 195.25 521.05 200.28 451.51 160.76 456.82 157.71"
            fill="#0083cf"
          />
          {/* <path d="M521.31,201l-.5-.28-70.27-39.94,6.27-3.62.25.14L523.56,195Zm-68.82-40.23,68.31,38.82,1.56-4.11-65.54-37.2Z" fill="#fff"/> */}
          <polygon
            points="451.51 160.76 521.05 200.28 517.03 202.6 451.48 165.34 451.51 160.76"
            fill="#0083cf"
          />
          {/* <path d="M517,203.17l-.24-.14L451,165.62l.05-5.69,71,40.35ZM452,165.06,517,202l3.05-1.75L452,161.59Z" fill="#fff"/> */}
        </g>
      </g>
    </chakra.svg>
  );
};
/*"currentColor"*/
