import {
  Box,
  Center,
  Grid,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import useAuth from "hooks/useAuthStore";
import useSocket from "hooks/useSocket";
import { GoBell } from "react-icons/go";
import { markAsReadNotification } from "services/notificationService";
import { Notification } from "./Notification";
import { NotificationBody } from "./NotificationBody";

const NotificationMenu = () => {
  const { token } = useAuth();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { notificationData } = useSocket();

  return (
    <Box>
      <Popover
        arrowSize={10}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnBlur
      >
        <PopoverTrigger>
          <Box>
            <Notification count={notificationData?.unread || 0} />
          </Box>
        </PopoverTrigger>
        <Box position="relative">
          <PopoverContent
            w={{ base: "15rem", md: "max" }}
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
              zIndex: "100000",
            }}
          >
            <PopoverArrow />
            <PopoverHeader>
              <Grid
                templateColumns="repeat(3, 1fr)"
                gap={6}
                alignItems="center"
              >
                <Box as={GoBell} fontSize="lg" color="blue.600" />
                <Heading as="h4" size="xs">
                  <Center h="32px" color="black">
                    Notification
                  </Center>
                </Heading>
                <PopoverCloseButton alignSelf="center" color="black" />
              </Grid>
            </PopoverHeader>
            <PopoverBody overflowY="auto" h="80vh" color="black" p={0}>
              <NotificationBody
                data={notificationData.notifications}
                onClickNotification={async ({ notificationId, isRead }) => {
                  if (!!isRead) {
                    onClose();
                    return;
                  }

                  onClose();
                  await markAsReadNotification({
                    notificationId,
                    authToken: token,
                  });
                }}
              />
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Popover>
    </Box>
  );
};

export default NotificationMenu;
