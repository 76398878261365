import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import Communication from "components/section/Communication";
import useFetchUsers from "hooks/useFetchUsers";
import useUserStore from "hooks/useUserStore";
import { produce } from "immer";
import { useEffect, useState } from "react";

const CommunicationTab = () => {
  const { user } = useUserStore();
  const { updateUserMutationQuery } = useFetchUsers();
  const [notifications, setNotifications] = useState({
    appUpdates: false,
    newsletters: false,
    promotional: false,
  });

  useEffect(() => {
    if (!!user) {
      setNotifications({
        appUpdates: user.preferences.notifications.appUpdates,
        newsletters: user.preferences.notifications.newsletters,
        promotional: user.preferences.notifications.promotional,
      });
    }
  }, [user?.preferences.notifications]);

  return (
    <AccordionItem border="none">
      <h2>
        <AccordionButton
          bg="gray.50"
          border="1px"
          borderColor="gray.300"
          rounded="md"
        >
          <Box as="span" flex="1" textAlign="left">
            Communication
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb="6" px="4" rounded="lg">
        <Communication
          appUpdates={{
            value: notifications.appUpdates,
            onChange: (value) => {
              setNotifications({
                ...notifications,
                appUpdates: value,
              });

              if (!user) return;
              const updatedUser = produce(user, (draft) => {
                draft.preferences.notifications.appUpdates = value;
              });
              updateUserMutationQuery.mutate(updatedUser);
            },
          }}
          newsLetters={{
            value: notifications.newsletters,
            onChange: (value) => {
              setNotifications({
                ...notifications,
                newsletters: value,
              });

              if (!user) return;
              const updatedUser = produce(user, (draft) => {
                draft.preferences.notifications.newsletters = value;
              });
              updateUserMutationQuery.mutate(updatedUser);
            },
          }}
          promotional={{
            value: notifications.promotional,
            onChange: (value) => {
              setNotifications({
                ...notifications,
                promotional: value,
              });

              if (!user) return;
              const updatedUser = produce(user, (draft) => {
                draft.preferences.notifications.promotional = value;
              });
              updateUserMutationQuery.mutate(updatedUser);
            },
          }}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default CommunicationTab;
