/////////////////////
// App Layout
/////////////////////

// Basic Imports
import { FC } from "react";
import { Outlet } from "react-router-dom";

// Design Imports
import { Box } from "@chakra-ui/react";
import Header from "../ui/menu/Header";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services

// Interfaces

// Functions
const AppLayout: FC = () => {
  return (
    // <Box bg={mode("gray.100", "gray.800")} minH="100vh" overflowX="hidden">
    <Box
      as="nav"
      //boxShadow="0px 8px 20px rgb(0 0 0 / 3%)"
      bg="white"
      minH="100vh"
    // overflowX="hidden"
    >
      <Header />
      <Outlet />
    </Box>
  );
};

export default AppLayout;
