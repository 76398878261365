import { Accordion, Box, Stack } from "@chakra-ui/react";
import useFetchUsers from "hooks/useFetchUsers";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IUser, UserFormTypes } from "types/user";
import * as yup from "yup";
import useUserStore from "hooks/useUserStore";
import UserSettings from "./UserSettings";
import { postcodeValidator } from "postcode-validator";
import { yupResolver } from "@hookform/resolvers/yup";
import CommunicationTab from "./CommunicationTab";
import Header from "./Header";
import { getHashedPassword } from "utils/getHashedPassword";
import WorkProgress from "./WorkProgress";
import useFetchStaffs from "hooks/useFetchStaff";
import useStaffStore from "hooks/useStaffStore";
import { IStaff } from "types/staff";

const schema = (action: "create" | "update") =>
  yup.object().shape({
    _id: yup.string(),
    firstName: yup.string().required().label("First name"),
    lastName: yup.string().required().max(10).label("Last name"),
    email: yup.string().email().required().label("Email"),
    password: yup.string().test({
      name: "password",
      message: "Password must be at least 6 characters",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      test: function (value: any) {
        if (!!value || action === "create") return value.length >= 6;
        return true;
      },
    }),
    role: yup.string().required().label("Role"),
    profile: yup.object().when(["_id"], {
      is: (_id: string) => !!_id, // Condition: If there is no Id then it means this is for adding new user and it doesn't require profile schema
      then: yup.object().shape({
        company: yup.string().label("Company"),
        addressLine1: yup.string().label("Address Line 1"),
        addressLine2: yup.string().label("Address Line 2"),
        country: yup.string().label("Country"),
        city: yup.string().label("City"),
        province: yup.string().label("Province or State"),
        avatar: yup.string(),
        postalCode: yup
          .string()
          .label("Postal Code or ZIP Code")
          .when(["country"], {
            // Conditional validation: It will run validation if country is available otherwise not
            is: (country: string) => !!country,
            then: yup.string().test({
              name: "postalCodeValidation",
              exclusive: false,
              message: "Postal Code or Zip Code must be valid.",
              test: function (value: string | undefined) {
                if (!value) return true; // No need validation because zipCode is not required

                // You can access the country field with `this.parent` e.g., `this.parent.country`.
                const country = this.parent.country;
                let isValid = false;
                try {
                  isValid = postcodeValidator(value.trim(), country);
                  return isValid;
                } catch (error) {
                  return false;
                }
              },
            }),
          }),
      }),
    }),
  });

type UserFormProps = { role: IUser["role"] | IStaff["role"] };
// Functional Component
const UserForm: FC<UserFormProps> = ({ role }) => {
  const {
    updateUserMutationQuery,
    createUserMutationQuery,
    deleteUserMutationQuery,
  } = useFetchUsers();
  const {
    updateStaffMutationQuery,
    createStaffMutationQuery,
    deleteStaffMutationQuery,
  } = useFetchStaffs();
  const { user: userStore, resetUser: resetUserStore } = useUserStore();
  const {
    staffMember: staffMemberStore,
    resetStaffMember: resetStaffMemberStore,
  } = useStaffStore();
  const isStaff = role === "staff" || role === "admin";

  const action: "update" | "create" = isStaff
    ? !!staffMemberStore?._id
      ? "update"
      : "create"
    : !!userStore?._id
    ? "update"
    : "create";

  const methods = useForm<UserFormTypes>({
    resolver: yupResolver(schema(action)),
  });

  useEffect(() => {
    return () => {
      resetUserStore();
      resetStaffMemberStore();
    };
  }, []);
  useEffect(() => {
    methods.reset();

    if (isStaff) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.entries(staffMemberStore || {}).forEach(([key, value]: any) =>
        methods.setValue(key, value)
      );
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.entries(userStore || {}).forEach(([key, value]: any) =>
      methods.setValue(key, value)
    );
  }, [userStore, staffMemberStore]);

  const handleSubmit = (formData: UserFormTypes) => {
    if (action === "update") {
      isStaff
        ? updateStaffMutationQuery.mutate({
            id: formData._id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            role: "staff",
            password: getHashedPassword(formData?.password || ""),
          })
        : updateUserMutationQuery.mutate({
            ...formData,
            password: getHashedPassword(formData?.password || ""),
          });
    }

    if (action === "create") {
      isStaff
        ? createStaffMutationQuery.mutate(
            {
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              role: role,
              password: getHashedPassword(formData?.password || ""),
            },
            {
              onSuccess: () => methods.reset(),
            }
          )
        : createUserMutationQuery.mutate(formData, {
            onSuccess: () => methods.reset(),
          });
    }
  };
  const handleDelete = () => {
    isStaff
      ? deleteStaffMutationQuery.mutate(staffMemberStore?._id || "", {
          onSuccess: () => {
            methods.reset();
            resetStaffMemberStore();
          },
        })
      : deleteUserMutationQuery.mutate(userStore?._id || "", {
          onSuccess: () => {
            methods.reset();
            resetUserStore();
          },
        });
  };

  if (methods?.formState?.errors) {
    console.error({ formErrors: methods.formState.errors });
  }

  // JSX
  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={methods.handleSubmit(handleSubmit)} pb="10">
        <Header
          action={action}
          deleteLoading={
            deleteUserMutationQuery.isLoading ||
            deleteStaffMutationQuery.isLoading
          }
          onDelete={handleDelete}
        />
        <Stack spacing={6} mt="6">
          <Accordion allowToggle defaultIndex={[0]}>
            <Stack spacing={4}>
              <WorkProgress role={role} />
              <UserSettings
                role={role}
                formLoading={
                  createUserMutationQuery.isLoading ||
                  updateUserMutationQuery.isLoading ||
                  createStaffMutationQuery.isLoading ||
                  updateStaffMutationQuery.isLoading
                }
              />
              {userStore?._id && role === "customer" ? (
                <CommunicationTab />
              ) : null}
            </Stack>
          </Accordion>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default UserForm;
