import { FC, useState } from "react";
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import Item from "../misc/Item";
import SpinnerLoading from "../../../loaders/SpinnerLoading";
import { IUser } from "types/user";
import useClientStore from "hooks/useClientStore";
import useFetchUsers from "hooks/useFetchUsers";
import useSettingStore from "hooks/useSettingStore";

const DeletedProjectsClients: FC = () => {
  const [search, setSearch] = useState("");
  const { clients, isLoading, isError } = useFetchUsers();
  const { updateClient: updateClientStore, client: clientStore } =
    useClientStore();
  const { changeTabIndex } = useSettingStore();

  if (!!isLoading) return <SpinnerLoading />;
  if (!!isError || clients.data.length === 0 || clients.getClientsWithDeletedProjects())
    return (
      <Alert status="info">
        <AlertIcon />
        Nothing to show
      </Alert>
    );
  return (
    <Stack justify="space-between" spacing={4} w="full" h="full" p="1">
      <Box>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="muted" boxSize="5" />
          </InputLeftElement>
          <Input
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
      </Box>
      <Box h="95%" overflow="auto" className="hide-scrollbar">
        <Stack spacing={2}>
          {clients
            .search(search, clients.getClientsWithDeletedProjects())
            .map((client: IUser) => {
              return (
                <Item
                  key={client._id}
                  icon={FaUserCircle}
                  email={client.email}
                  badge={client.projects.length}
                  company={client?.profile?.company}
                  label={client?.firstName + " " + client?.lastName}
                  isPaused={!!clients.isClientPaused(client._id)}
                  avatar={client?.profile && client?.profile?.avatar}
                  active={client._id === clientStore?._id}
                  count={client.projects.length}
                  onClick={() => {
                    updateClientStore({
                      ...client,
                      projects: client.projects.filter(
                        (pr) => pr.status === "deleted"
                      ),
                    });
                    changeTabIndex(1);
                  }}
                />
              );
            })}
        </Stack>
      </Box>
    </Stack>
  );
};

export default DeletedProjectsClients;
