import {
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET,
} from "./site-credentials";

export const getUploadOptions = ({ tag, projectName }: any) => {
  return {
    // cloudName: "dtytyllec",
    cloudName: CLOUDINARY_CLOUD_NAME,
    tags: [tag, "anImage"],
    uploadPreset: CLOUDINARY_UPLOAD_PRESET,
    multiple: false,
    // publicId: "multiple/folders/testing1",
    folder: `Homerender/${projectName}/0 Client Upload`,
    useFileName: false,
  };
};
