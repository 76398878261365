import {
  Avatar,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Square,
  Text,
} from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import { Stage, TimeIcon } from "components/ui/misc/CustomIcons";
import { FC } from "react";

interface SubTaskProps {
  taskId: string;
  taskName: string;
  taskStage: string;
  assignee: {
    fullName: string;
    avatar: string;
  };
  onEdit: () => void;
}
const TaskRow: FC<SubTaskProps> = ({
  taskId,
  taskName,
  taskStage,
  assignee,
  onEdit,
}) => {
  return (
    <Flex
      align="center"
      justify="center"
      w="full"
      mt={[0, "0.5em !important"]}
      id={taskId}
      py="1"
    >
      <HStack spacing="5" w="full" justify="space-between">
        <HStack spacing="2" w="22rem">
          <Square>
            <Icon fontSize={20} as={Stage} />
          </Square>
          <Text noOfLines={1} color="#526BD8">
            {taskName}
          </Text>
        </HStack>
        <HStack spacing="2" w="14rem">
          <>
            {assignee.avatar ? (
              <Avatar
                boxSize="6"
                name={assignee.fullName}
                src={assignee.avatar}
              />
            ) : (
              <Avatar boxSize="6" />
            )}

            {assignee.fullName === "" ? (
              <Spinner />
            ) : (
              <Text noOfLines={1} fontSize="sm" fontWeight="bold" minW={"40%"}>
                {assignee?.fullName}
              </Text>
            )}
          </>
        </HStack>
        <HStack spacing="2" flex="1">
          <Icon as={TimeIcon} boxSize="6" color={"#526BD8"} />
          <Text noOfLines={1} color="#526BD8">
            {taskStage || "No stage yet"}
          </Text>
        </HStack>
        <IconButton
          fontSize="20px"
          size="md"
          color={"gray.500"}
          variant="ghost"
          icon={<MdEdit />}
          onClick={onEdit}
          aria-label="Toggle menu"
        />
      </HStack>
    </Flex>
  );
};

export default TaskRow;
