export function getObjectFulFillmentPercentage(object: any) {
  // Total input fields
  const values: string[] = Object.values(object);

  if (values.length === 0) return 0;

  // It will count all the filled values
  const filledValuesCount: number = values.reduce((prev, curr) => {
    const increment = (prev as number) + 1;
    const ignore = (prev as number) + 0;
    return curr === "" ? ignore : increment;
  }, 0) as number;

  // Percentage of filled input fields
  const percentage: number = (filledValuesCount / values.length) * 100;

  // Round the percentage
  return Math.round(percentage);
}
