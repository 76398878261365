import { FC, useState } from "react";
import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  AlertIcon,
  Alert,
  Badge,
  Divider,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { AiFillCodeSandboxCircle, AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { changeProject } from "redux/reducers/projectReducer";
import {
  changeAddTask,
  changeProjectIndex,
  changeTabIndex,
  createProject,
} from "redux/reducers/settingsReducer";
import Item from "../misc/Item";
import useClientStore from "hooks/useClientStore";
import { IProject, ProjectStatus } from "types/project";
import _ from "lodash";
import getStatusColorScheme from "utils/getStatusColorScheme";
import useProjectStore from "hooks/useProjectStore";
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineRefresh } from "react-icons/md";
import useFetchUsers from "hooks/useFetchUsers";
import useNewProjectPage from "pages/NewProjectPage/useNewProjectPage";

interface ProjectsProps {
  strictStatus?: IProject["status"];
}
const Projects: FC<ProjectsProps> = ({ strictStatus }) => {
  const {  projectContext } =
    useNewProjectPage({ shouldFetchDraftProjects: true });
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const { client } = useClientStore();
  const { refetch, isFetching } = useFetchUsers();

  function renderProjectsFromClient(
    projects: IProject[],
    status?: IProject["status"]
  ): IProject[] {
    if (!status) return projects;
    return projects.filter((project) => project.status === status);
  }

  return (
    <Stack justify="space-between" spacing={4} w="full" h="full" p="1">
      {!!client &&
      renderProjectsFromClient(client?.projects, strictStatus).length > 0 ? (
        <>
          <HStack>
            <IconButton
              size="sm"
              colorScheme="blue"
              aria-label="back-to-client"
              icon={<FaArrowLeft />}
              onClick={() => {
                dispatch(changeTabIndex(0));
                dispatch(changeProjectIndex(-1));
                dispatch(changeProject(null));
                dispatch(createProject(false))
              }}
              // variant="unstyled"
            />
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </HStack>
          <Box pt="2" h="95%" overflow="auto" className="hide-scrollbar">
            <Stack w="full" align="center" spacing={5}>
              {!!strictStatus ? (
                <RenderProjectsByStatus
                  shouldShowLabel={false}
                  status={strictStatus}
                  search={search}
                />
              ) : (
                <>
                  <RenderProjectsByStatus status="active" search={search} />
                  <RenderProjectsByStatus status="draft" search={search} />
                  <RenderProjectsByStatus status="paused" search={search} />
                  <RenderProjectsByStatus status="completed" search={search} />
                  <RenderProjectsByStatus status="deleted" search={search} />
                </>
              )}
            </Stack>
          </Box>
        </>
      ) : (
        <Stack spacing={6}>
          <HStack>
            <IconButton
              size="sm"
              colorScheme="blue"
              aria-label="back-to-client"
              icon={<FaArrowLeft />}
              onClick={() => {
                dispatch(changeTabIndex(0));
                dispatch(changeProjectIndex(-1));
                dispatch(changeProject(null));
                dispatch(createProject(false));
              }}
            />
            <Button
              onClick={() => refetch()}
              variant="outline"
              flex="1"
              rightIcon={<MdOutlineRefresh />}
              isLoading={isFetching}
            >
              Refresh
            </Button>
          </HStack>
          <Alert status="info" borderRadius={"xl"}>
            <AlertIcon />
            No project found...
          </Alert>
        </Stack>
      )}
      <Button
          isLoading={projectContext.initializeLoading}
          onClick={() => dispatch(createProject(true)) && projectContext.onInitializeProject()}
          rightIcon={<AiOutlinePlus />}
          colorScheme="blue"
        >
          Create new project
        </Button>
    </Stack>
  );
};

function RenderProjectsByStatus({
  status,
  search,
  shouldShowLabel = true,
}: {
  status: ProjectStatus;
  search: string;
  shouldShowLabel?: boolean;
}) {
  const dispatch = useDispatch();
  const { searchProjects } = useClientStore();
  const filteredProjects = searchProjects(search).filter(
    (project: IProject) => project.status === status
  );
  const { project: projectStore } = useProjectStore();

  if (filteredProjects.length === 0) return null;
  return (
    <>
      {!!shouldShowLabel ? (
        <Badge
          variant="solid"
          colorScheme={getStatusColorScheme(status)}
          w="full"
          textAlign="center"
          rounded="sm"
        >
          {`${_.capitalize(status)} Projects`}
        </Badge>
      ) : null}
      <Stack spacing={1} w="full">
        {filteredProjects.map((project: IProject, index: number) => {
          return (
            <Item
              key={index}
              avatar={""}
              badge={project.selectedOutputs.length}
              label={project?.name}
              icon={AiFillCodeSandboxCircle}
              active={project._id === projectStore?._id}
              count={project.selectedOutputs.length}
              onClick={() => {
                dispatch(changeProject(project));
                dispatch(createProject(false));
                dispatch(changeAddTask(false));
              }}
            />
          );
        })}
      </Stack>
      <Divider />
    </>
  );
}

export default Projects;
