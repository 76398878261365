import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RESET_ACTION } from "../redux/actions";
import { ROUTES } from "../constants/routes";
import { ERRORS, SUCCESS } from "../constants/toastMessages";
import { changeAuthUser } from "../redux/reducers/authUserReducer";
import { store } from "../redux/store";
import { getUserAccountDetails, signin } from "../services/authService";
import { WrapIntoTryCatch } from "../services/wrapIntoTryCatch";
import useCustomToast from "./useCustomToast";

// Interfaces
export type LoginUserTypes = {
  email: string;
  password: string;
  isLoading: boolean;
};

interface OptionTypes {
  hideToast?: boolean;
}

//Function
const useAuthForm = () => {
  const navigate = useNavigate();
  const { errorToast, successToast } = useCustomToast();
  // State management (component level)
  const [user, setUser] = useState<LoginUserTypes>({
    email: "",
    password: "",
    isLoading: false,
  });

  const setLoading = (isLoading: true | false) =>
    setUser({ ...user, isLoading });
  const setEmail = (email: string) => setUser({ ...user, email });
  const setPassword = (password: string) => setUser({ ...user, password });

  const handleSignin = async (e: FormEvent) => {
    e.preventDefault();
    const { response, error } = await callWaiter(() =>
      signin({
        email: user.email,
        password: user.password,
      })
    );
    if (response.status === 200) {
      // Fetch user account details using logged in auth token
      const { data, error } = await WrapIntoTryCatch(() =>
        getUserAccountDetails(response?.data?.token)
      );
      if (!!error) {
        // toast({ title: "Something went wrong...", status: "error" });
        errorToast({ title: ERRORS.ROLE });
        return;
      }
      // Check if user data available otherwise show doesn't let it go ahead
      const userAccount = data?.data?.user || null;
      if (!userAccount || userAccount.role != "admin")
        return errorToast({ title: ERRORS.ROLE });
      store.dispatch(RESET_ACTION);
      onBoardingUser(userAccount, response?.data?.token);
    }

    if (!!error) return;
  };

  type AsyncFunction = () => Promise<any>;
  const callWaiter = async (fn: AsyncFunction, options?: OptionTypes) => {
    setLoading(true);
    const { response, error } = await fn();
    setLoading(false);

    // 2.2 Error (show error message)
    if (!!error) {
      // eslint-disable-next-line @typescript-eslint/ban-types
      if (!options?.hideToast) {
        errorToast({ title: error.message });
      }

      return { error };
    }
    return { response };
  };

  const onBoardingUser = async (user: any, token: any) => {
    // After login user should be navigated to the projects, if there is no project then just hit wizard page
    successToast({ title: SUCCESS.LOGGED_IN });
    //TODO add fetchClients here and set in redux state
    // store.dispatch(changeToken(token));
    // store.dispatch(changeEmail(user.email));
    // store.dispatch(changeRole(user.role));
    // store.dispatch(changeFirstName(user.firstName));
    // store.dispatch(changeLastName(user.lastName));
    // store.dispatch(changeAvatar(user.profile.avatar));
    // eslint-disable-next-line no-debugger
    store.dispatch(
      changeAuthUser({
        token,
        _id: user._id,
        email: user.email,
        role: user.role,
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: user.avatar || "",
      })
    );
    navigate(ROUTES.DASHBOARD, { replace: true });
  };

  return {
    handleSignin,
    setEmail,
    setPassword,
    user,
  };
};

export default useAuthForm;
