import { Box, Container, Flex, Spacer } from "@chakra-ui/react";
import useClientStore from "hooks/useClientStore";
import { useEffect } from "react";
import SideBarDeletedProjects from "components/section/SideBarDeletedProjects";
import useSettingStore from "hooks/useSettingStore";
import DeleteProjectViewBar from "components/section/DeleteProjectViewBar";
import useProjectStore from "hooks/useProjectStore";

const DeletedProjectsPage = () => {
  const { resetClient } = useClientStore();
  const { resetProject } = useProjectStore();
  const { resetTabIndex } = useSettingStore();

  useEffect(() => {
    return () => {
      resetTabIndex();
      resetClient();
      resetProject();
    };
  }, []);

  return (
    <Box w="full" minH="100%" h="full">
      <Container maxW="container.xl" py={{ base: "4", lg: "5" }} h="calc(85vh)">
        <Flex direction="row" align="start" flex="1" h="full">
          <Box w="25%" h="full" bg={"#F4F7FA"} borderRadius={"3xl"}>
            <SideBarDeletedProjects />
          </Box>
          <Spacer />
          <Box w="70%" h="full">
            <DeleteProjectViewBar />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default DeletedProjectsPage;
