/* eslint-disable no-unused-vars */

import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "types/user";

type State = IUser | null;
const initialState: State = null;
export const userSlice = createSlice({
  name: "user",
  initialState: initialState as State,
  reducers: {
    changeUserState: (state, action) => {
      return (state = action.payload);
    },
    resetUserState: (state) => {
      return (state = null);
    },
    changeAppUpdatesNotifications: (state, action) => {
      if (!state) return;
      state.preferences.notifications.appUpdates = action.payload;
    },
    changeNewsLetterNotifications: (state, action) => {
      if (!state) return;
      state.preferences.notifications.newsletters = action.payload;
    },
    changePromotionalNotifications: (state, action) => {
      if (!state) return;
      state.preferences.notifications.promotional = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeUserState,
  resetUserState,
  changeAppUpdatesNotifications,
  changeNewsLetterNotifications,
  changePromotionalNotifications,
} = userSlice.actions;

export default userSlice.reducer;
