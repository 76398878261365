/////////////////////
// Auth Service
// HC is just a crypto generated string which tells the react component that the payment is done or canceled
// to show up the modal when a user click cancel on stripe payment page or subscribed as stripe page is
// totally stateless so we have to query out the params
/////////////////////

// Basic Imports
import axios from "axios";
import { CUSTOMER_HC } from "settings/site-credentials";
import { API_URL } from "../utils/site-credentials";

// Design Imports

// Layout and Section Imports

// Data Imports

// Functions

// Custom Hooks and Services

// Interfaces
interface SigninTypes {
  email: string;
  password: string;
}

// Functions
export const getUserAccountDetails = (authToken?: string) =>
  axios.get(`${API_URL}/users/account`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const getClientAccountDetails = (authToken?: string) =>
  axios.get(`${API_URL}/users`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export async function signin({ email, password }: SigninTypes) {
  try {
    const response = await axios.post(
      `${API_URL}/auth/login`,
      JSON.stringify({ email, password }),
      {
        headers: { "Content-Type": "application/json" },
        // withCredentials: true,
      }
    );

    return { response };
  } catch (err) {
    console.log("/services/auth/signin :: ", { error: err });
    if (!err.response)
      return {
        error: {
          statusCode: 500,
          message: "No server response, please try again later",
        },
      };
    if (err.response?.status === 400)
      return { error: { statusCode: 400, message: "Invalid credentials..." } };
    return { error: { statusCode: 500, message: "Something went wrong..." } };
  }
}

export function storeHcIntoLocalStorage(hc: string) {
  localStorage.setItem(CUSTOMER_HC, JSON.stringify(hc));
}
