/////////////////////
// Output Card UI used on Output Step of New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  HStack,
  Icon,
  Text,
  Tooltip,
  Wrap,
} from "@chakra-ui/react";

import { FaFileDownload } from "react-icons/fa";

// Layout and Section Imports
import { ToggleButton } from "components/ui/common/ToggleButton";
import { ToggleButtonGroup } from "components/ui/common/ToggleButtonGroup";

// Interfaces
interface Props {
  title: string;
  defaultValue?: string;
  options: string[];
  selected: string;
  onSelect?: any;
  readOnly?: boolean
}

const RenderSourceFile: FC<Props> = ({
  title,
  options,
  onSelect,
  selected,
  readOnly
}) => {
 
  return (
    <Wrapper title={title}>
      <ToggleButtonGroup
        value={selected}
        defaultValue={selected}
        isAttached
        variant="outline"
        aria-label={title}
        // onChange={(value) => setSelected(value)}
        onChange={(value) => onSelect(value)}
        readOnly={readOnly}
      >
        {options.map((option: any, idx: number) => (
          <ToggleButton key={idx.toString()} value={option} />
        ))}
      </ToggleButtonGroup>
    </Wrapper>
  );
};

function Wrapper({ title, children }: any) {
  return (
    <HStack>
      <Icon as={FaFileDownload} color="gray.500" />
      <Tooltip
        hasArrow
        placement="auto-start"
        label="Blender files are our default"
      >
        <Text fontWeight="semibold" mt="8" mb="2">
          {title}
        </Text>
      </Tooltip>
      <Wrap shouldWrapChildren>{children}</Wrap>
    </HStack>
  );
}

export default RenderSourceFile;
