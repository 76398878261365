import axios from "axios";
import { API_URL } from "settings/site-credentials";

export async function fetchAllNotifications({
  authToken,
}: {
  authToken: string;
}) {
  try {
    const { data } = await axios.get(`${API_URL}/notifications`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return { data };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
}

export async function markAsReadNotification({
  notificationId,
  authToken,
}: {
  notificationId: string;
  authToken: string;
}) {
  try {
    const { data } = await axios.patch(
      `${API_URL}/notifications/${notificationId}/markAsRead`,
      {},
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
    return { data };
  } catch (error) {
    console.log({ error });
    return { error: "Something went wrong!" };
  }
}
