import { IStaff } from "types/staff";
import { IUser } from "types/user";

export function getCommentSender(commentResponse: IUser | IStaff) {
  if (commentResponse.role === "customer") return "customer";
  if (commentResponse.role === "staff") return "staff";
  if (commentResponse.role === "guest") return "guest";
  if (commentResponse.role === "admin") return "admin";

  return "";
}
