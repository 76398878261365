import { AxiosResponse } from "axios";

export async function WrapIntoTryCatch(fn: () => Promise<AxiosResponse<{ data: any, error: any }>>) {
  try {
    const {data} = await fn();
    return {data}
  } catch (error) {
    console.log({error});
    return {error}
  }
}