import { useEffect, useMemo, useState } from "react";
import io from "socket.io-client";
import useAuth from "./useAuthStore";

const useSocket = () => {
  const { token }: any = useAuth();
  const socket = useMemo(
    () =>
      io("https://dev.homerender.io", {
        withCredentials: true,
        path: "/socket.io",
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [token]
  );

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [notificationData, setNotificationData] = useState({
    notifications: [],
    unread: 0,
  });

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("all_notifications", (data) => {
      setNotificationData(data);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    return () => {
      if (!!socket.connected) {
        socket.off("connect");
        socket.off("disconnect");
      }
    };
  }, [socket]);

  return {
    notificationData,
    isConnected,
  };
};

export default useSocket;
