/* eslint-disable no-unused-vars */

import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "types/user";

type State = IUser | null;
const initialState: State = null;
export const clientSlice = createSlice({
  name: "client",
  initialState: initialState as State,
  reducers: {
    changeClientState: (state, action) => {
      return (state = action.payload);
    },
    resetClientState: (state) => {
      return (state = null);
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeClientState, resetClientState } = clientSlice.actions;

export default clientSlice.reducer;
