/* eslint-disable no-unused-vars */

import { createSlice } from "@reduxjs/toolkit";
import { IStaff } from "types/staff";

type State = IStaff | null;
const initialState: State = null;
export const staffSlice = createSlice({
  name: "staff",
  initialState: initialState as State,
  reducers: {
    changeStaffState: (state, action) => {
      return (state = action.payload);
    },
    resetStaffState: (state) => {
      return (state = null);
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeStaffState, resetStaffState } = staffSlice.actions;

export default staffSlice.reducer;
