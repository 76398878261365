/////////////////////
// Site Credenital Settings
/////////////////////

// Handlers

// Credentials
export const API_URL = process.env.REACT_APP_API_URL as string;
export const SOCKET_API_URL = (URL: string) => {
  const replacedURL = URL.replace("http", "ws");
  const splittedURL = replacedURL.split("/");
  const removedSplittedURL = splittedURL.filter((i) => i !== "v1");
  const joinSplittedURL = removedSplittedURL.join("/");

  return joinSplittedURL;
};

const isPaymentLive = (isLive: true | false) => isLive;
export const LIVE_PAYMENT = isPaymentLive(
  "TRUE" === process.env.REACT_APP_LIVE_STRIPE ? true : false
);
export const CUSTOMER_TOKEN_NAME = "hr_admin_token";
export const CUSTOMER_HC = "hr_admin_hc";
// export const AUTHORIZED_TOKEN = JSON.parse(
//   localStorage.getItem(CUSTOMER_TOKEN_NAME) || {}
// );

// Cloudinary credentials
export const CLOUDINARY_CLOUD_NAME =
  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const CLOUDINARY_UPLOAD_PRESET =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_UPLOAD_PRESET;

// Stripe credentials
export const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;
export const BUSINESS_PLAN_PRICE_ID =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID;
export const BUSINESS_PLAN_PRICE_ID_CAD =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID_CAD;
export const BUSINESS_PLAN_PRICE_ID_USD =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID_USD;
export const PROFESSIONAL_PLAN_PRICE_ID =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID;
export const PROFESSIONAL_PLAN_PRICE_ID_CAD =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID_CAD;
export const PROFESSIONAL_PLAN_PRICE_ID_USD =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID_USD;
export const STARTER_PLAN_PRICE_ID =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID;
export const STARTER_PLAN_PRICE_ID_CAD =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID_CAD;
export const STARTER_PLAN_PRICE_ID_USD =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID_USD;
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

//Cloudflare and Security
export const CLOUDFLARE_SITEKEY = process.env.REACT_APP_CLOUDFLARE_SITE_KEY;
