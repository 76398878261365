import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Stack,
  Text,
  useAccordionContext,
  useColorModeValue,
} from "@chakra-ui/react";
import useStaffStore from "hooks/useStaffStore";
import useUserStore from "hooks/useUserStore";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IStaff } from "types/staff";
import { IUser } from "types/user";
import FormInputField from "../ReactHookForm/FormInputField";

interface Props {
  formLoading?: boolean;
  role: IUser["role"] | IStaff["role"];
}

const Settings: FC<Props> = ({ formLoading, role }) => {
  const { getValues, formState } = useFormContext();
  const { user: client } = useUserStore();
  const { staffMember } = useStaffStore();
  const { focusedIndex, index } = useAccordionContext();
  const [isStateChanged, setIsStateChanged] = useState(false);
  const isStaff = role === "staff" || role === "admin";

  const user = isStaff ? staffMember : client;

  useEffect(() => {
    if (!!formState.isSubmitSuccessful || !formState.isDirty || !user) {
      setIsStateChanged(false);
      return;
    }

    const objectFields = ["firstName", "lastName", "email", "profile"];
    const filteredUser = _.pick(user, objectFields);
    const filteredForm = _.pick(getValues(), objectFields);

    const isPasswordTouched = getValues().password !== "";
    const isEqual = _.isEqual(filteredUser, filteredForm);

    setIsStateChanged(!isEqual || isPasswordTouched);
  }, [focusedIndex, index, user, formState]);

  return (
    <AccordionItem border="none">
      <h2>
        <AccordionButton
          bg={!!isStateChanged ? "blackAlpha.100" : "gray.50"}
          border="1px"
          borderColor={!!isStateChanged ? "black" : "gray.300"}
          rounded="md"
        >
          <Box flex="1" textAlign="left">
            <Text>
              Profile
              {!!isStateChanged ? (
                <Text as="span" ml="1" fontStyle="italic">
                  (Unsaved changes)
                </Text>
              ) : null}
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb="4" px="4" rounded="lg">
        <Box as="section" py={{ base: "2", md: "4" }}>
          <Box
            bg="bg-surface"
            boxShadow={useColorModeValue("sm", "sm-dark")}
            borderRadius="lg"
            px={{ base: "4", md: "6" }}
          >
            <Stack spacing={6} py="4">
              {user?._id || staffMember?._id ? (
                <Stack
                  spacing={{ base: "3", md: "5" }}
                  direction={{ base: "column", sm: "row" }}
                ></Stack>
              ) : null}

              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormInputField label="First Name" name="firstName" />
                <FormInputField label="Last Name" name="lastName" />
              </Stack>

              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormInputField label="Email" name="email" />
                <FormInputField label="New Password" name="password" />
              </Stack>

              {(role === "customer" || role === "guest") && (
                <FormInputField
                  name="role"
                  label="Role"
                  variant="select"
                  list={[
                    { label: "Customer", value: "customer" },
                    { label: "Guest", value: "guest" },
                  ]}
                />
              )}
              {(role === "admin" || role === "staff") && (
                <FormInputField
                  name="role"
                  label="Role"
                  variant="select"
                  list={[
                    { label: "Staff", value: "staff" },
                    { label: "Admin", value: "admin" },
                  ]}
                />
              )}

              {client?._id ? (
                <>
                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormInputField
                      variant="input"
                      label="Company"
                      name="profile.company"
                    />
                    <FormInputField
                      variant="input"
                      label="Street address 1"
                      name="profile.addressLine1"
                    />
                    <FormInputField
                      variant="input"
                      label="Street address 2"
                      name="profile.addressLine2"
                    />
                  </Stack>

                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormInputField
                      name="profile.country"
                      label="Country"
                      variant="select"
                      list={[
                        { value: "US", label: "USA" },
                        { value: "CA", label: "Canada" },
                      ]}
                    />
                    <FormInputField
                      variant="input"
                      label="City"
                      name="profile.city"
                    />
                  </Stack>
                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormInputField
                      variant="input"
                      label="State / Province"
                      name="profile.province"
                    />
                    <FormInputField
                      name="profile.postalCode"
                      label="Zip / Postal code"
                      variant="input"
                    />
                  </Stack>
                </>
              ) : null}
              <Button isLoading={formLoading} colorScheme="blue" type="submit">
                {user?._id ? "Update" : "Create"}
              </Button>
            </Stack>
          </Box>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Settings;
