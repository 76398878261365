import { Box, Container, Heading, Img, VStack } from "@chakra-ui/react";
import zombie from "../../../assets/img/zombie.jpg";
import useFetchTask from "hooks/useFetchTask";
import SpinnerLoading from "loaders/SpinnerLoading";
import ZombieTasks from "components/section/Tasks/ZombieTasks";

const ZombieTasksPage = () => {
  const { allZombieTask, allZombieTaskLoading } = useFetchTask();

  if (!!allZombieTaskLoading)
    return (
      <VStack spacing="24px" align="start" pt={5} h="full">
        <Box
          w="full"
          h="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <SpinnerLoading />
        </Box>
      </VStack>
    );

  return (
    <Box w="full" minH="100%" h="full">
      <Container maxW="container.xl" py={{ base: "4", lg: "5" }} h="calc(85vh)">
        <Box h="full" w="full">
          {allZombieTask.length === 0 ? (
            <Box
              w="full"
              h="full"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box>
                <Img src={zombie} alt="None" height="250px" />
                <Heading as="h4" size="sm">
                  No Zombie Tasks Found!
                </Heading>
              </Box>
            </Box>
          ) : (
            <ZombieTasks tasks={allZombieTask} />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ZombieTasksPage;
