import { useQuery, useMutation, useQueryClient } from "react-query";
import { deleteTaskFromDB, getTasksByProjectID } from "services/appService";
import {
  createTaskService,
  updateTaskService,
  getAllTask,
} from "services/taskService";
import { ITask, ITaskFormData } from "types/task";
import useAuth from "./useAuthStore";
import useCustomToast from "./useCustomToast";
import useProjectStore from "./useProjectStore";
import _ from "lodash";
import { ISelectedOutputs } from "types/project";
import ObjectID from "bson-objectid";

// Functions
const useFetchTask = () => {
  const { project } = useProjectStore();
  const projectId = project ? project["_id"] : "";
  const { token } = useAuth();
  const queryClient = useQueryClient();
  const { errorToast } = useCustomToast();

  const { data, error, isLoading } = useQuery<ITask[]>(
    ["tasks", projectId],
    async () => {
      try {
        const { data } = await getTasksByProjectID(projectId, token || "");
        return data.data.tasks.map((task: any) => ({
          ...task,
          assignee: task.assignee._id,
        }));
      } catch (error) {
        throw new Error("");
      }
    },
    {
      enabled: !!projectId,
    }
  );

  //Getting all tasks
  const {
    data: allZombieTask,
    error: allZombieTaskError,
    isLoading: allZombieTaskLoading,
  }: any = useQuery(
    "allZombieTask",
    async () => {
      try {
        const { data } = await getAllTask(token || "");
        const zombieTask = data.data.tasks.filter(
          (t: any) =>
            !t.outputId || t.outputId === "" || !ObjectID.isValid(t.outputId)
        );

        return zombieTask.map((task: any) => ({
          ...task,
          assignee: task.assignee._id,
        }));
      } catch (error) {
        throw new Error("");
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  // DOC: For update task only
  const updateTaskMutationQuery = useMutation({
    mutationFn: async (formData: ITaskFormData & { taskId: string }) => {
      const { data, error } = await updateTaskService(
        formData.taskId,
        formData,
        token ? token : ""
      );

      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }

      return data?.data?.task as ITask;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks", projectId]);
    },
  });

  // DOC: For adding new task
  const addTaskMutationQuery = useMutation({
    mutationFn: async (formData: ITaskFormData) => {
      const { data, error } = await createTaskService(
        formData,
        token ? token : ""
      );

      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }

      return data?.data?.task;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks", projectId]);
    },
  });

  const addBatchTaskMutationQuery = useMutation({
    mutationFn: async (formDataList: ITaskFormData[]) => {
      try {
        const response = await Promise.all(
          formDataList.map((formData) =>
            createTaskService(formData, token ? token : "")
          )
        );

        return response.map((res) => res.data.data.task);
      } catch (error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks", projectId]);
    },
  });

  const deleteTaskMutationQuery = useMutation({
    mutationFn: async (projectId: string) => {
      const { data, error } = await deleteTaskFromDB(projectId, token);
      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }

      return data?.data?.task;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks", projectId]);
    },
  });

  return {
    error,
    allZombieTaskError,
    isLoading: isLoading,
    allZombieTaskLoading,
    tasks: data || [],
    allZombieTask: allZombieTask || [],
    zombieTasks: getZombieTasks(data || [], project?.selectedOutputs || []),
    updateTaskMutationQuery,
    addTaskMutationQuery,
    addBatchTaskMutationQuery,
    deleteTaskMutationQuery,
  };
};

function getZombieTasks(
  tasks: ITask[],
  selectedOutputs: ISelectedOutputs[]
): ITask[] {
  if (tasks.length === 0) return [];
  if (selectedOutputs.length === 0) return [];

  return _.differenceWith(
    tasks,
    selectedOutputs,
    (task: ITask, output) => task?.outputId === output._id
  );
}

export default useFetchTask;
