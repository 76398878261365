import {
  CloseButton,
  Divider,
  FormLabel,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useColorModeValue as mode } from "@chakra-ui/system";
import { AiFillFileImage, AiFillFilePdf } from "react-icons/ai";
import { BsFillFileEarmarkArrowDownFill } from "react-icons/bs";
import getFileFormat from "utils/getFileFormat";

interface Props {
  url: string;
  fileName: string;
  format: string;
  size?: any;
  description?: "";
  onDelete?: () => Promise<void>;
  showDeleteButton?: boolean;
}
const WizardAttachmentPreview = ({
  url,
  fileName,
  format: _format,
  size = null,
  description,
  onDelete,
  showDeleteButton = true,
}: Props) => {
  const format = getFileFormat({
    format: _format,
    url,
  });

  return (
    <Stack
      px="2"
      rounded="md"
      bg={description ? mode("gray.200", "gray.800") : "inherit"}
      spacing="0"
    >
      <HStack pt="2">
        <HStack
          w="90%"
          onClick={() => window.open(url, "_blank")}
          cursor="pointer"
          userSelect="none"
        >
          <RenderPreview url={url} format={format} />
          <FormLabel cursor="pointer" userSelect="none" fontSize="16" mb="4">
            {`${fileName}.${format} ${
              !!size ? `(${Math.trunc(size / 1000)} kb)` : ""
            }`}
          </FormLabel>
        </HStack>
        {!!showDeleteButton && (
          <HStack w="10%" justify="flex-end">
            <CloseButton onClick={onDelete} />
          </HStack>
        )}
      </HStack>
      {description && (
        <>
          <Divider borderColor="gray.400" />
          <Text px="2" py="2" noOfLines={4}>
            {description}
          </Text>
        </>
      )}
    </Stack>
  );
};

function RenderPreview({ format, url }: any) {
  if (format === "dwg")
    return (
      <Icon
        fontSize="1.5rem"
        as={BsFillFileEarmarkArrowDownFill}
        onClick={() => window.open(url, "_blank")}
        cursor={"pointer"}
        userSelect="none"
      />
    );
  if (format === "pdf")
    return (
      <Icon
        fontSize="1.5rem"
        as={AiFillFilePdf}
        onClick={() => window.open(url, "_blank")}
        cursor={"pointer"}
        userSelect="none"
      />
    );

  if (format === "heic")
    return (
      <Icon
        fontSize="1.5rem"
        as={AiFillFileImage}
        onClick={() => window.open(url, "_blank")}
        cursor={"pointer"}
        userSelect="none"
      />
    );
  return (
    <Image
      w="10"
      minH="1rem"
      border="1px"
      borderColor="gray.400"
      rounded="sm"
      objectFit="cover"
      src={url}
    />
  );
}

export default WizardAttachmentPreview;
