import { useQuery } from "react-query";
import { getProjectByProjectID } from "services/projectServices";
import useAuth from "./useAuthStore";

// Functions
const useFetchProject = (project: any) => {
  const projectId = project ? project?._id : "";
  const { token } = useAuth();

  const getAssets = (assets: any) => {
    if (!assets) return null;
    const att: any[] = [];
    assets.forEach((asset: any) => {
      att.push(...asset.attachments);
    });

    return att;
  };

  const { data, error, isLoading }: any = useQuery(
    ["deletedProjects", projectId],
    async () => {
      try {
        const { data } = await getProjectByProjectID(projectId, token || "");
        return data?.data?.project;
      } catch (error) {
        throw new Error("");
      }
    },
    {
      enabled: !!projectId,
      // refetchOnMount: false,
      // refetchOnWindowFocus: false,
    }
  );
  return {
    error,
    getAssets: getAssets,
    isLoading: isLoading,
    deletedProject: data || null,
  };
};

export default useFetchProject;
