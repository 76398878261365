import { FC } from "react";
import {
  CardHeader as ChakraCardHeader,
  CardHeaderProps,
  HStack,
  Stack,
  Text,
  TextProps,
} from "@chakra-ui/react";

interface Props extends CardHeaderProps {
  title: string;
  description?: string;
  headingStyle?: TextProps;
  SaveButton?: JSX.Element;
}
const CardHeader: FC<Props> = ({
  title,
  headingStyle,
  description,
  SaveButton,
  ...rest
}) => {
  return (
    <ChakraCardHeader {...rest}>
      <HStack justify="space-between">
        <Stack>
          <Text
            textTransform="uppercase"
            fontSize="20"
            fontWeight="bold"
            {...headingStyle}
          >
            {title}
          </Text>
          {!!description && <Text mt="2">{description}</Text>}
        </Stack>
        {SaveButton}
      </HStack>
    </ChakraCardHeader>
  );
};

export default CardHeader;
