import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuList,
  useMenuButton,
  UseMenuButtonProps,
  useColorModeValue as mode,
  MenuItem,
  HStack,
  Text,
  AvatarBadge,
} from "@chakra-ui/react";
import useAuth from "../../../hooks/useAuthStore";

const ProfileMenuButton = (props: UseMenuButtonProps) => {
  const buttonProps = useMenuButton(props);
  const { user } = useAuth();
  return (
    <Flex
      as="button"
      {...buttonProps} //TEMP:COMBAK: It was creating a warning due to button props in Flex.
      flexShrink={0}
      rounded="lg"
      outline="0"
      _focus={{ shadow: "outline" }}
    >
      <Box srOnly>Open user menu</Box>
      <Avatar
        size="sm"
        src={user.avatar || ""}
        name={`${user.firstName} ${user.lastName}`}
      >
        <AvatarBadge borderColor="papayawhip" bg="green.500" boxSize="1em" />
      </Avatar>
    </Flex>
  );
};

const ProfileDropdown = () => {
  const { user, handleLogout } = useAuth();

  return (
    <Box>
      <Menu>
        <ProfileMenuButton />
        <MenuList
          rounded="md"
          shadow="lg"
          color={mode("gray.600", "inherit")}
          fontSize="sm"
          zIndex={99}
        >
          <HStack px="3" py="4">
            <Avatar
              size="sm"
              src={user.avatar || ""}
              name={user.firstName || ""}
            />
            <Box lineHeight="1">
              <Text align="left" fontWeight="semibold">
                {user?.firstName + " " + user?.lastName}
              </Text>
              <Text mt="1" fontSize="xs" color="gray.500">
                {user?.email}
              </Text>
            </Box>
          </HStack>

          {/* <Link to={"/"}> */}
          <MenuItem
            color={mode("black.800", "whiteAlpha.800")}
            fontWeight="medium"
          >
            Help and Support
          </MenuItem>
          {/* </Link> */}
          <MenuItem
            onClick={handleLogout}
            fontWeight="medium"
            color={mode("red.500", "red.300")}
          >
            Sign out
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default ProfileDropdown;
