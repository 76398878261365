import { useDispatch, useSelector } from "react-redux";
import {
  changeShouldShowStaffAssignee,
  changeTabIndex,
} from "redux/reducers/settingsReducer";
import { RootState } from "redux/store";

const useSettingStore = () => {
  const dispatch = useDispatch();
  const activeIndex = useSelector(
    (state: RootState) => state.setting.projectIndex
  );
  const tabIndex = useSelector((state: RootState) => state.setting.tabIndex);
  const shouldShowStaffAssignee = useSelector(
    (state: RootState) => state.setting.shouldShowStaffAssignee
  );

  const createProject = useSelector(
    (state: RootState) => state.setting.createProject
  );

  const handleChangeTabIndex = (tabIndex: number) => {
    dispatch(changeTabIndex(tabIndex));
  };

  return {
    activeIndex,
    tabIndex,
    shouldShowStaffAssignee,
    createProject,
    changeTabIndex: handleChangeTabIndex,
    changeShouldShowStaffAssignee: (value: boolean) =>
      dispatch(changeShouldShowStaffAssignee(value)),
    resetTabIndex: () => dispatch(changeTabIndex(0)),
  };
};

export default useSettingStore;
