/////////////////////
// Style Card UI used on Style Step of New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { FlexProps } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports
import { StyleCardProps } from "data/projectWizardData/styleCardData";
import StyleCards from "./StyleCardsForSteps";
import Card from "components/layout/Card";
import CardBody from "components/layout/Card/CardBody";
import CardHeader from "components/layout/Card/CardHeader";

// Interfaces
interface StyleProps extends FlexProps {
  id: string;
  title: string;
  options: StyleCardProps[];
  onChange?: any;
  onSelected?: any;
  showSelectedOnly?: boolean;
  isReadOnly?: boolean;
  selectedStyles?: any[];
}
export interface Item {
  label: string;
  value: string;
}

export const StyleCardForSteps: FC<StyleProps> = ({
  id,
  title,
  options,
  showSelectedOnly,
  isReadOnly,
  selectedStyles,
}) => {
  if (options.length === 0) return null;
  return (
    <Card>
      <CardHeader title={title} />
      <CardBody>
        {/* Content Section */}
        <StyleCards
          id={id}
          title={title}
          options={options}
          isReadOnly={isReadOnly}
          selectedOptions={[]}
          showSelectedOnly={showSelectedOnly}
          selectedStyles={selectedStyles}
        />
      </CardBody>
    </Card>
  );
};
