import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import clientReducer from "./reducers/clientReducer";
import projectReducer from "./reducers/projectReducer";
import settingsReducer from "./reducers/settingsReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AnyAction, CombinedState, combineReducers } from "redux";
import authUserReducer from "./reducers/authUserReducer";
import userReducer from "./reducers/userReducer";
import { IUser } from "types/user";
import planReducer from "./reducers/planReducer";
import currencyReducer from "./reducers/currencyReducer";
import { IProject } from "types/project";
import staffReducer from "./reducers/staffReducer";
import { IStaff } from "types/staff";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["project"],
};

const combinedReducer = combineReducers({
  client: clientReducer,
  staff: staffReducer,
  project: projectReducer,
  setting: settingsReducer,
  authUser: authUserReducer,
  user: userReducer,
  plan: planReducer,
  currency: currencyReducer,
});

const rootReducer = (
  state:
    | CombinedState<{
        client: null | IUser;
        project: null | IProject;
        setting: {
          tabIndex: number;
          clientIndex: number;
          projectIndex: number;
          addTask: boolean;
          taskId: string;
          subTaskId: string;
          updatedSubtask: any;
          updatedStaff: any;
          shouldShowStaffAssignee: boolean;
          createProject: boolean;
        };
        authUser: {
          _id: null;
          email: null;
          token: null;
          role: null;
          firstName: null;
          lastName: null;
          avatar: null;
        };
        user: null | IUser;
        staff: null | IStaff;
        currency: {
          currencyType: string;
        };
        plan: {
          planDuration: string;
          planType: any;
          selectedPlanPrice: any;
          selectedPlanId: any;
          currentPlan: string;
          stripePlans: any;
        };
      }>
    | undefined,
  action: AnyAction
) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  // reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
