// TODO: Please remove blow line and fix issues reported by eslint
/* eslint-disable react/no-children-prop */

import {
  Box,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Textarea,
  VStack,
  useColorModeValue as mode,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  Spacer,
  Alert,
  AlertIcon,
  Image,
  Wrap,
} from "@chakra-ui/react";
import useFetchProject from "hooks/useFetchProject";
import SpinnerLoading from "loaders/SpinnerLoading";
import { FC } from "react";
import { IProject } from "types/project";

interface Props {
  project: IProject;
}
const ProjectInfo: FC<Props> = ({ project }) => {
  const { deletedProject, isLoading, getAssets } = useFetchProject(project);
  const attachments = deletedProject?.attachments;
  const assets = getAssets(deletedProject?.assets);

  if (!!isLoading)
    return (
      <VStack spacing="24px" align="start" pt={5} h="full">
        <Box
          w="full"
          h="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <SpinnerLoading />
        </Box>
      </VStack>
    );

  return (
    <>
      <Accordion defaultIndex={[0]}>
        <AccordionItem border="none">
          <h2>
            <AccordionButton
              bg={"gray.100"}
              border="1px"
              borderColor={"gray.300"}
              rounded="md"
            >
              <Box flex="1" textAlign="left">
                <Text>Project information</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb="4" px="4" rounded="lg">
            <Stack rounded="lg" w="full" mt={4} spacing={4}>
              <InputGroup size="sm">
                <InputLeftAddon children="Project:" minW="150px" />
                <Input
                  name="Name"
                  autoComplete="off"
                  placeholder="i.e. 2D Floorplan"
                  bg={mode("white", "gray.700")}
                  roundedBottom="0"
                  defaultValue={deletedProject.name}
                  disabled={true}
                  value={deletedProject.name || ""}
                />
              </InputGroup>
              <InputGroup size="sm">
                <InputLeftAddon
                  children="Description:"
                  minH="75px"
                  minW="150px"
                />
                <Textarea
                  defaultValue={deletedProject.description}
                  bg={mode("white", "gray.700")}
                  minH="75px"
                  placeholder="Anything you want to tell us about the project. (i.e. 2 story single family home)"
                  disabled={true}
                  value={deletedProject.description || ""}
                />
              </InputGroup>
              <InputGroup size="sm">
                <InputLeftAddon children="Address Line 1:" minW="150px" />
                <Input
                  name="ProjectAddress1"
                  placeholder=""
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  defaultValue={deletedProject.addressLine1}
                  disabled={true}
                  value={deletedProject.addressLine1 || ""}
                />
              </InputGroup>
              <InputGroup size="sm">
                <InputLeftAddon children="Address Line 2:" minW="150px" />
                <Input
                  name="ProjectAddress2"
                  autoComplete="off"
                  placeholder="i.e. Unit 1234"
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  defaultValue={deletedProject.addressLine2}
                  disabled={true}
                  value={deletedProject.addressLine2 || ""}
                />
              </InputGroup>
              <InputGroup size="sm">
                <InputLeftAddon children="Country:" minW="150px" />
                <Input
                  name="Country"
                  autoComplete="off"
                  placeholder="i.e. USA"
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  defaultValue={deletedProject.country}
                  disabled={true}
                  value={deletedProject.country || ""}
                />
              </InputGroup>
              <InputGroup size="sm">
                <InputLeftAddon children="City:" minW="150px" />
                <Input
                  name="City"
                  autoComplete="off"
                  placeholder="i.e. Vancouver"
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  defaultValue={deletedProject.city}
                  disabled={true}
                  value={deletedProject.city || ""}
                />
              </InputGroup>
              <InputGroup size="sm">
                <InputLeftAddon children="State or Province" minW="150px" />
                <Input
                  name="ProvinceState"
                  autoComplete="off"
                  placeholder="i.e. BC"
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  defaultValue={deletedProject.state}
                  disabled={true}
                  value={deletedProject.state || ""}
                />
              </InputGroup>
              <InputGroup size="sm">
                <InputLeftAddon children="ZIP or Postal Code" minW="150px" />
                <Input
                  name="PostalZipCode"
                  autoComplete="off"
                  placeholder="i.e. V6B 3Y8"
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  type="number"
                  defaultValue={deletedProject.postalCode}
                  disabled={true}
                  value={deletedProject.postalCode || ""}
                />
              </InputGroup>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none" pt={5}>
          <h2>
            <AccordionButton
              bg={"gray.100"}
              border="1px"
              borderColor={"gray.300"}
              rounded="md"
            >
              <Box flex="1" textAlign="left">
                <Text>Project Assets and attachments</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb="4" px="4" rounded="lg">
            <Stack rounded="lg" w="full" mt={4} spacing={4}>
              <Text fontWeight="bold">Project assets</Text>
              {assets && assets.length > 0 ? (
                <Wrap spacing={4}>
                  {assets.map((asset: any, index: number) => (
                    <Box key={index}>
                      <Image
                        border="1px solid gray"
                        boxSize="150px"
                        objectFit="cover"
                        src={asset.cdnUrl}
                        alt="Dan Abramov"
                      />
                    </Box>
                  ))}
                </Wrap>
              ) : (
                <Alert status="info">
                  <AlertIcon />
                  No assets found in this project
                </Alert>
              )}

              <Spacer />
              <Text fontWeight="bold">Project attachments</Text>

              {attachments && attachments.length > 0 ? (
                <Wrap spacing={4}>
                  {attachments.map((asset: any, index: number) => (
                    <Box key={index}>
                      <Image
                        border="1px solid gray"
                        boxSize="150px"
                        objectFit="cover"
                        src={asset.cdnUrl}
                        alt="Dan Abramov"
                      />
                    </Box>
                  ))}
                </Wrap>
              ) : (
                <Alert status="info">
                  <AlertIcon />
                  No attachments found in this project
                </Alert>
              )}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default ProjectInfo;
