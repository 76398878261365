import { useRef } from "react";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { IAssetWithTasks } from "types/task";
import AssetRow from "components/ui/misc/AssetRow";
import Tasks from "components/section/Tasks";
import { IStaff } from "types/staff";

interface ProjectsRowProps {
  asset: IAssetWithTasks;
  staff: IStaff[];
}
const AssetRowWithTasks = ({ asset, staff }: ProjectsRowProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const nav = useDisclosure();
  return (
    <Box w="full">
      <Box
        bg="bg-surface"
        p="2"
        w="full"
        position="relative"
        rounded="lg"
        border="1px"
        borderColor="gray.200"
      >
        <Box
          bg={nav.isOpen ? "gray.100" : "inherit"}
          px="4"
          overflow="hidden"
          rounded="md"
        >
          <AssetRow nav={nav} asset={asset} staff={staff} />
        </Box>

        <Flex
          overflow="hidden"
          ref={ref}
          justify="end"
          align="center"
          initial={false}
          hidden={!nav.isOpen}
          aria-label="Submenu"
          bg="bg-surface"
          width="full"
          top="4.5rem"
          as={motion.nav}
          variants={{
            open: { y: "0", transition: { ease: [0, 0.8, 0, 1] } },
            closed: { y: "-100%", transition: { ease: [0, 0, 4, 0] } },
          }}
          animate={nav.isOpen ? "open" : "closed"}
          onAnimationStart={() => {
            ref.current?.removeAttribute("hidden");
          }}
          onAnimationComplete={() => {
            if (!ref.current) return;
            ref.current.hidden = !nav.isOpen;
          }}
        >
          <Tasks
            staff={staff}
            tasks={asset.tasks.map((t) => ({
              assignee: t.assignee,
              _id: t._id,
              description: t.description,
              name: t.name,
              stage: t.stage,
              status: t.status,
              projectId: t.projectId || null,
            }))}
            asset={asset}
          />
        </Flex>
      </Box>
    </Box>
  );
};
export default AssetRowWithTasks;
