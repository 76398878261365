import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { FaUserCircle } from "react-icons/fa";
import { AiFillCodeSandboxCircle } from "react-icons/ai";
import {
  changeTabIndex,
  changeAddTask,
  changeProjectIndex,
  changeClientIndex,
  changeShouldShowStaffAssignee,
} from "../../../redux/reducers/settingsReducer";
import { EditIcon } from "../misc/CustomIcons";
import useClientStore from "hooks/useClientStore";
import useProjectStore from "hooks/useProjectStore";
import getFullName from "utils/getFullName";
import TooltipAvatar from "../common/TooltipAvatar";
import { MdDelete } from "react-icons/md";
import { deleteProjectByIdService } from "services/projectServices";
import useAuth from "hooks/useAuthStore";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS } from "constants/toastMessages";
import ConfirmationPopover from "../common/ConfirmationPopover";
import { FC, useState } from "react";
import useFetchStaffs from "hooks/useFetchStaff";
import { IStaff } from "types/staff";

const TopBar: FC = () => {
  const dispatch = useDispatch();
  const { client } = useClientStore();
  const { project, changeProject, onDeleteProject } = useProjectStore();
  const { staff } = useFetchStaffs();
  const { token } = useAuth();
  const { errorToast } = useCustomToast();
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);

  const handleDeleteProject = async () => {
    setDeleteProjectLoading(true);
    const { error } = await deleteProjectByIdService(project?._id || "", token);
    setDeleteProjectLoading(false);
    if (!!error) {
      errorToast({ title: ERRORS.DELETE_PROJECT });
      return;
    }

    onDeleteProject();
  };

  return (
    <HStack spacing="50px" align="flex-start">
      <Box h="10" maxWidth={"250px"}>
        <HStack spacing="15px">
          {client && client["profile"] && client["profile"]["avatar"] ? (
            <Avatar
              name={client["firstName"] + " " + client["lastName"]}
              src={client["profile"]["avatar"]}
              boxSize="6"
            />
          ) : (
            <Icon as={FaUserCircle} boxSize="6" color={"blue.500"} />
          )}
          <Tooltip
            label={
              client
                ? client["firstName"] + " " + client["lastName"]
                : "Select Client"
            }
          >
            <Text
              noOfLines={1}
              onClick={() => {
                !client &&
                  dispatch(changeTabIndex(0)) &&
                  dispatch(changeAddTask(false));
              }}
              cursor={client ? "default" : "pointer"}
              fontWeight={"bold"}
              color={client ? "#3C4151" : "#3181CE"}
            >
              {client
                ? client["firstName"] + " " + client["lastName"]
                : "Select Client"}
            </Text>
          </Tooltip>
          <IconButton
            colorScheme="transparent"
            boxSize="5"
            color={"#3C4151"}
            cursor={client ? "default" : "pointer"}
            onClick={() => {
              dispatch(changeTabIndex(0)) &&
                dispatch(changeProjectIndex(-1)) &&
                dispatch(changeProject(null)) &&
                dispatch(changeClientIndex(-1)) &&
                dispatch(changeAddTask(false));
            }}
            icon={<EditIcon />}
            aria-label={"Select Client"}
          />
        </HStack>
      </Box>
      {!!client ? (
        <Box h="10" maxWidth={"250px"}>
          <HStack spacing="15px">
            <Icon as={AiFillCodeSandboxCircle} boxSize="7" color={"#E74F4F"} />
            <Tooltip label={project ? project["name"] : "Select Project"}>
              <Text
                noOfLines={1}
                onClick={() => {
                  client &&
                    dispatch(changeTabIndex(1)) &&
                    dispatch(changeProjectIndex(-1)) &&
                    dispatch(changeProject(null)) &&
                    dispatch(changeAddTask(false));
                }}
                cursor={project ? "default" : "pointer"}
                fontWeight={"bold"}
                color={project ? "#3C4151" : "#3181CE"}
              >
                {project ? project["name"] : "Select Project"}
              </Text>
            </Tooltip>
            <IconButton
              colorScheme="transparent"
              boxSize="5"
              color={"#3C4151"}
              cursor={project ? "default" : "pointer"}
              onClick={() => {
                client &&
                  dispatch(changeTabIndex(1)) &&
                  dispatch(changeProjectIndex(-1)) &&
                  dispatch(changeProject(null)) &&
                  dispatch(changeAddTask(false));
              }}
              icon={<EditIcon />}
              aria-label={"Select Project"}
            />
          </HStack>
        </Box>
      ) : null}

      {!!project && project.assignee.length > 0 ? (
        <HStack>
          <AvatarGroup size="sm" fontSize="10">
            {staff.filterByIds(project.assignee).map((staffMember: IStaff) => (
              <TooltipAvatar
                key={staffMember._id}
                name={getFullName(staffMember.firstName, staffMember.lastName)}
                src={staffMember.profile.avatar}
              />
            ))}
          </AvatarGroup>
          <IconButton
            colorScheme="transparent"
            boxSize="5"
            color={"#3C4151"}
            cursor={project ? "default" : "pointer"}
            onClick={() => {
              dispatch(changeShouldShowStaffAssignee(true));
            }}
            icon={<EditIcon />}
            aria-label={"Select Project"}
          />
        </HStack>
      ) : !!project && project.assignee.length === 0 ? (
        "Select staff"
      ) : null}

      {!!project && (
        <>
          <ConfirmationPopover
            confirmationText="project"
            onConfirm={handleDeleteProject}
          >
            <Button
              leftIcon={<MdDelete />}
              size="sm"
              colorScheme="red"
              isLoading={deleteProjectLoading}
            >
              Delete project
            </Button>
          </ConfirmationPopover>
        </>
      )}
    </HStack>
  );
};

export default TopBar;
