/////////////////////
// Chat Section
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Box, BoxProps, Stack } from "@chakra-ui/react";

// Data Imports
import CommentsSection from "./CommentsSection";
import useFetchComments from "hooks/useFetchComments";
import useProjectStore from "hooks/useProjectStore";

// Interfaces

const Chat: FC<BoxProps> = () => {
  const { project } = useProjectStore();
  const { fetchCommentsLoading, comments } = useFetchComments(
    project?._id || ""
  );

  return (
    <Stack>
      <Stack direction={{ base: "column", md: "row" }}>
        <Box as="div" id="top">
          {" "}
        </Box>

        {/* All Comments */}
        <CommentsSection isLoading={fetchCommentsLoading} comments={comments} />

        <Box as="div" id="bottom">
          {" "}
        </Box>
      </Stack>
    </Stack>
  );
};

export default Chat;
