import axios from "axios";
import { API_URL } from "settings/site-credentials";
import { render_project_info__db_project } from "schemas/project_wizard_schema";

type AsyncResult<T> = {
  data: T[];
  error: string;
};

export async function assignProjectToStaffService(
  projectId: string,
  staffIds: string[],
  { authToken }: { authToken: string }
): Promise<AsyncResult<any>> {
  try {
    const { data } = await axios.patch(
      `${API_URL}/projects/${projectId}/addStaff`,
      {
        assigneeIds: staffIds,
      },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

    return { data, error: "" };
  } catch (error) {
    return { data: [], error: "Something went wrong..." };
  }
}

// Handlers
async function createNewProject(project: any, token: string) {
  if (!API_URL) {
    throw new Error("Invalid environmental variable.");
  }

  try {
    const { data, status } = await axios.post(`${API_URL}/projects`, project, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { data, statusCode: status };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

async function updateCurrentProject(
  id: string,
  project: any,
  token: string,
  shouldTrackActivity: boolean
) {
  if (!API_URL) {
    throw new Error("Invalid environmental variable.");
  }

  try {
    const { data, status } = await axios.patch(
      `${API_URL}/projects/${id}?trackActivity=${shouldTrackActivity ? 1 : 0}`,
      project,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return { data, statusCode: status };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export const updateProjectByStatus = async (
  id: string,
  statusData: any,
  token: string
) => {
  try {
    const { data } = await axios.patch(
      `${API_URL}/projects/${id}`,
      statusData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return { data };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
};

export async function submitProjectService(
  projectWizard: any,
  token: string,
  shouldTrackActivity: boolean
) {
  const { id, createdBy,  projectInfo, selectedOutputs, selectedStyles, projectStatus } =
    projectWizard;

  const project = {
    ...(createdBy && createdBy != '' && { createdBy}),
    selectedOutputs: selectedOutputs,
    selectedStyles: selectedStyles,
    status: projectStatus,
    ...render_project_info__db_project(projectInfo),
  };

  if (!id) {
    return createNewProject(project, token);
  } else {
    return updateCurrentProject(id, project, token, shouldTrackActivity);
  }
}

//Getting task by project id
export const getProjectByProjectID = (projectId?: string, authToken?: string) =>
  axios.get(`${API_URL}/projects/${projectId}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const fetchDraftProjectsAxiosRequest = (token: string) =>
  axios.get(`${API_URL}/projects?status=draft`, {
    headers: { Authorization: `Bearer ${token}` },
});


export const deleteProjectByIdService = async (
  projectId: string,
  authToken: string
): Promise<{ data: any; error: string }> => {
  try {
    const { data } = await axios.delete(`${API_URL}/projects/${projectId}`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });

    return { data, error: "" };
  } catch (error) {
    return { data: null, error: "Something went wrong..." };
  }
};

export async function updateProjectService(
  id: string,
  project: any,
  token: string,
  shouldTrackActivity: boolean
) {
  if (!API_URL) {
    throw new Error("Invalid environmental variable.");
  }

  try {
    const { data, status } = await axios.patch(
      `${API_URL}/projects/${id}?trackActivity=${shouldTrackActivity ? 1 : 0}`,
      project,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return { data, statusCode: status };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function deleteProjectService(id: string | number, token: string) {
  try {
    const { data } = await axios.delete(`${API_URL}/projects/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function initializeProjectService(
  projectWizard: any,
  token: string,
  projectStatus?: string,
  shouldTrackActivity?: boolean
) {
  const { id, projectInfo, selectedOutputs, selectedStyles } = projectWizard;

  const project = {
    selectedOutputs: selectedOutputs,
    selectedStyles: selectedStyles,
    status: projectStatus || "draft",
    ...render_project_info__db_project(projectInfo),
  };

  if (!id) {
    return createNewProject(project, token);
  } else {
    return updateCurrentProject(
      id,
      project,
      token,
      shouldTrackActivity || false
    );
  }
}
