import { Box, Flex, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import DeletedProjectsClients from "components/ui/Tabs/DeletedProjectsClients";
import Projects from "components/ui/Tabs/Projects";
import useSettingStore from "hooks/useSettingStore";
const SideBarDeletedProjects = () => {
  const { tabIndex, changeTabIndex } = useSettingStore();

  return (
    <Box id="kk" position="relative" h="full">
      <Flex
        flex="1"
        py={"6"}
        px={{ base: "4", sm: "6" }}
        justify="center"
        h="full"
      >
        <Tabs
          index={tabIndex}
          variant="unstyled"
          colorScheme="blue"
          w="full"
          onChange={(index) => changeTabIndex(index)}
        >
          <TabPanels h="full">
            <TabPanel h="full" pr={0} pl={0}>
              <DeletedProjectsClients />
            </TabPanel>
            <TabPanel pr={0} pl={0} h="full">
              <Projects strictStatus="deleted" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
};

export default SideBarDeletedProjects;
