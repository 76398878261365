/////////////////////
// NavItem (appMenu) UI
/////////////////////

// Basic Imports
import { Link as RouterLink } from "react-router-dom";

// Design Imports
import { Box, HStack, useColorModeValue as mode } from "@chakra-ui/react";
import { FC } from "react";

// Layout and Section Imports

// Data Imports

// Interfaces
interface _NavItemProps {
  href?: string;
  active?: boolean;
  label: string;
}

interface NavItemProps extends _NavItemProps {
  icon: any;
  // eslint-disable-next-line no-unused-vars
  onClickNavItem?: (navItem: NavItemProps) => void;
}

// Functions
const DesktopNavItem: FC<NavItemProps> = (props) => {
  const { icon: Icon, label, href = "#", active, onClickNavItem } = props;
  return (
    <HStack
      as={RouterLink}
      to={href}
      aria-current={active ? "page" : undefined}
      spacing="2"
      px="3"
      py="2"
      rounded="lg"
      transition="all 0.2s"
      // color={mode("white", "gray.300")}
      color={mode("black", "black")}
      // _hover={{ bg: "whiteAlpha.200" }}
      _hover={{ bg: "blackAlpha.200" }}
      _activeLink={{ bg: "blackAlpha.300", color: mode("black", "black") }}
      onClick={() =>
        onClickNavItem && onClickNavItem({ label, href, active, icon: Icon })
      }
    >
      {Icon && (
        <Box aria-hidden fontSize="md">
          {Icon}
        </Box>
      )}
      <Box fontWeight="semibold">{label}</Box>
    </HStack>
  );
};

const MobileNavItem: FC<NavItemProps> = (props) => {
  const { icon: Icon, label, href = "#", active, onClickNavItem } = props;
  return (
    <Box
      as={RouterLink}
      display="flex"
      alignItems="center"
      to={href}
      px="3"
      py="3"
      rounded="lg"
      fontWeight="semibold"
      aria-current={active ? "page" : undefined}
      _hover={{ bg: "blackAlpha.200" }}
      _activeLink={{ bg: "blackAlpha.300", color: mode("black", "black") }}
      onClick={() =>
        onClickNavItem && onClickNavItem({ label, href, active, icon: Icon })
      }
    >
      {Icon && (
        <Box aria-hidden fontSize="md" pr={4}>
          {Icon}
        </Box>
      )}
      {label}
    </Box>
  );
};

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
};
