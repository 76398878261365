/////////////////////
// Nav Menu (appMenu) UI
/////////////////////

// Basic Imports
import { useLocation } from "react-router-dom";

// Design Imports
import { Flex, HStack, Stack } from "@chakra-ui/react";

import { GoRocket } from "react-icons/go";
import { RxDashboard } from "react-icons/rx";

import { NavItem } from "./NavItem";
import { HiUsers } from "react-icons/hi";
import { AiOutlineDelete } from "react-icons/ai";
import { GiShamblingZombie } from "react-icons/gi";
// import { ROUTES } from "settings/constants/routes";

// Layout and Section Imports

// Data Imports

// Interfaces
interface NavItemProps {
  label: string;
  href: string;
  icon: React.ReactNode;
}

interface MobileNavMenuProps {
  isOpen?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClickNavItem?: (navItem: NavItemProps) => void;
}

// Functions

const NAV_ITEMS: NavItemProps[] = [
  { label: "Dashboard", href: "/", icon: <RxDashboard /> },
  { label: "Staff", href: "/staff", icon: <GoRocket /> },
  { label: "Client", href: "/client", icon: <HiUsers /> },
  { label: "Deleted Projects", href: "/deleted", icon: <AiOutlineDelete /> },
  { label: "Zombie Tasks", href: "/zombieTask", icon: <GiShamblingZombie /> },
];

const MobileNavMenu = (props: MobileNavMenuProps) => {
  const { isOpen } = props;
  const { pathname } = useLocation();

  return (
    <Flex
      hidden={!isOpen}
      as="nav"
      direction="column"
      // bg="blue.600"
      bg="gray.100"
      position="fixed"
      height="calc(100vh - 4rem)"
      top="16"
      insetX="0"
      zIndex={10}
      w="full"
      color="black"
    >
      <Stack p="4">
        {NAV_ITEMS.map((item: NavItemProps, idx: number) => {
          const isActive = item.href === pathname;
          return (
            <NavItem.Mobile
              key={idx.toString()}
              active={isActive}
              icon={item.icon}
              label={item.label}
              href={item.href}
              // onClickNavItem={onClickNavItem}
            />
          );
        })}
      </Stack>
    </Flex>
  );
};

const DesktopNavMenu = () => {
  const { pathname } = useLocation();
  return (
    <HStack spacing="3" flex="1" display={{ base: "none", lg: "flex" }}>
      {NAV_ITEMS.map((item: NavItemProps, idx: number) => {
        const isActive = item.href === pathname;
        return (
          <NavItem.Desktop
            key={idx.toString()}
            active={isActive}
            icon={item.icon}
            label={item.label}
            href={item.href}
          />
        );
      })}
    </HStack>
  );
};

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
};
