import { useEffect, useState } from "react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { useBreakpointValue } from "@chakra-ui/react";
import SwiperApp from "libraries/Swiper";
import SwiperWithMultipleItems from "libraries/Swiper/SwiperWithMultipleItems";
import { SwiperSlidePropsCustom } from "libraries/Swiper/types";

// Import data
import useFetchAssets from "hooks/useFetchAssets";
import _ from "lodash";
import { renderEachAssetGallery } from "schemas/assets_schema";

interface Props {
  defaultSelectedAssets?: [];
}
export default function ChatGalleryWithSwiper({
  defaultSelectedAssets = [],
}: Props) {
  const slidersPerView = useBreakpointValue({ base: 2, md: 3, lg: 4 });
  const [cardSlides, setCardSlides] = useState<{
    loading: boolean;
    slides: SwiperSlidePropsCustom[];
  }>({
    loading: false,
    slides: defaultSelectedAssets,
  });
  const [selectedSlide, setSelectedSlide] =
    useState<SwiperSlidePropsCustom | null>(null);
  const [mainSlides, setMainSlides] = useState<{
    loading: boolean;
    slides: SwiperSlidePropsCustom[] | [];
  }>({
    loading: true,
    slides: [],
  });
  const { assets, isLoading } = useFetchAssets();

  useEffect(() => {
    const emptyAssets = _.differenceWith(
      defaultSelectedAssets,
      assets,
      (defaultAsset: any, asset: any) => {
        const assetQuantity =
          typeof asset.quantity === "number"
            ? asset.quantity
            : Number(asset.quantity);

        if (
          defaultAsset.assetType === asset.assetType &&
          defaultAsset.quantity === assetQuantity
        )
          return true;
        return false;
      }
    );

    const uploadedAssets = assets.map((asset) =>
      renderEachAssetGallery({
        id: asset._id,
        assetType: asset.assetType,
        icon: null,
        imgUrl:
          asset.attachments.length > 0
            ? asset.attachments[asset.attachments.length - 1].cdnUrl
            : "",
        quantity: 0,
        title: asset.title,
        name:
          asset.attachments.length > 0
            ? asset.attachments[asset.attachments.length - 1].name
            : "untitled",
        format:
          asset.attachments.length > 0
            ? asset.attachments[asset.attachments.length - 1].format
            : "",
        thumbnailUrl:
          asset.attachments.length > 0
            ? asset.attachments[asset.attachments.length - 1].thumbnailUrl
            : "",
      })
    );
    setCardSlides((prev) => ({
      ...prev,
      slides: [..._.reverse(uploadedAssets), ...emptyAssets],
    }));
  }, [assets]);

  useEffect(() => {
    if (!selectedSlide || cardSlides.slides.length === 0) {
      setMainSlides({ loading: false, slides: [] });
      return;
    }

    const selectedAsset = assets.find(
      (asset) => asset._id === selectedSlide.id
    );

    if (!selectedAsset) {
      setMainSlides({ ...mainSlides, loading: false, slides: [] });
      return;
    }

    setMainSlides &&
      setMainSlides((prevState) => ({
        ...prevState,
        loading: false,
        slides: _.reverse(
          selectedAsset.attachments.map((att: any) => ({
            imgUrl: att.cdnUrl,
            alt: att.notes,
            assetId: selectedAsset._id,
            attchementId: att._id,
            name: att.name,
            count: selectedAsset.attachments.length,
            format: att.format,
            thumbnailUrl: att.thumbnailUrl,
          }))
        ),
      }));
  }, [selectedSlide, cardSlides.slides]);
  return (
    <>
      <SwiperWithMultipleItems
        slides={cardSlides.slides}
        slidesPerView={slidersPerView}
        spaceBetween={10}
        slideToClickedSlide={true}
        selectedSlide={selectedSlide}
        onSlideClick={setSelectedSlide}
        isLoading={isLoading}
      />
      {mainSlides.slides.length > 0 && (
        <SwiperApp
          selectedSlide={null}
          isLoading={mainSlides.loading}
          slides={mainSlides.slides}
        />
      )}
    </>
  );
}
