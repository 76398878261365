import { FC, useState } from "react";
import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  AlertIcon,
  Alert,
  Badge,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import Item from "../misc/Item";
import SpinnerLoading from "../../../loaders/SpinnerLoading";
import { AiOutlineUserAdd } from "react-icons/ai";
import { IUser } from "types/user";
import useClientStore from "hooks/useClientStore";
import useFetchUsers from "hooks/useFetchUsers";
import useUserStore from "hooks/useUserStore";
import useSettingStore from "hooks/useSettingStore";

interface ClientsProps {
  context?: "view" | "edit";
}
const Clients: FC<ClientsProps> = ({ context = "view" }) => {
  const [search, setSearch] = useState("");
  const { clients, isLoading, isError } = useFetchUsers();
  const { resetUser: resetUserStore } = useUserStore();
  const { client: clientStore } = useClientStore();
  const { changeTabIndex } = useSettingStore();

  // Client filterations
  const PAID_AND_VERIFIED_CUSTOMERS = clients
    .search(search)
    .filter(
      (client: IUser) =>
        client?.subscription?.plan !== "none" && !!client.verified
    );
  const UNPAID_AND_VERIFIED_CUSTOMERS = clients
    .search(search)
    .filter(
      (client: IUser) =>
        client?.verified && client?.subscription?.plan === "none"
    );
  const UNVERIFIED_CUSTOMERS = clients
    .search(search)
    .filter((client: IUser) => !client?.verified);

  if (!!isLoading) return <SpinnerLoading />;
  if (!!isError || clients.data.length === 0)
    return (
      <Alert status="info">
        <AlertIcon />
        Nothing to show
      </Alert>
    );
  return (
    <Stack justify="space-between" spacing={4} w="full" h="full" p="1">
      <Stack>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="muted" boxSize="5" />
          </InputLeftElement>
          <Input
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
      </Stack>
      <Box h="90%" overflow="auto" className="hide-scrollbar">
        <Flex direction="column" gap={6}>
          <RenderClients
            context={context}
            label="paid_verified"
            clients={PAID_AND_VERIFIED_CUSTOMERS}
          />
          {PAID_AND_VERIFIED_CUSTOMERS.length > 0 && (
            <Divider w="95%" mx="auto" />
          )}
          <RenderClients
            context={context}
            label="unpaid_verified"
            clients={UNPAID_AND_VERIFIED_CUSTOMERS}
          />
          {UNPAID_AND_VERIFIED_CUSTOMERS.length > 0 && (
            <Divider w="95%" mx="auto" />
          )}
          <RenderClients
            context={context}
            label="unverified"
            clients={UNVERIFIED_CUSTOMERS}
          />
        </Flex>
      </Box>
      {context === "edit" ? (
        <Button
          onClick={resetUserStore}
          leftIcon={<AiOutlineUserAdd size="18" />}
          w="full"
          colorScheme="blue"
          fontSize="sm"
        >
          Add Client
        </Button>
      ) : (
        <Button
          onClick={() => changeTabIndex(1)}
          rightIcon={<HiOutlineArrowNarrowRight />}
          colorScheme="blue"
          disabled={!clientStore}
        >
          Select projects
        </Button>
      )}
    </Stack>
  );
};

type labelTypes = "paid_verified" | "unpaid_verified" | "unverified";
function RenderClients({
  context,
  label,
  clients,
}: {
  clients: IUser[];
  label: labelTypes;
  context: ClientsProps["context"];
}) {
  const { clients: allClients } = useFetchUsers();
  const { updateUser: updateUserStore, user: userStore } = useUserStore();
  const { updateClient: updateClientStore, client: clientStore } =
    useClientStore();
  const { changeTabIndex } = useSettingStore();

  // Handlers
  const renderBadge = (
    label: labelTypes
  ): { colorScheme: string; label: string } => {
    if (label === "paid_verified")
      return { colorScheme: "green", label: "Paid and Verified Clients" };
    if (label === "unpaid_verified")
      return { colorScheme: "orange", label: "Unpaid and Verified Clients" };
    if (label === "unverified")
      return { colorScheme: "red", label: "Unverified Clients" };

    return { colorScheme: "blue", label: "Rest of the Clients" };
  };

  if (clients.length === 0) return null;
  return (
    <>
      {!!label ? (
        <Badge
          variant="solid"
          colorScheme={renderBadge(label).colorScheme}
          w="full"
          textAlign="center"
          rounded="sm"
          letterSpacing="wider"
        >
          {renderBadge(label).label}
        </Badge>
      ) : null}
      <Stack spacing={2}>
        {clients.map((client: IUser) => (
          <Item
            key={client._id}
            icon={FaUserCircle}
            email={client.email}
            badge={client.projects.length}
            company={client?.profile?.company}
            label={client?.firstName + " " + client?.lastName}
            isPaused={!!allClients.isClientPaused(client._id)}
            isGuest={client?.role === "guest"}
            avatar={client?.profile && client?.profile?.avatar}
            active={
              client._id ===
              (context === "edit" ? userStore?._id : clientStore?._id)
            }
            count={context === "edit" ? 0 : client.projects.length}
            onClick={() => {
              if (context === "view") {
                updateClientStore(client);
                changeTabIndex(1);
                return;
              }
              if (context === "edit") {
                updateUserStore(client);
                return;
              }
            }}
          />
        ))}
      </Stack>
    </>
  );
}

export default Clients;
