import { useDisclosure } from "@chakra-ui/react";
import TaskRow from "components/ui/misc/TaskRow";
import { TaskFormProps } from "components/ui/tasks/SubtaskAction";
import useAuth from "hooks/useAuthStore";
import useCustomToast from "hooks/useCustomToast";
import useFetchTask from "hooks/useFetchTask";
import { renderAssetTypeAsDB } from "schemas/assets_schema";
import { getAssignee } from "services/appService";
import { ISelectedOutputs } from "types/project";
import { IStaff } from "types/staff";
import TaskDrawer from "./TaskDrawer";
import { TaskFields, TasksProps } from "./types";

interface RenderTaskRowProps {
  staff: IStaff[];
  task: TaskFields;
  asset?: TasksProps["asset"];
  allAssets?: ISelectedOutputs[];
}
export default function RenderTaskRow({
  staff,
  task,
  asset,
  allAssets,
}: RenderTaskRowProps) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { updateTaskMutationQuery, deleteTaskMutationQuery } = useFetchTask();
  const { user } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //TODO Test and then remove below line
  const assignee: any = getAssignee(task.assignee, staff);
  const { errorToast } = useCustomToast();

  return (
    <>
      <TaskRow
        taskId={task._id}
        taskName={task.name}
        taskStage={task.stage}
        assignee={{
          fullName:
            !!assignee && assignee?.firstName
              ? assignee.firstName + " " + assignee.lastName
              : "",
          avatar: assignee?.profile?.avatar || "",
        }}
        onEdit={onOpen}
      />

      <TaskDrawer
        action="update"
        isOpen={isOpen}
        onClose={onClose}
        staff={staff}
        title={!asset ? "Zombie" : asset.title}
        allAssets={allAssets}
        defaultValues={{
          name: task.name,
          description: task.description,
          status: task.status,
          stage: task.stage,
          assigneeId: task.assignee,
        }}
        submitLoading={updateTaskMutationQuery.isLoading}
        onSubmit={(formData: TaskFormProps) => {
          if (!user._id) {
            return;
          }
          const taskProjectId = task.projectId?._id || "";

          if (!taskProjectId) {
            errorToast({
              title: "Something went wrong...",
              description: "Seems like project reference is unavailable",
            });
            return;
          }
          updateTaskMutationQuery.mutate(
            {
              assetType: asset
                ? (renderAssetTypeAsDB[
                    asset.title.replaceAll(" ", "")
                  ] as string)
                : "other",
              taskId: task._id,
              outputId: formData.outputId,
              projectId: taskProjectId,
              assignee: formData.assignee,
              createdBy: user?._id,
              description: formData.description,
              name: formData.name,
              stage: formData.stage,
            },
            {
              onSuccess: () => onClose(),
            }
          );
        }}
        onDelete={() => {
          deleteTaskMutationQuery.mutate(task._id, {
            onSuccess: onClose,
          });
        }}
        deleteLoading={deleteTaskMutationQuery.isLoading}
      />
    </>
  );
}
