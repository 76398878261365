export function render_cloudinary_comment_db(cloudinaryComment: any) {
  return {
    name: cloudinaryComment.original_filename,
    description: "",
    cloudinaryId: cloudinaryComment.asset_id,
    format: cloudinaryComment.format,
    cdnUrl: cloudinaryComment.secure_url,
    version: cloudinaryComment.version,
    resourceType: "image",
    thumbnailUrl: cloudinaryComment.thumbnail_url,
    isExisting: cloudinaryComment.existing,
  };
}
