import { useEffect, useState } from "react";

// Hook
export default function useDebounce(value: any, delay: any) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      setIsLoading(true);
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setIsLoading(false);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
        setIsLoading(false);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return { debouncedValue, isLoading };
}
