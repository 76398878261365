/* eslint-disable no-unused-vars */

export enum ERRORS {
  GENERIC = "Something went wrong...",
  ACCOUNT = "Something wrong with user account",
  ROLE = "User don't have privileges",
  INVALID_TOKEN = "Invalid token! Try again...",
  FIELDS_REQUIRED = "The fields are required",
  SCHEMA_ERROR = "Invalid schema!",
  UPLOAD_IMAGE = "Something wrong while uploading an image.",
  DUPLICATE_ATTACHMENTS = "Duplicate attachments are not allowed",
  DELETE_PROJECT = "There is something wrong while deleting project!",
  UPDATE_PROJECT = "Something wrong while updating project.",
}

export enum SUCCESS {
  LOGGED_IN = "Logged in successfully",
  TASK_CREATED = "Task created successfully",
  STAFF_CREATED = "Staff created successfully",
  EMAIL_CHANGED = "Email changed successfully",
  PASSWORD_CHANGED = "Password changed successfully",
  COMMENT_UPLOADED = "Comment uploaded successfully",
  COMMENT_DELETED = "Comment deleted successfully",
  COMMENT_UPDATED = "Comment updated successfully",
  ASSET_DELETED = "Asset deleted successfully",
}
