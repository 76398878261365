/////////////////////
// Output Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";
import _ from "lodash";

// Design Imports
import { BoxProps, SimpleGrid } from "@chakra-ui/react";

// Layout and Section Imports
import { OutputCardForSteps } from "./OutputCardForSteps";
import Card from "components/layout/Card";

// Data Imports
import { assets, AssetProps } from "data/projectWizardData";
import CardBody from "components/layout/Card/CardBody";
import CardHeader from "components/layout/Card/CardHeader";

// Interfaces
interface Props {
  styles: BoxProps;
  data: any;
  projectId?: string;
  readOnly?: boolean;
}

// Functions

const OutputCard: FC<Props> = ({ data, styles, projectId }) => {
  return (
    <Card id={projectId} {...styles}>
      <CardHeader title="Output Selected" />
      <CardBody>
        <SimpleGrid maxW="7xl" columns={{ base: 1, md: 2 }} spacing={5}>
          {_.intersectionBy(assets, data, "title").map(
            (currentValue: AssetProps) => {
              const { title, id, actionData } = currentValue;
              return (
                <OutputCardForSteps
                  id={id}
                  key={id}
                  title={title}
                  data={actionData}
                  selectedData={data.find(
                    (selected: any) => selected.title === title
                  )}
                />
              );
            }
          )}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};

export default OutputCard;
