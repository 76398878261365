import {
  Center,
  Flex,
  HStack,
  useColorModeValue as mode,
  Container,
} from "@chakra-ui/react";
import AppLogo from "./AppLogo";
import { MobileHamburgerMenu } from "./MobileHamburgerMenu";
import { NavMenu } from "./NavMenu";
import NotificationMenu from "./NotificationMenu";
import ProfileDropdown from "./ProfileDropdown";
import { useMobileMenuState } from "./useMobileMenuState";

const Header = () => {
  return (
    <Center
      bg="white"
      color={mode("gray.100", "gray.800")}
      px="6"
      minH="16"
      my={0}
      pt={2}
    >
      <Flex
        display={{ base: "inherit", lg: "none" }}
        justify="space-between"
        w="full"
      >
        <MobileHeader />
      </Flex>

      <Container display={{ base: "none", lg: "inherit" }} maxW="7xl">
        <DesktopHeader />
      </Container>
    </Center>
  );
};

function MobileHeader() {
  const { isMenuOpen, toggle, onCloseModal } = useMobileMenuState();

  return (
    <>
      <>
        <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
        <NavMenu.Mobile isOpen={isMenuOpen} onClickNavItem={onCloseModal} />
      </>

      <Flex alignSelf="center">
        <AppLogo />
      </Flex>

      <HStack spacing={{ base: 3, md: 6 }}>
        {/* <NotificationMenu /> */}
        <ProfileDropdown />
      </HStack>
    </>
  );
}

function DesktopHeader() {
  return (
    <Flex w="full" justify="space-between">
      <HStack spacing={4} alignSelf="center">
        <AppLogo />
        <NavMenu.Desktop />
      </HStack>

      <HStack spacing={{ base: 3, md: 6 }}>
        <NotificationMenu />
        <ProfileDropdown />
      </HStack>
    </Flex>
  );
}

export default Header;
