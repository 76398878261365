/////////////////////
// Output Card UI used on Output Step of New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  HStack,
  Icon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import { FaHashtag } from "react-icons/fa";
import useCustomToast from "hooks/useCustomToast";
import { OUTPUT_MAX_QUANTITY } from "settings/constants/projectWizard";

// Interfaces
interface Props {
  min?: number;
  max?: number;
  value?: string;
  defaultValue?: number;
  onChange?:any;
  readOnly?: boolean;
}

const RenderQuantity: FC<Props> = ({
  value,
  defaultValue,
  min,
  onChange,
  readOnly,
}) => {
  const { warningToast } = useCustomToast();

  return (
    <HStack>
      <Icon as={FaHashtag} color="gray.500" />
      <Tooltip
        hasArrow
        placement="auto-start"
        label="How many camera capture locations do you want ?"
      >
        <Text fontWeight="semibold">Quantity: </Text>
      </Tooltip>
      <NumberInput
        isDisabled={readOnly}
        defaultValue={defaultValue ?? 1}
        value={readOnly ? defaultValue : value}
        onChange={(value) => onChange(value)}
        min={min ?? 1}
        max={OUTPUT_MAX_QUANTITY}
      >
        <NumberInputField />
        <NumberInputStepper>
          <Tooltip
            placement="top"
            label={
              Number(value) >= OUTPUT_MAX_QUANTITY
                ? `For adding more than ${OUTPUT_MAX_QUANTITY}, you have to contact staff`
                : ""
            }
          >
            <NumberIncrementStepper
              onClick={() => {
                if (Number(value) >= OUTPUT_MAX_QUANTITY) {
                  warningToast({
                    title: `Not allowed to add more than ${OUTPUT_MAX_QUANTITY} quantity!`,
                    description:
                      "Contact staff if you want to enhance the limit",
                  });
                }
              }}
            />
          </Tooltip>
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </HStack>
  );
};

export default RenderQuantity;
