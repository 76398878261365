import React, { FC, ReactNode } from "react";
import { Card as ChakraCard, CardProps } from "@chakra-ui/react";

interface Props extends CardProps {
  children: ReactNode;
}
const Card: FC<Props> = ({ children }) => {
  return <ChakraCard>{children}</ChakraCard>;
};

export default Card;
