/////////////////////
// Skeleton Loading UI
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Box, Skeleton, SkeletonProps } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services

// Interfaces
interface Props extends SkeletonProps {
  children?: React.ReactElement | React.ReactElement[];
}

// Functions
const SkeletonLoading: FC<Props> = ({ children, ...restProps }) => {
  if (!!children) return <Skeleton {...restProps}>{children}</Skeleton>;

  return (
    <Skeleton {...restProps}>
      <Box h="20" />
    </Skeleton>
  );
};

export default SkeletonLoading;
