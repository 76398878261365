import { useDispatch, useSelector } from "react-redux";
import {
  changeProject,
  changeProjectAssignee,
} from "redux/reducers/projectReducer";
import { RootState } from "redux/store";
import { IProject, ISelectedOutputs } from "types/project";
import useClientStore from "./useClientStore";

const useProjectStore = () => {
  const project = useSelector((state: RootState) => state.project);
  const dispatch = useDispatch();
  const { client, updateClient } = useClientStore();

  const handleDeleteProject = () => {
    dispatch(changeProject(null));

    if (!client) return;

    const updatedClientProjects: IProject[] =
      client?.projects.filter((pr) => pr._id !== project?._id) || [];
    updateClient({ ...client, projects: updatedClientProjects });
  };

  const handleUpdateSelectedOutputs = (
    updatedSelectedOutputs: ISelectedOutputs
  ) => {
    if (!project) return;

    const filteredOutputIndex = project?.selectedOutputs.findIndex(
      (output) => output.title === updatedSelectedOutputs.title
    );

    const clonedSelectedOutputs = project ? [...project.selectedOutputs] : [];
    clonedSelectedOutputs[filteredOutputIndex] = updatedSelectedOutputs;

    const clonedProject = {
      ...project,
      selectedOutputs: clonedSelectedOutputs,
    };

    dispatch(changeProject(clonedProject));
  };

  const handleChangeProject = (project: IProject | null) =>
    dispatch(changeProject(project));

  const handleChangeProjectAssignee = (ids: string[]) =>
    dispatch(changeProjectAssignee(ids));

  const handleUpdateProject = (updatedProject: IProject) => {
    handleChangeProject(updatedProject);

    if (!client) return;

    const targetProjectIndex = client?.projects.findIndex(
      (p) => p._id === updatedProject._id
    );

    const clonedProjects = client ? [...client.projects] : [];
    clonedProjects[targetProjectIndex] = updatedProject;

    const clonedUser = { ...client, projects: clonedProjects };
    updateClient(clonedUser);
  };

  return {
    project,
    changeProject: handleChangeProject,
    updateProject: handleUpdateProject,
    onDeleteProject: handleDeleteProject,
    resetProject: () => handleChangeProject(null),
    changeProjectAssignee: handleChangeProjectAssignee,
    onUpdateSelectedOutputs: handleUpdateSelectedOutputs,
  };
};

export default useProjectStore;
