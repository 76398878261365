/////////////////////
// Output Card UI used on Output Step of New Project Wizard
/////////////////////

// Basic Imports
import React, { FC, useEffect } from "react";

// Design Imports
import {
  HStack,
  Icon,
  Text,
  useColorModeValue as mode,
  Stack,
  Tag,
  Wrap,
} from "@chakra-ui/react";

import { CUIAutoComplete } from "chakra-ui-autocomplete";

import { FaFileDownload } from "react-icons/fa";

// Layout and Section Imports

// Interfaces
import { Item } from "./index";
import { LocationProps } from "data/projectWizardData";
interface Props {
  title: string;
  locations: LocationProps[];
  noOfMaxSelections?: number;
  onSelect?: any;
  readOnly?: boolean;
  selectedLocations?: any[];
}

const RenderCameraCaptureLocation: FC<Props> = ({
  title,
  noOfMaxSelections,
  locations,
  onSelect,
  readOnly,
  selectedLocations,
}) => {
  const [pickerItems, setPickerItems] = React.useState(locations);
  const [selectedItems, setSelectedItems] =
    React.useState<any>(selectedLocations);
  const [hasReachedLimit, setHasReachedLimit] = React.useState(false);

  // DOC: If it is not read only then do nothing
  useEffect(() => {
    if (!readOnly) return;
    setSelectedItems(selectedLocations);

    // eslint-disable-next-line
  }, [selectedLocations]);

  const handleCreateItem = (item: Item) => {
    setPickerItems((curr) => [...curr, item]);
    setSelectedItems((curr: any) => [...curr, item]);
  };

  const handleSelectedItemsChange = (selectedItems?: Item[]) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
      onSelect(selectedItems);
    }
  };

  React.useEffect(() => {
    if (noOfMaxSelections && selectedItems.length > noOfMaxSelections) {
      setSelectedItems(selectedItems.splice(0, noOfMaxSelections));
    }
    if (noOfMaxSelections && selectedItems.length <= noOfMaxSelections) {
      setHasReachedLimit(false);
      return;
    }
  }, [selectedItems, noOfMaxSelections]);

  if (!locations || locations.length === 0) return null;
  return (
    <Stack spacing="0">
      <Stack mb="-2">
        <HStack spacing="0">
          <Icon as={FaFileDownload} color="gray.500" />
          <Text fontWeight="semibold">{title}</Text>
        </HStack>
        {hasReachedLimit && (
          <Text color="red.500">Increase quantity to select more...</Text>
        )}
      </Stack>
      <Stack position="relative">
        {readOnly ? (
          <Wrap mt="4">
            {selectedItems.map((location: any) => (
              <Tag key={location.label} color="gray.500" size="lg">
                {location.label}
              </Tag>
            ))}
          </Wrap>
        ) : (
          <CUIAutoComplete
            label="" // We want icon with label too, but it accepts string only so that's why ignore it
            placeholder="Locations"
            onCreateItem={handleCreateItem}
            items={pickerItems}
            selectedItems={selectedItems}
            disableCreateItem={true} // Don't allow the user to create new list item if it doesn't exist
            listStyleProps={{
              // position: "absolute",
              w: "full",
              backgroundColor: mode("white", "gray.800"),
            }}
            toggleButtonStyleProps={{
              color: mode("black", "white"),
              backgroundColor: mode("gray.200", "gray.700"),
              _hover: { backgroundColor: "gray.300" },
            }}
            listItemStyleProps={{
              _hover: {
                backgroundColor: mode("gray.200", "gray.500"),
                color: mode("black", "white"),
              },
            }}
            onSelectedItemsChange={(changes) => {
              if (noOfMaxSelections && changes?.selectedItems && changes.selectedItems.length > noOfMaxSelections) {
                setHasReachedLimit(true);
              } else {
                setHasReachedLimit(false);
                handleSelectedItemsChange(changes.selectedItems);
              }
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default RenderCameraCaptureLocation;
