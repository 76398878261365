import { ERRORS, SUCCESS } from "constants/toastMessages";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  createComment,
  fetchAllCommentsByProjectId,
} from "services/commentServices";
import useAuth from "./useAuthStore";
import useCustomToast from "./useCustomToast";

// Interfaces
export type CommentsStateTypes = { comments: any[]; uploadLoading: boolean };
const useFetchComments = (projectId: string) => {
  const [{ comments, uploadLoading }, setState] = useState<CommentsStateTypes>({
    comments: [],
    uploadLoading: false,
  });
  const { token } = useAuth();
  const { data, isLoading: fetchCommentsLoading } = useQuery(
    ["comments", projectId],
    async () => await fetchAllCommentsByProjectId(projectId, token),
    { enabled: !!projectId, refetchInterval: 10 * 1000 }
  );
  const { successToast, errorToast } = useCustomToast();

  const generateParentWithChild = (arr: any[]) => {
    const parents = arr.filter((val) => !val.parentId);
    const parentsWithChild = parents.map((parent) => {
      return {
        ...parent,
        //p.parentId and parent.id if both were undefined they will also get in children added check
        children: arr.filter(
          (p) => p.parentId && parent.id && p.parentId === parent.id
        ),
      };
    });
    return parentsWithChild;
  };

  /*
    UseEffects
  */
  useEffect(() => {
    const comments = data?.data?.data?.comments || [];
    const commentsWithParentAndChild = generateParentWithChild(comments);
    if (comments.length > 0)
      setState((state: any) => ({
        ...state,
        comments: commentsWithParentAndChild,
      }));
  }, [data]);

  /*
    Handlers
  */
  const setComments = (comments: any[]) =>
    setState((state) => ({ ...state, comments }));
  const setUploadLoading = (loading: boolean) =>
    setState((state) => ({ ...state, uploadLoading: loading }));

  const handleUploadComment = async ({
    commentText,
    attachments,
  }: {
    commentText: string;
    attachments: any[];
  }) => {
    setUploadLoading(true);

    try {
      const { data, error } = await createComment(
        {
          text: commentText,
          projectId: projectId,
          attachments,
        },
        token
      );

      if (!!error) throw new Error("Something wrong while creating comment");
      const comment = data.comment;
      setComments([...comments, comment]);

      successToast({ title: SUCCESS.COMMENT_UPLOADED });

      return { comment };
    } catch (error) {
      errorToast({ title: ERRORS.GENERIC });
      return { error: "Something went wrong..." };
    } finally {
      setUploadLoading(false);
    }
  };

  return {
    comments,
    onUploadComment: handleUploadComment,
    setComments,
    fetchCommentsLoading,
    uploadLoading,
  };
};

export default useFetchComments;
