import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createUserService,
  deleteUserService,
  fetchAllUsersService,
  updateUserService,
} from "services/userService";
import { USERS_KEY } from "settings/keys/react-query";
import { IProject } from "types/project";
import { IUser, UserFormTypes } from "types/user";
import userFuzzySearch from "utils/fuzzy-search/userFuzzySearch";
import useAuth from "./useAuthStore";
import useClientStore from "./useClientStore";
import useCustomToast from "./useCustomToast";
import useProjectStore from "./useProjectStore";

const useFetchUsers = () => {
  const { token } = useAuth();
  const { client, updateClient } = useClientStore();
  const { successToast, errorToast } = useCustomToast();
  const queryClient = useQueryClient();
  const { project, changeProject } = useProjectStore();
  const { data, isLoading, isSuccess, ...restQueryResponse } = useQuery(
    [USERS_KEY], // TODO: May be we need to add "token" as key
    async () => {
      const { users, error } = await fetchAllUsersService(token || "");
      if (!!error) {
        throw new Error("");
      }
      return users || [];
    },
    {
      enabled: !!token,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      onSuccess: (updatedUsers) => {
        const _user = updatedUsers.find(
          (updatedUser) => updatedUser._id === client?._id
        );
        if (!_user) return;

        const updatedProject = client?.projects.find(
          (pr) => pr._id === project?._id
        );
        changeProject(updatedProject || null);
        updateClient(_user);
      },
    }
  );

  // Handlers
  const updateUserMutationQuery = useMutation({
    mutationFn: async (formData: UserFormTypes) => {
      const { error } = await updateUserService(formData, {
        authToken: token,
      });

      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }
    },
    onSuccess: () => {
      successToast({ title: "Client updated!" });
      queryClient.invalidateQueries([USERS_KEY]);
    },
  });

  const createUserMutationQuery = useMutation({
    mutationFn: async (formData: Omit<UserFormTypes, "_id">) => {
      const { error } = await createUserService(formData, {
        authToken: token,
      });

      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }
    },
    onSuccess: () => {
      successToast({ title: "Client created." });
      queryClient.invalidateQueries([USERS_KEY]);
    },
  });

  const deleteUserMutationQuery = useMutation({
    mutationFn: async (userId: string) => {
      const { error } = await deleteUserService(userId, {
        authToken: token || "",
      });

      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }
    },
    onSuccess: async () => {
      successToast({ title: "Client deleted." });
      queryClient.invalidateQueries([USERS_KEY]);
    },
  });

  const clients = getFilteredUsersFromRole(data || [], ["guest", "customer"]);
  const staff = getFilteredUsersFromRole(data || [], "staff");
  return {
    clients: {
      data: clients,
      search: (query: string, _clients?: IUser[]) =>
        userFuzzySearch(_clients || clients, query),
      getClientsWithDeletedProjects: () =>
        getClientsWithDeletedProjects(clients),
      isClientPaused: (userId: string) => checkIsUserPaused(clients, userId),
      filterByAssignee: (assigneeId: string) =>
        filterClientsWithAssigneeId(clients, assigneeId),
      getAllAssignees: (client: IUser) =>
        getAllAssigneesFromProjectsOfClients(clients, client),
      getAllProjectsWithAssignee: (clientId: string) =>
        getProjectsWithAssignees(clients, clientId),
    },
    staff: {
      data: staff,
      defaultStaffMember: () =>
        staff.find(
          (staffMember) => staffMember.email === "eric.leung@homerender.io"
        ),
      search: (query: string) => userFuzzySearch(staff, query),
      filterByIds: (ids: string[]) =>
        _.intersectionWith(
          staff,
          ids,
          (member: IUser, id: string) => member._id === id
        ),
      filterByAssignee: (assigneeId: string) =>
        clients
          .map((client) => ({
            ...client,
            projects: client.projects.filter((project) => {
              return project.assignee.some(
                (assignee) => assignee === assigneeId
              );
            }),
          }))
          .filter((client) => client.projects.length > 0),
    },
    allUsers: {
      data: data || [],
      search: (query: string) => userFuzzySearch(data || [], query),
    },
    updateUserMutationQuery,
    createUserMutationQuery,
    deleteUserMutationQuery,
    isLoading,
    isSuccess,
    ...restQueryResponse,
  };
};

const filterClientsWithAssigneeId = (clients: IUser[], assigneeId: string) =>
  clients
    .map((client) => ({
      ...client,
      projects: client.projects.filter((project) => {
        return project.assignee.some((assignee) => assignee === assigneeId);
      }),
    }))
    .filter((client) => client.projects.length > 0);

type Role = "customer" | "staff" | "guest";
function getFilteredUsersFromRole(data: IUser[], role: Role | Role[]): IUser[] {
  if (!data || data.length === 0) return [];

  if (!!Array.isArray(role))
    return data.filter((item) => role.some((r) => r === item.role));

  return data.filter((item) => item.role === role);
}

function getProjectsWithAssignees(
  clients: IUser[],
  clientId: string
): IProject[] {
  const targetClientProjects = clients.find(
    (client) => client._id === clientId
  )?.projects;
  if (!targetClientProjects) return [];

  const projects = targetClientProjects.filter(
    (project) => project.assignee.length > 0
  );

  if (!projects || projects.length === 0) return [];

  return projects;
}
function getClientsWithDeletedProjects(clients: IUser[]): IUser[] {
  const filteredClients = clients.filter(
    (client) =>
      client.projects.filter((project) => project.status === "deleted").length >
      0
  );

  return filteredClients;
}

function getAllAssigneesFromProjectsOfClients(
  clients: IUser[],
  targetClient: IUser
): string[] {
  const targetClientProjects = clients.find(
    (client) => client._id === targetClient?._id
  )?.projects;
  if (!targetClientProjects) return [];

  const projects = targetClientProjects.filter(
    (project) => project.assignee.length > 0
  );

  if (!projects || projects.length === 0) return [];

  const assignees = projects.flatMap((project) => project.assignee);
  const uniqueAssignees = _.uniq(assignees);

  return uniqueAssignees;
}

function checkIsUserPaused(users: IUser[], userId: string): boolean {
  const targetUser = users.find((user) => user._id === userId);
  if (!targetUser) return false;

  return targetUser?.subscription?.status === "canceled";
}

export default useFetchUsers;
