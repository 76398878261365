import { Accordion, Box, Stack, VStack } from "@chakra-ui/react";
import TopBar from "../TopBar";
import useFetchTask from "../../../../hooks/useFetchTask";
import SpinnerLoading from "../../../../loaders/SpinnerLoading";
import AssetsWithTasks from "components/section/AssetsWithTasks";
import Chat from "components/section/Chat";
import AccordionItemCustom from "components/ui/common/AccordionItemCustom";
import ProjectSummary from "../ProjectSummary";
import useProjectStore from "hooks/useProjectStore";
import ZombieTasks from "components/section/Tasks/ZombieTasks";
import UploadedAssets from "components/section/UploadedAssets";

const MainSide = () => {
  const { tasks, zombieTasks, isLoading: isTaskLoading } = useFetchTask();
  const { project } = useProjectStore();

  if (!project) return null;
  if (!!isTaskLoading)
    return (
      <VStack spacing="24px" align="start" pt={5} h="full">
        <TopBar />
        <Box
          w="full"
          h="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <SpinnerLoading />
        </Box>
      </VStack>
    );
  return (
    <>
      <TopBar />

      <Accordion allowToggle defaultIndex={[0]}>
        <Stack spacing={4}>
          <AccordionItemCustom title="Tasks">
            <AssetsWithTasks tasks={tasks} />
          </AccordionItemCustom>
          <AccordionItemCustom
            title="Zombie Tasks 🧟"
            count={zombieTasks.length}
          >
            <ZombieTasks tasks={zombieTasks} />
          </AccordionItemCustom>
          <AccordionItemCustom title="Uploaded Assets">
            <UploadedAssets />
          </AccordionItemCustom>
          <AccordionItemCustom title="Chat">
            <Chat />
          </AccordionItemCustom>
          <AccordionItemCustom title="Summary">
            <ProjectSummary />
          </AccordionItemCustom>
        </Stack>
      </Accordion>
    </>
  );
};

export default MainSide;
