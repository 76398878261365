import React, { FC, ReactNode } from "react";
import { CardBody as ChakraCardBody, CardBodyProps } from "@chakra-ui/react";

interface Props extends CardBodyProps {
  children: ReactNode;
}
const CardBody: FC<Props> = ({ children, ...rest }) => {
  return (
    <ChakraCardBody pt="0" {...rest}>
      {children}
    </ChakraCardBody>
  );
};

export default CardBody;
