import { Box, Container, Flex, Spacer, Stack } from "@chakra-ui/react";
import SideBarStaff from "../../section/SideBarStaff";
import UserForm from "components/forms/UserForm";

const CreateStaffPage = () => {
  return (
    <Box w="full" minH="100%" h="full">
      <Container maxW="container.xl" py={{ base: "4", lg: "5" }} h="calc(85vh)">
        <Flex direction="row" align="start" flex="1" h="full">
          <Box w="25%" h="full" bg={"#F4F7FA"} borderRadius={"3xl"}>
            <SideBarStaff />
          </Box>
          <Spacer />
          <Stack spacing={6} w="70%" h="full">
            <UserForm role="staff" />
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
};

export default CreateStaffPage;
