import { Badge, Box, HStack, VStack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Logo } from "../misc/Logo";
import { ProgressGuideLogo } from "../misc/ProgressGuideLogo";

const AppLogo = () => {
  return (
    <Box px="4" position="relative">
      <VStack>
        <Box>
          <Link to="/">
            <Logo
              color="black"
              flexShrink={0}
              marginEnd="10"
              h={{ base: 4, sm: 8, md: 10 }}
            />
          </Link>
          <Badge
            variant="solid"
            colorScheme="green"
            position="absolute"
            top="0"
            right="4"
          >
            Admin
          </Badge>
        </Box>
        <HStack mt='-8px!important'>
          <Text color="gray.800" fontSize="xs" fontWeight="bold" mr="-1">BY</Text>
          <Box>
            <ProgressGuideLogo color="gray.600" />
          </Box>
        </HStack>
      </VStack>

    </Box>
  );
};

export default AppLogo;
