/* eslint-disable no-unused-vars */

import { createSlice } from "@reduxjs/toolkit";
import { IProject } from "types/project";

type State = IProject | null;
const initialState: State = null;
export const projectSlice = createSlice({
  name: "project",
  initialState: initialState as State,
  reducers: {
    changeProject: (state, action) => {
      return (state = action.payload);
    },
    changeProjectAssignee: (state, action) => {
      if (!state) return;

      state.assignee = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeProject, changeProjectAssignee } = projectSlice.actions;

export default projectSlice.reducer;
