import { IProject } from "types/project";

type Status = IProject["status"];
export default function getStatusColorScheme(status: Status) {
  if (status === "deleted") return "red";
  if (status === "paused") return "orange";
  if (status === "active") return "green";
  if (status === "draft") return "gray";
  if (status === "completed") return "blue";
  return "blue";
}
