import axios from "axios";
import { API_URL } from "settings/site-credentials";

export async function fetchAttachmentsByProjectId(
  projectId: string,
  token: string
) {
  try {
    const { data } = await axios.get(
      `${API_URL}/projects/${projectId}/attachments`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function createAttachmentService({
  mediaResponse,
  mediaDescription,
  projectId,
  userToken,
}: any) {
  const body = {
    projectId,
    name: mediaResponse.original_filename,
    description: mediaDescription,
    cloudinaryId: mediaResponse.asset_id,
    format: mediaResponse.format,
    cdnUrl: mediaResponse.url,
    version: mediaResponse.version.toString(), // must be string but cloudindary response provides us in number
    resourceType: mediaResponse.resource_type,
    thumbnailUrl: mediaResponse.thumbnail_url,
  };

  try {
    const { data } = await axios.post(`${API_URL}/attachments`, body, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}


export async function deleteAttachmentByIdService(attachmentId: any, userToken: any) {
  try {
    const { data } = await axios.delete(
      `${API_URL}/attachments/${attachmentId}`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}
