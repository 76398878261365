/////////////////////
// Action Button On Project Section
/////////////////////

// Basic Imports
import { useState, useRef, FC } from 'react';

// Design Imports
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Button, 
  ButtonProps,
} from '@chakra-ui/react'
import { createProject } from 'redux/reducers/settingsReducer';
import { useDispatch } from 'react-redux';


// Layout and Section Imports


// Data Imports


// Custom Hooks and Services


// Interfaces
interface SecondaryButtonProps extends ButtonProps {
  btnText: string
  onSuccess?: () => void
}
interface AlertDialogProps {
  title: string
  description: string
  successBtnText?: string
  onSuccess?: () => void
  secondaryButton?: SecondaryButtonProps
}
interface Props extends ButtonProps {
  btnText: string
  alertDialog?: AlertDialogProps
}

// Functions
const ActionButton:FC<Props> = ({ btnText, alertDialog, ...btnRestProps }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef()
  const { colorScheme } = btnRestProps; 

  const handleSuccess = async () => {
    onClose();

    alertDialog.onSuccess();
  }

  return (
    <>
      <Button onClick={() => { setIsOpen(true); dispatch(createProject(false)) }} {...btnRestProps}>
        {btnText}
      </Button>

      {alertDialog && <AlertDialog
        isOpen={isOpen}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold' mb="-3">
              {alertDialog.title}
            </AlertDialogHeader>
            <AlertDialogCloseButton />

            <AlertDialogBody>
              {alertDialog.description}
            </AlertDialogBody>

            <AlertDialogFooter>
              {alertDialog.secondaryButton ? 
                <Button
                  onClick={async () => {
                    await alertDialog.secondaryButton.onSuccess();
                    onClose();
                  }} 
                  {...alertDialog.secondaryButton}
                >
                  {alertDialog.secondaryButton.btnText}
                </Button> : 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-ignore
                <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>}
              <Button colorScheme={colorScheme === 'transparent' ? 'blue'  : colorScheme} onClick={handleSuccess} ml={3}>
                {alertDialog.successBtnText || btnText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>}
    </>
  )
}

export default ActionButton