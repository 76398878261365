/////////////////////
// Supporting Document Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC, useState } from "react";

// Design Imports
import {
  // Box,
  Button,
  // Center,
  FormControl,
  FormLabel,
  // Text,
  Textarea,
  // useColorModeValue as mode,
  // Progress,
} from "@chakra-ui/react";
import { chakra } from "@chakra-ui/system";
import PageContent from "components/ui/appPages/PageContent";
import { useProject } from "context/project/ProjectProvider";
import { createAttachmentService } from "services/attachmentsService";
import useAuth from "hooks/useAuthStore";
import useCloudinaryWidget from "hooks/useCloudinaryWidget";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS } from "settings/constants/toastMessages";
import { FaUpload } from "react-icons/fa";
import WizardAttachmentPreview from "components/ui/upload/cloudinary/WizardAttachmentPreview";

const UploadingForm: FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [activeImage, setActiveImage] = useState<any>(null);
  const [activeDescription, setActiveDescription] = useState("");
  const {
    projectWizard,
    supportingDocuments,
    onAddSupportingDocuments,
    onSaveProjectWizardAsDraft,
  } = useProject();
  const { errorToast, warningToast } = useCustomToast();
  const { token }: any = useAuth();
  const { beginUpload, loading: widgetLoading } = useCloudinaryWidget({
    // const { loading: widgetLoading } = useCloudinaryWidget({
    onSuccess: (photo) => setActiveImage(photo.info),
  });
  useCloudinaryWidget({
    onSuccess: (photo) => setActiveImage(photo.info),
  });

  const handleSubmit = async () => {
    setLoading(true);

    let projectId = "";
    if (!projectWizard.id) {
      const { data }: any = await onSaveProjectWizardAsDraft();

      if (!data.success) {
        warningToast({
          title: "Project is not initialized yet!!",
          description: "You cannot upload attachments until you save project.",
        });
        return;
      }

      const id: string = await data?.data?.project?._id;
      if (!id) {
        warningToast({ title: "Schema invalid!" });
      }
      projectId = id || "";
    }

    const { data, error } = await createAttachmentService({
      mediaResponse: activeImage,
      mediaDescription: activeDescription,
      projectId: projectWizard.id || projectId,
      userToken: token,
    });
    setLoading(false);

    // If error then show toast message
    if (!!error) {
      errorToast({ title: ERRORS.GENERIC });

      return;
    }

    // Updating state with new document
    const newDocuments: any[] = supportingDocuments;
    newDocuments.unshift(data.data.attachment);

    onAddSupportingDocuments(newDocuments);

    // Reset active filters
    setActiveImage(null);
    setActiveDescription("");
  };

  return (
    <PageContent minW="10rem">
      <chakra.form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {!activeImage ? (
          <Button
            variant="outline"
            w="full"
            leftIcon={<FaUpload />}
            border="dashed"
            color="grey"
            loadingText="Loading widget..."
            isLoading={widgetLoading}
            onClick={() =>
              beginUpload("image", projectWizard?.projectInfo?.id)
            }
          >
            Click here to upload
          </Button>
        ) : (
          <WizardAttachmentPreview
            url={activeImage.secure_url}
            fileName={activeImage.original_filename}
            format={activeImage.format}
            size={activeImage.bytes}
            onDelete={async () => {
              setActiveImage(null);
              setActiveDescription("");
            }}
          />
        )}
        <FormControl mt="5" id="AssetDescription">
          <FormLabel>File Description</FormLabel>
          <Textarea
            value={activeDescription}
            onChange={(e) => setActiveDescription(e.currentTarget.value)}
            placeholder="Details about file you just uploaded"
            onKeyPress={(e) => {
              if (!e.shiftKey && e.key === "Enter") {
                handleSubmit();
              }
            }}
          />
          <Button
            isLoading={loading}
            isDisabled={!activeImage}
            mt={5}
            colorScheme="blue"
            type="submit"
          >
            Upload
          </Button>
        </FormControl>
      </chakra.form>
    </PageContent>
  );
};

export default UploadingForm;

// LEGACY
/**
 * (
          <Box
            border="3px dashed currentColor"
            color={mode("gray.200", "gray.600")}
            rounded="lg"
            minH="150"
            mb="5"
            position="relative"
          >
            {!!widgetLoading ? (
              <Box position="absolute" top="35%" bottom="50%" w="full" px="5">
                <Text textAlign="center" py="1">
                  Loading widget...
                </Text>
                <Progress size="xs" isIndeterminate />
              </Box>
            ) : (
              <Center minH="150">
                {widgetLoading ? (
                  <Text as="u">Loading...</Text>
                ) : (
                  <Text
                    as="u"
                    cursor="pointer"
                    userSelect="none"
                    onClick={() =>
                      beginUpload("image", projectWizard?.projectInfo?.name)
                    }
                  >
                    {" "}
                    Click here to upload{" "}
                  </Text>
                )}
              </Center>
            )}
          </Box>
        )
 */
