import axios from "axios";
import { render_cloudinary_comment_db } from "schemas/comment_schema";
import { API_URL } from "settings/site-credentials";

interface DataProps {
  text: string;
  projectId: string;
  attachments: any[];
}
export async function createComment(data: DataProps, userToken: string) {
  const body = {
    text: data.text,
    projectId: data.projectId,
    attachments: data.attachments.map((att) =>
      render_cloudinary_comment_db(att)
    ),
  };

  try {
    const { data } = await axios.post(`${API_URL}/comments`, body, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

    return { data: data.data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function fetchAllCommentsByProjectId(
  projectId: string,
  userToken: string
) {
  try {
    const { data } = await axios.get(
      `${API_URL}/projects/${projectId}/comments`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function fetchAllCommentsByAttachmentId(
  attachmentId: string,
  userToken: string
) {
  try {
    const { data } = await axios.get(
      `${API_URL}/attachments/${attachmentId}/comments`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}


export async function deleteCommentFromDB(id: string, token: string) {
  try {
    const { data } = await axios.delete(`${API_URL}/comments/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export async function updateCommentInDB(
  id: string,
  reqData: any,
  token: string
) {
  try {
    const { data } = await axios.put(
      `${API_URL}/comments/${id}`,
      reqData,
      {
        headers: { Authorization: `Bearer ${token || ""}` },
      }
    );

    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}
