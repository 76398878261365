import axios from "axios";
import { API_URL } from "settings/site-credentials";

export async function fetchAssetsByProjectId(projectId: string, token: string) {
  try {
    const res = await axios.get(`${API_URL}/projects/${projectId}/assets`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const assets = res.data.data.assets as any[];
    return { assets, res };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
}

export async function deleteAttachmentById(
  attachmentId: string,
  token: string
) {
  try {
    await axios.delete(`${API_URL}/attachments/${attachmentId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return true;
  } catch (error) {
    return false;
  }
}

export async function deleteAssetById(assetId: string, token: string) {
  try {
    await axios.delete(`${API_URL}/assets/${assetId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return true;
  } catch (error) {
    return false;
  }
}
