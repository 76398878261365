import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  AlertIcon,
  Alert,
  Button,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import Item from "../misc/Item";
import SpinnerLoading from "../../../loaders/SpinnerLoading";
import { useState } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import useFetchStaffs from "hooks/useFetchStaff";
import { IStaff } from "types/staff";
import useStaffStore from "hooks/useStaffStore";
const Staff = () => {
  const [search, setSearch] = useState("");
  const { staff, isLoading, isSuccess } = useFetchStaffs();
  const {
    staffMember: staffMemberStore,
    updateStaffMember,
    resetStaffMember,
  } = useStaffStore();

  if (!!isLoading) return <SpinnerLoading />;
  if (!!isSuccess && staff.data.length === 0)
    return (
      <Alert status="info">
        <AlertIcon />
        Nothing to show
      </Alert>
    );
  return (
    <Stack justify="space-between" spacing={6} w="full" h="full">
      <Box>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="muted" boxSize="5" />
          </InputLeftElement>
          <Input
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
      </Box>
      <Box h="90%" overflow="auto" className="hide-scrollbar">
        <Stack spacing={2}>
          {staff.search(search).map((staffMember: IStaff) => {
            return (
              <Item
                key={staffMember._id}
                label={staffMember.firstName + " " + staffMember.lastName}
                email={staffMember.email}
                icon={FaUserCircle}
                avatar={staffMember.profile && staffMember.profile?.avatar}
                active={staffMember._id === staffMemberStore?._id}
                onClick={() => updateStaffMember(staffMember)}
              />
            );
          })}
        </Stack>
      </Box>
      <Button
        onClick={resetStaffMember}
        leftIcon={<AiOutlineUserAdd size="18" />}
        w="full"
        colorScheme="blue"
        fontSize="sm"
      >
        Add Staff Member
      </Button>
    </Stack>
  );
};

export default Staff;
