/////////////////////
// [Replace me] Page
/////////////////////

// Basic Imports
import { useEffect, useState } from "react";

// Design Imports

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services
import { openUploadWidget } from "services/cloudinaryService";
import useCustomToast from "./useCustomToast";
import { getUploadOptions } from "settings/cloudinary";
import { ERRORS } from "constants/toastMessages";

// Interfaces
interface Props {
  // eslint-disable-next-line no-unused-vars
  onSuccess?: (photo: any) => void;
  allowDuplicate?: boolean;
}

// Functions
const useCloudinaryWidget = (props?: Props) => {
  const [{ loading, uploadedFiles }, setState] = useState({
    loading: false,
    uploadedFiles: [],
  });
  const { errorToast } = useCustomToast();

  // WARN: Don't remove it otherwise cloudinary widget will not work
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const resetState = () => {
    setState((state) => ({ ...state, loading: false, uploadedFiles: [] }));
  };

  const onDeleteFile = (file: any) => {
    setState((state) => ({
      ...state,
      uploadedFiles: uploadedFiles.filter(
        (f: any) => f.secure_url !== file.secure_url
      ),
    }));
  };

  const beginUpload = async (tag: string, projectName?: string) => {
    setState((state) => ({ ...state, loading: true }));

    const uploadOptions = getUploadOptions({
      tag,
      projectName: projectName || "Unknown",
    });

    // TODO: Wrap this function inside useMemo, that can fix the issue of unnecessary re-renderings
    openUploadWidget(uploadOptions, async (error: any, photo: any) => {
      setState((state) => ({ ...state, loading: false }));
      if (!error) {
        if (photo.event === "success") {
          const isExist = uploadedFiles.some(
            (f: any) => f.etag === photo.info.etag
          );
          if (!!isExist) {
            errorToast({ title: ERRORS.DUPLICATE_ATTACHMENTS });
          } else {
            if (!!props?.onSuccess) {
              props.onSuccess(photo);
            }
            setState((state: any) => ({
              ...state,
              uploadedFiles: [photo.info, ...uploadedFiles],
            }));
          }
        }
      } else {
        // Do something
      }
    });
  };

  return {
    loading,
    beginUpload,
    uploadedFiles,
    onDeleteFile,
    resetState,
  };
};

export default useCloudinaryWidget;
