import axios from "axios";
import { API_URL } from "settings/site-credentials";

// Types and Interfaces
import { IUser, UserFormTypes } from "types/user";

export async function fetchAllUsersService(authToken: string) {
  try {
    const { data } = await axios.get(`${API_URL}/users`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });

    const users = data.data.users as IUser[];
    return { users };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function fetchAllStaffService(authToken: string) {
  try {
    const { data } = await axios.get(`${API_URL}/staffs`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });

    const users = data.data.staffs as IUser[];
    return { users };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function updateCommunicationService(
  token: string,
  {
    appUpdates,
    newsletters,
    promotional,
  }: { appUpdates: boolean; newsletters: boolean; promotional: boolean }
) {
  try {
    const response = await axios.patch(
      `${API_URL}/users/account`,
      {
        preferences: {
          notifications: {
            appUpdates,
            newsletters,
            promotional,
          },
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data: response.data, status: response.data.success };
  } catch (err) {
    console.log({ err });
    return { error: "Something went wrong..." };
  }
}

export async function updateUserService(
  formData: UserFormTypes,
  options?: { authToken: string }
) {
  try {
    const { data } = await axios.patch(
      `${API_URL}/users/${formData._id}`,
      formData,
      {
        headers: { Authorization: `Bearer ${options?.authToken || ""}` },
      }
    );

    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export async function createUserService(
  formData: Omit<UserFormTypes, "_id">,
  options?: { authToken: string }
) {
  try {
    const { data } = await axios.post(
      `${API_URL}/users`,
      {
        firstName: formData.firstName,
        lastName: formData.lastName,
        role: formData.role,
        email: formData.email,
        password: formData.password,
        // ...(formData.role === "staff" ? { asanaId: formData.asanaId } : {}), // Dynamic: Only add assignId if role is staff
      },
      {
        headers: { Authorization: `Bearer ${options?.authToken || ""}` },
      }
    );
    return { data };
  } catch (error) {
    console.log({ error });
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export async function deleteUserService(
  userId: string,
  options?: { authToken: string }
) {
  try {
    const { data } = await axios.delete(`${API_URL}/users/${userId}`, {
      headers: { Authorization: `Bearer ${options?.authToken || ""}` },
    });

    return { user: data.data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}
