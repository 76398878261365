import { Box, Flex, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import Clients from "../ui/Tabs/Clients";
import Projects from "../ui/Tabs/Projects";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { changeTabIndex } from "../../redux/reducers/settingsReducer";

const SideBar = () => {
  const dispatch = useDispatch();
  const tabIndex = useSelector((state: RootState) => state.setting.tabIndex);

  return (
    <Box position="relative" h="full">
      <Flex
        flex="1"
        py={{ base: "5", sm: "5" }}
        px={{ base: "4", sm: "6" }}
        justify="center"
        h="full"
      >
        <Tabs
          index={tabIndex}
          variant="unstyled"
          colorScheme="blue"
          w="full"
          onChange={(index) => dispatch(changeTabIndex(index))}
        >
          <TabPanels h="full">
            <TabPanel pr={0} pl={0} pt={2} h="full">
              <Clients context="view" />
            </TabPanel>
            <TabPanel pr={0} pl={0} pt={2} h="full">
              <Projects />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
};

export default SideBar;
