import { Box, Container, Flex, Spacer } from "@chakra-ui/react";
import SideBarClients from "components/section/SideBarClients";
import UserForm from "components/forms/UserForm";

const ClientPage = () => {
  return (
    <Box w="full" minH="100%" h="full">
      <Container maxW="container.xl" py={{ base: "4", lg: "5" }} h="calc(85vh)">
        <Flex direction="row" align="start" flex="1" h="full">
          <Box w="25%" h="full" bg={"#F4F7FA"} borderRadius={"3xl"}>
            <SideBarClients />
          </Box>
          <Spacer />
          <Box w="70%" h="full">
            <UserForm role="customer" />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default ClientPage;
