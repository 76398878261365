import axios from "axios";
import { API_URL } from "settings/site-credentials";
import { ITaskFormData, taskStatusTypes } from "types/task";

export async function createTaskService(
  formData: ITaskFormData,
  token: string
) {
  try {
    const { data } = await axios.post(
      `${API_URL}/tasks`,
      {
        ...formData,
        status: !!formData.assignee ? "assigned" : "unassigned",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

//Getting all tasks
export const getAllTask = (authToken?: string) =>
  axios.get(`${API_URL}/tasks/`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export async function updateTaskService(
  taskId: string,
  formData: ITaskFormData,
  token: string
) {
  const body = Object.fromEntries(
    Object.entries(formData).filter(([key]) => key !== "taskId")
  );

  try {
    const { data } = await axios.put(`${API_URL}/tasks/${taskId}`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export function getTaskStatusLabel(status: taskStatusTypes): string {
  if (status === "assigned") return "Assigned";
  if (status === "completed") return "Completed";
  if (status === "in-progress") return "In Progress";
  if (status === "unassigned") return "Unassigned";
  if (status === "unseen") return "Unseen";
  return "";
}
