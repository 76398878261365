//////////////////////////////////////
// Styles Cards for Summary Section
/////////////////////////////////////

// Basic Imports
import { SimpleGrid } from "@chakra-ui/react";

// Context API
// UI or Layouts
// import { StyleCardForSteps } from "components/ui/common/StyleCardForSteps";
import { FC } from "react";
import { StyleCardForSteps } from "./StyleCardForSteps";

interface Props {
  projectWizard: any;
}
const StyleCardsReadOnly: FC<Props> = ({ projectWizard }) => {
  return (
    // <Card>
    //   <CardHeader title="Styles" />
    //   <CardBody>
    <SimpleGrid maxW="7xl" columns={1} spacing={5} pb={5}>
      {projectWizard.selectedAssets.map((currentValue: any, index: number) => {
        return (
          <StyleCardForSteps
            key={index}
            id={currentValue?.id}
            title={currentValue?.title}
            options={currentValue.stylesData}
            isReadOnly={true}
            showSelectedOnly={true}
            selectedStyles={projectWizard.selectedStyles}
          />
        );
      })}
    </SimpleGrid>
    //   </CardBody>
    // </Card>
  );
};

export default StyleCardsReadOnly;
