import FuzzySearch from "fuzzy-search";
import { useDispatch, useSelector } from "react-redux";
import {
  changeClientState,
  resetClientState,
} from "redux/reducers/clientReducer";
import { RootState } from "redux/store";
import { IProject } from "types/project";
import { IUser } from "types/user";
import { removeWhiteSpace } from "utils/string";

// type ProjectType = any;
interface ReturnProps {
  client: IUser | null;
  // eslint-disable-next-line no-unused-vars
  searchProjects: (searchQuery: string) => IProject[];
  resetClient: () => void;
  // eslint-disable-next-line no-unused-vars
  updateClient: (user: IUser) => void;
}
const useClientStore = (): ReturnProps => {
  const dispatch = useDispatch();
  const client: IUser | null = useSelector((state: RootState) => state.client);

  const updateClient = (user: IUser) => {
    dispatch(changeClientState(user));
  };

  return {
    client,
    searchProjects: (searchQuery: string) =>
      new FuzzySearch(client?.projects || [], ["name"]).search(
        removeWhiteSpace(searchQuery)
      ),
    resetClient: () => dispatch(resetClientState()),
    updateClient,
  };
};

export default useClientStore;
