import axios from "axios";
import { API_URL } from "settings/site-credentials";
import { IStaffForm } from "types/forms";
import { IStaff } from "types/staff";

export async function fetchAllStaffService(authToken: string) {
  try {
    const { data } = await axios.get(`${API_URL}/staffs`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });

    const staff = data.data.staffs as IStaff[];
    return { staff };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function updateStaffMember(
  staffMemberId: string,
  formData: IStaffForm,
  token: string
) {
  try {
    const { data } = await axios.patch(
      `${API_URL}/staffs/${staffMemberId}`,
      {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        // role: formData.role || "staff", // TODO: It should be dynamic, it can be "admin" or "staff"
        password: formData.password,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export async function createStaffMember(
  formData: Omit<IStaffForm, "id">,
  authToken: string
) {
  try {
    const { data } = await axios.post(
      `${API_URL}/staffs`,
      {
        firstName: formData.firstName,
        lastName: formData.lastName,
        role: "staff",
        email: formData.email,
        password: formData.password,
      },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export async function deleteStaffMember(staffId: string, authToken: string) {
  try {
    const { data } = await axios.delete(`${API_URL}/staffs/${staffId}`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });

    return { user: data.data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}
