/////////////////////
// Notification (appPages) UI
/////////////////////

// Basic Imports
import React from "react";

// Design Imports
import { Text, CenterProps, Stack, Divider } from "@chakra-ui/react";
import { Link } from "react-router-dom";

// Layout and Section Imports

// Data Imports

import { formatDate } from "utils/date";
import colors from "settings/theme/colors";
import useAuth from "hooks/useAuthStore";

// Interfaces
interface NotificationBodyProps extends CenterProps {
  // eslint-disable-next-line no-unused-vars
  onClickNotification: (notification: any) => void;
  data: any[];
}

export const NotificationBody = React.forwardRef(
  (props: NotificationBodyProps) => {
    return (
      <Stack w="full" spacing={2}>
        {props.data.map((item) => {
          return (
            <NotificationCard
              onClickNotification={props.onClickNotification}
              key={item._id}
              data={item}
            />
          );
        })}
      </Stack>
    );
  }
);

interface NotificationCardProps extends NotificationBodyProps {
  data: any;
}
export function NotificationCard({
  data,
  onClickNotification,
}: NotificationCardProps) {
  const { userId } = useAuth();

  function checkIfRead(userIds: any[]) {
    return userIds.some((user: any) => user.userId === userId);
  }
  return (
    <>
      <Stack
        onClick={() =>
          onClickNotification({
            notificationId: data._id,
            isRead: checkIfRead(data.readBy),
          })
        }
        as={Link}
        // to={getNotificationLink(data.metaData)}
        to={"#"}
        borderLeft={!checkIfRead(data.readBy) ? "4px solid #2b6cb0" : ""}
        w="full"
        p="2"
      >
        <Stack spacing={1}>
          <Text fontWeight="semibold" fontSize="16px" color={colors.blue}>
            {data.metaData.title}
          </Text>
        </Stack>
        <Text fontSize="16">{data.metaData.description}</Text>

        <Text fontSize="12" color="GrayText" fontStyle="italic">
          {formatDate(new Date(data.updatedAt))}
        </Text>
      </Stack>
      <Divider />
    </>
  );
}
