export const renderAssetTypeAsDB: any = {
  "2DFloorPlan": "2d_floor_plan",
  "LandscapePlan": "landscape_plan",
  "MarketingBrochure": "marketing_brochure",
  "SpecSheet": "specification_sheet",
  "3DFloorPlan": "floor_plan_3d",
  "3DRender": "3d_render",
  "3DModel": "3d_model",
  "VirtualStaging": "virtual_stating",
  "3dCommercial": "commercial_3d",
  "Other": "other",
};

export function renderEachAssetGallery({
  id,
  title,
  quantity,
  icon,
  assetType,
  imgUrl,
}: any) {
  return {
    id: id,
    alt: title || "-",
    quantity: quantity,
    icon: icon,
    assetType,
    imgUrl,
  };
}
