import {
  Box,
  Flex,
  Icon,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import Clients from "components/ui/Tabs/Clients";
import useClientStore from "hooks/useClientStore";
import { AiFillCodeSandboxCircle } from "react-icons/ai";

const SideBarClients = () => {
  const { client: clientStore } = useClientStore();
  return (
    <Box id="kk" position="relative" h="full">
      <Flex
        flex="1"
        py={{ base: "8", sm: "8" }}
        px={{ base: "4", sm: "6" }}
        justify="center"
        h="full"
      >
        <Tabs variant="unstyled" colorScheme="blue" w="full">
          <TabList style={{ justifyContent: "space-between" }}>
            <Tab
              w="full"
              _selected={{
                color: "white",
                bg: "blue.500",
                border: "2px solid #3182ce",
              }}
              border="2px solid #9AA9B8"
              color="#9AA9B8"
              borderRadius="10px"
              isDisabled={!clientStore}
            >
              <Icon as={AiFillCodeSandboxCircle} boxSize="5" mr={2} />
              <Text>Clients</Text>
            </Tab>
          </TabList>
          <TabPanels h="full" pt={4}>
            <TabPanel h="full" pr={0} pl={0} pt={5}>
              <Clients context="edit" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
};

export default SideBarClients;
