import { Box, Flex, HStack, Stack } from "@chakra-ui/react";
import LoginForm from "../components/forms/LoginForm";

const Login = () => (
  <Box
    py={{ base: "12", md: "24" }}
    maxW="7xl"
    mx="auto"
    h="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <HStack>hi</HStack>
    <Stack direction="row" spacing="12">
      <Flex flex="1">
        <LoginForm />
      </Flex>
    </Stack>
  </Box>
);

export default Login;
