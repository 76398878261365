import { Box } from "@chakra-ui/react";
import ChatGalleryWithSwiper from "./ChatGalleryWithSwiper";

const UploadedAssets = () => {
  return (
    <Box>
      <ChatGalleryWithSwiper />
    </Box>
  );
};

export default UploadedAssets;
