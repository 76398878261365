import { Stack } from "@chakra-ui/react";
import ProjectInfoCard from "components/section/ProjectWizard/ProjectInfoCard";
import useProjectStore from "hooks/useProjectStore";
import _ from "lodash";
import {
  render_db_project__project_info,
  render_project_wizard_schema_from_db_project,
} from "schemas/project_wizard_schema";
import { assets } from "data/projectWizardData";
import DesiredAssetsCard from "components/section/ProjectWizard/DesiredAssetsCard";
import OutputCard from "components/section/ProjectWizard/OutputCard";
import { useEffect, useState } from "react";
import StyleCardsReadOnly from "components/section/ProjectWizard/StyleCardsReadOnly";
import SupportingDocumentsStep from "components/section/ProjectWizard/SupportingDocuments/SupportingDocumentsStep";

const ProjectSummary = () => {
  const { project } = useProjectStore();
  const [imaginaryProjectWizard, setImaginaryProjectWizard] =
    useState<any>(null);

  useEffect(() => {
    if (!project) return;

    const projectWizard = render_project_wizard_schema_from_db_project(project);
    setImaginaryProjectWizard(projectWizard);
  }, [project]);

  if (!project) return null;
  return (
    <Stack spacing={4}>
      <ProjectInfoCard
        styles={{ my: 5 }}
        data={render_db_project__project_info(project)}
        projectId={project._id}
        readOnly={true}
      />

      {project.selectedStyles.length > 0 && (
        <DesiredAssetsCard
          styles={{ my: 5 }}
          assets={_.intersectionBy(assets, project.selectedStyles, "title")}
          projectId={project._id}
          readOnly={true}
        />
      )}

      {project.selectedOutputs.length > 0 && (
        <OutputCard
          styles={{ my: 5 }}
          data={project.selectedOutputs}
          projectId={project._id}
          readOnly={false}
        />
      )}

      {imaginaryProjectWizard?.selectedStyles.length > 0 && (
        <StyleCardsReadOnly projectWizard={imaginaryProjectWizard} />
      )}

      {imaginaryProjectWizard?.supportingDocuments?.length > 0 && (
        <SupportingDocumentsStep projectId={project._id} showHeader />
      )}
    </Stack>
  );
};

export default ProjectSummary;
