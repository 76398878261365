/////////////////////
// Projects Container
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  Container,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Custom Hook Imports

// Functions

// Interfaces

const ProjectsContainer: FC<any> = ({ children, ...restProps }) => {
  return (
    <Container
      bg={mode("white", "blackAlpha.100")}
      overflow="auto"
      maxW="container.xl"
      mx="auto"
      py="2"
      pb="8"
      px={{ base: "2", md: "6" }}
      rounded="lg"
      {...restProps}
    >
      {children}
    </Container>
  );
};

export default ProjectsContainer;
