import { useQuery } from "react-query";
import { fetchAssetsByProjectId } from "services/assetService";
import useAuth from "./useAuthStore";
import useProjectStore from "./useProjectStore";

const useFetchAssets = () => {
  const { token } = useAuth();
  const { project } = useProjectStore();

  const { data, ...restQueryResponse } = useQuery(
    ["assets", project?._id],
    () => fetchAssetsByProjectId(project?._id || "", token),
    {
      enabled: !!project?._id,
    }
  );

  return {
    assets: data?.assets || [],
    ...restQueryResponse,
  };
};

export default useFetchAssets;
