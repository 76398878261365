import { Skeleton } from "@chakra-ui/react";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

//PDFjs worker from an external cdn
export default function PDFViewer({ url }: { url: string }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess() {
    setLoading(false);
    setPageNumber(1);
  }
  return (
    <Skeleton
      isLoaded={!loading}
      border="2px"
      rounded="md"
      p={2}
      minH={"200px"}
    >
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} renderAnnotationLayer={undefined} />
      </Document>
    </Skeleton>
  );
}
