import { useDispatch, useSelector } from "react-redux";
import { RESET_ACTION } from "redux/actions";
import { RootState } from "redux/store";
import jwt_decode from "jwt-decode";

const useAuth = () => {
  const user = useSelector((state: RootState) => state.authUser);
  const dispatch = useDispatch();

  let extractedToken: any = "";  // Initialize the variable with an empty string
  if (user?.token) {
    extractedToken = jwt_decode(user.token);  // Only call jwt_decode if user?.token exists
  }

  const handleLogout = () => {
    dispatch(RESET_ACTION);
  };

  return {
    token: user?.token || "",
    user,
    handleLogout,
    userId: extractedToken?._id || "",
  };
};

export default useAuth;
