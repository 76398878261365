import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Container,
  HStack,
  Icon,
  IconButton,
  List,
  Stack,
  Wrap,
} from "@chakra-ui/react";
import { ListItem } from "components/ui/ListWithVerticalLine/ListItem";
import { FC, useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import VisibilitySensor from "react-visibility-sensor";
import { FaReply } from "react-icons/fa";
import { getCommentSender } from "utils/getCommentSender";
import { formatDate } from "utils/date";
import { BsChatSquareText } from "react-icons/bs";
import SkeletonLoading from "loaders/SkeletonLoading";
import useAuth from "hooks/useAuthStore";
import parse from "html-react-parser";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  deleteCommentFromDB,
  updateCommentInDB,
} from "services/commentServices";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "constants/toastMessages";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FilePreview } from "hr-design-system";

interface Props {
  isLoading: boolean;
  comments: any[];
}
const CommentsSection: FC<Props> = ({ isLoading, comments }) => {
  const endMessageDiv = useRef<HTMLDivElement>(null);
  const [atLastComment, setAtLastComment] = useState(false);
  const [commentsToRender, setCommentsRender] = useState(comments);
  const removedeletedComment = (comment: any) => {
    const toRender = comments.filter((c) => c._id != comment._id);
    setCommentsRender(toRender);
  };

  const updateModifiedComment = (comment: any) => {
    const commentsToRender: any[] = [];
    comments.forEach((c) => {
      if (c._id === comment._id) {
        c.text = comment.text;
      }
      commentsToRender.push(c);
    });
    setCommentsRender(commentsToRender);
  };
  const handleScrollToBottom = () => {
    setCommentsRender(comments);
    if (!endMessageDiv.current) return;
    endMessageDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };
  useEffect(handleScrollToBottom, [comments]);

  if (!!isLoading) return <SkeletonLoading mt="6" />;
  if (commentsToRender.length > 0)
    return (
      <Stack mt="14" w="full">
        <Box
          position="relative"
          border="1px"
          borderColor="gray.200"
          rounded="md"
        >
          {!atLastComment && (
            <IconButton
              aria-label="scrollToBottom"
              icon={<IoIosArrowDown />}
              position="absolute"
              bottom="5"
              right="5"
              rounded="full"
              size="lg"
              shadow="lg"
              colorScheme="blue"
              onClick={handleScrollToBottom}
            />
          )}
          <Container
            mt="10"
            as="section"
            maxH="70rem"
            maxW="full"
            overflowY="scroll"
          >
            <Box mx="auto">
              {commentsToRender.map((comment) => {
                const hasReplies = comment?.children?.length > 0;
                return (
                  <List key={comment._id} spacing="6">
                    {/* Main Comment */}
                    <Comment
                      showLine={hasReplies}
                      comment={comment}
                      removeDeletedComment={removedeletedComment}
                      updateModifiedComment={updateModifiedComment}
                    />

                    {/* Show Replies */}
                    {comment?.children?.map((childComment: any, i: number) => {
                      const isLast = comment.children.length === i + 1;

                      return (
                        <Comment
                          key={childComment._id}
                          comment={childComment}
                          showLine={!isLast}
                          isItReply={true}
                        />
                      );
                    })}
                    <VisibilitySensor
                      onChange={(value: boolean) => setAtLastComment(value)}
                    >
                      <Box h=".2rem" ref={endMessageDiv} />
                    </VisibilitySensor>
                  </List>
                );
              })}
            </Box>
          </Container>
        </Box>
      </Stack>
    );

  return (
    <Alert status="warning">
      <AlertIcon />
      <AlertDescription>
        No chat yet between staff and customer
      </AlertDescription>
    </Alert>
  );
};

interface CommentProps {
  comment: any;
  showLine?: boolean;
  hasReplies?: boolean;
  isItReply?: boolean;
  removeDeletedComment?: any;
  updateModifiedComment?: any;
}
function Comment({
  comment,
  showLine = false,
  isItReply = false,
  removeDeletedComment,
  updateModifiedComment,
}: CommentProps) {
  const { token } = useAuth();
  const { successToast, errorToast } = useCustomToast();
  const createdAt = formatDate(new Date(comment.createdAt));
  const chatPerson = getCommentSender(comment.createdBy);
  const deleteComment = async (id: string) => {
    try {
      const data = await deleteCommentFromDB(id, token);
      successToast({ title: SUCCESS.COMMENT_DELETED });
      removeDeletedComment(data.data.data);
    } catch (e) {
      errorToast({ title: ERRORS.GENERIC });
    }
  };

  const updateComment = async (id: string, commentText: string) => {
    try {
      const reqData = { text: commentText };
      const data = await updateCommentInDB(id, reqData, token);
      successToast({ title: SUCCESS.COMMENT_UPDATED });
      updateModifiedComment(data.data.data.comment);
      updateCommentState("", "");
    } catch (e) {
      errorToast({ title: ERRORS.GENERIC });
    }
  };
  const [editComment, setEditComment] = useState({
    id: "",
    text: "",
  });

  const updateCommentState = (id: string, text: string) => {
    setEditComment({ id: id, text: text });
  };

  return (
    <ListItem
      title={`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
      titleTag={chatPerson}
      subTitle={createdAt}
      isLastItem={!showLine}
      circleStyle={{
        bg: chatPerson === "customer" ? "gray.300" : "blue.500",
        color: chatPerson === "customer" ? "black" : "white",
      }}
      iconVsImg={chatPerson === "customer" ? "img" : "icon"}
      icon={<Icon as={isItReply ? FaReply : BsChatSquareText} boxSize="5" />}
      imgUrl={comment?.createdBy?.profile?.avatar}
    >
      <Stack
        spacing={6}
        p="4"
        bg="gray.100"
        rounded="md"
        w="full"
        position="relative"
      >
        <Stack>
          {editComment.id != "" && editComment.id === comment._id ? (
            <Box>
              <ReactQuill
                theme="snow"
                value={editComment.text}
                onChange={(value) => updateCommentState(comment._id, value)}
              />
              <HStack pt={2}>
                <Button
                  colorScheme="blue"
                  onClick={() => updateComment(comment._id, editComment.text)}
                >
                  Update
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => updateCommentState("", "")}
                >
                  Cancel
                </Button>
              </HStack>
            </Box>
          ) : (
            <Box>
              <Box>{parse(comment.text)}</Box>
              <HStack
                position="absolute"
                right="14px"
                bottom="10px"
                cursor="pointer"
              >
                <Box
                  onClick={() => updateCommentState(comment._id, comment.text)}
                  bg="gray.300"
                  p={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                >
                  <MdEdit />
                </Box>
                <Box
                  onClick={() => {
                    deleteComment(comment._id);
                  }}
                  bg="gray.300"
                  p={2}
                  color="red.500"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                >
                  <MdDelete />
                </Box>
              </HStack>
            </Box>
          )}
        </Stack>
        <Wrap>
          {comment.attachments.map((att: any) => {
            const file = {
              thumbnailUrl: att.thumbnailUrl || att.cdnUrl,
              secure_url: att.cdnUrl,
              format: att.format || "",
              name: att.name,
            };

            return (
              <FilePreview
                key={att.secure_url}
                size="md"
                fileURL={file.secure_url}
                fileType={file.format}
                fileName={file.name}
                allowClickToEnlarge
                allowDownload
              />
            );
          })}
        </Wrap>
      </Stack>
    </ListItem>
  );
}

export default CommentsSection;
