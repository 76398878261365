import {
  Button,
  Divider,
  HStack,
  Stack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import TaskDrawer from "components/section/Tasks/TaskDrawer";
import useAuth from "hooks/useAuthStore";
import useCustomToast from "hooks/useCustomToast";
import useFetchTask from "hooks/useFetchTask";
import useProjectStore from "hooks/useProjectStore";
import React, { FC } from "react";
import { BiPlus } from "react-icons/bi";
import { GoZap } from "react-icons/go";
import { IAssetWithTasks } from "types/task";
import RenderTaskRow from "./TaskRow";
import { TaskFields, TasksProps } from "./types";
import uniqueId from "uniqid";
import ButtonTooltip from "components/ui/common/Tooltips/ButtonTooltip";
import { cameraCaptureLocations, LocationProps } from "data/projectWizardData";
import _ from "lodash";
import { renderAssetTypeAsDB } from "schemas/assets_schema";
import { TaskFormProps } from "components/ui/tasks/SubtaskAction";

const Tasks: FC<TasksProps> = ({ tasks, staff, asset }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { project } = useProjectStore();
  const { addTaskMutationQuery, addBatchTaskMutationQuery } = useFetchTask();
  const { user } = useAuth();
  const { errorToast, warningToast, successToast } = useCustomToast();

  const handleAutoCreatedTask = (asset: IAssetWithTasks) => {
    if (asset.tasks.length >= asset.quantity) {
      warningToast({
        title: "Enough tasks are already been created.",
        description:
          "Tasks are equal or more than the quantity customer selected.",
      });
      return;
    }

    if (!project?._id) {
      errorToast({
        title: "Something wrong, may be project is not initialized.",
      });
      return;
    }

    const defaultAssignee = staff.find(
      (staffMember) => staffMember.email === "eric.leung@homerender.io"
    );

    if (!defaultAssignee?._id) {
      errorToast({
        title: "Problem with default assignee!",
        description: "You may need to update the default assignee email.",
      });
      return;
    }

    const autoCreatedTasks: TaskFields[] = [];
    for (let i = tasks.length; i < asset.quantity; i++) {
      autoCreatedTasks.push({
        _id: uniqueId(`${asset.title} ${i + 1}-`),
        name: `${asset.title} ${i + 1}`,
        description:
          _.find(cameraCaptureLocations as LocationProps[], {
            value: asset.cameraCaptureLocations[i],
          })?.label || "",
        projectId: project,
        assignee: defaultAssignee?._id || "",
        stage: "Information Gathering",
      });
    }

    if (!user?._id) {
      errorToast({
        title: "Something wrong while verifying user.",
      });
      return;
    }
    addBatchTaskMutationQuery.mutate(
      autoCreatedTasks.map((t) => ({
        assetType: renderAssetTypeAsDB[
          asset.title.replaceAll(" ", "")
        ] as string,
        outputId: asset._id,
        assignee: t.assignee,
        createdBy: user?._id || "",
        description: t.description,
        name: t.name,
        projectId: project._id,
        stage: t.stage,
      })),
      {
        onSuccess: (data) =>
          successToast({
            title: `Created ${data.length} tasks successfully for ${asset.title}`,
          }),
      }
    );
  };

  if (!project) return null;
  return (
    <>
      <VStack px="4" spacing="6" pb={4} w="full">
        <Stack spacing={2}>
          {tasks.map((task: TaskFields, index: number) => {
            return (
              <React.Fragment key={task._id}>
                <RenderTaskRow asset={asset} task={task} staff={staff} />
                {tasks.length !== index + 1 ? <Divider /> : null}
              </React.Fragment>
            );
          })}
        </Stack>

        <HStack mt="4" w="full" justify="start">
          <Button
            color="blue.500"
            type="submit"
            w="full"
            variant="outline"
            onClick={onOpen}
            leftIcon={<BiPlus size={20} />}
          >
            Add task
          </Button>
          <ButtonTooltip
            label={
              tasks.length >= asset.quantity
                ? "This feature only works if your already created tasks are less than the asset quantity customer selected."
                : ""
            }
          >
            <Button
              color="blue.500"
              type="submit"
              w="full"
              variant="outline"
              colorScheme="blue"
              isDisabled={tasks.length >= asset.quantity}
              isLoading={addBatchTaskMutationQuery.isLoading}
              onClick={() => handleAutoCreatedTask(asset)}
              leftIcon={<GoZap />}
            >
              Auto create tasks
            </Button>
          </ButtonTooltip>
        </HStack>
      </VStack>

      <TaskDrawer
        isOpen={isOpen}
        onClose={onClose}
        staff={staff}
        title={asset.title}
        defaultValues={{
          name: "",
          description: "",
          stage: "",
        }}
        submitLoading={addTaskMutationQuery.isLoading}
        onSubmit={(formData: TaskFormProps) => {
          if (!user) {
            errorToast({ title: "Something wrong with user authentication!" });
            return;
          }
          addTaskMutationQuery.mutate(
            {
              assetType: renderAssetTypeAsDB[
                asset.title.replaceAll(" ", "")
              ] as string,
              outputId: asset._id,
              assignee: formData.assignee,
              createdBy: user?._id || "",
              description: formData.description,
              name: formData.name,
              projectId: project._id,
              stage: formData.stage,
            },
            {
              onSuccess: () => onClose(),
            }
          );
        }}
      />
    </>
  );
};

export default Tasks;
