import { Button, HStack, Text, Wrap } from "@chakra-ui/react";
import { FC } from "react";
import { MdDelete } from "react-icons/md";

interface Props {
  onDelete: () => void;
  action: "update" | "create";
  deleteLoading?: boolean;
}
const Header: FC<Props> = ({ onDelete, action, deleteLoading }) => {
  return (
    <Wrap justify="space-between">
      <Text fontSize="22" fontWeight="semibold" textAlign="center">
        {action === "update" ? "Account settings" : "Create a new account"}
      </Text>
      <HStack>
        {action === "update" ? (
          <Button
            colorScheme="red"
            size="sm"
            rightIcon={<MdDelete />}
            onClick={onDelete}
            isLoading={deleteLoading}
          >
            Delete
          </Button>
        ) : null}
      </HStack>
    </Wrap>
  );
};

export default Header;
