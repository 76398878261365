import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./styles/global.css";
import { chakraCustomTheme } from "styles/chakra/chakraTheme";
import { ProjectProvider } from "context/project/ProjectProvider";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
            <ProjectProvider>
              {/* <PersistGate loading={null} persistor={persistor}> */}
              <ChakraProvider theme={chakraCustomTheme}>
                <Routes>
                  <Route path="*" element={<App />} />
                </Routes>
              </ChakraProvider>
               {/* </PersistGate> */}
            </ProjectProvider>
        </Provider>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
