/////////////////////
// Supporting Document Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Stack, Text } from "@chakra-ui/react";

// Layout and Section Imports
import { render_supporting_document__db_project } from "schemas/project_wizard_schema";
import WizardAttachmentPreview from "components/ui/upload/cloudinary/WizardAttachmentPreview";

// Data Imports

// Interfaces
interface MainProps {
  showDeleteButton?: boolean;
  supportingDocuments: any[];
}

const UploadedFiles: FC<MainProps> = ({ supportingDocuments }) => {
  return (
    <Stack mt="2">
      <Text fontSize="20" fontWeight="bold" align="center" py="2">
        Uploaded files
      </Text>
      {supportingDocuments.length > 0 ? (
        <Stack spacing="4">
          {supportingDocuments.map((attachment: any, idx: number) => {
            const file = render_supporting_document__db_project(attachment);
            return (
              <WizardAttachmentPreview
                key={idx.toString()}
                fileName={file.name}
                format={file.format}
                url={file.url}
                description={file.description}
                showDeleteButton={false}
              />
            );
          })}
        </Stack>
      ) : (
        <Text textAlign="center">No file uploaded...</Text>
      )}
    </Stack>
  );
};

export default UploadedFiles;
