import {
  Box,
  Circle,
  Flex,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { AiOutlineNumber } from "react-icons/ai";
import { TiArrowSortedUp } from "react-icons/ti";
import { MdSupervisedUserCircle, MdTextSnippet } from "react-icons/md";
import { BiTask } from "react-icons/bi";
import _ from "lodash";
import { IAssetWithTasks } from "types/task";
import { IStaff } from "types/staff";

//Show selected Output as a task
const AssetRow = (props: {
  nav: any;
  asset: IAssetWithTasks;
  staff: IStaff[];
}) => {
  const { nav, asset } = props;
  return (
    <Flex align="center" justify="space-between" height="3rem" w="full">
      <HStack spacing="6" w="full" justify="space-between">
        <HStack spacing="2" align="flex-start">
          <Tooltip label={"Asset"} placement="top" hasArrow>
            <Circle size="21px" bg={"#27AF5B"} color="white">
              <BiTask fontSize="14px" />
            </Circle>
          </Tooltip>
          <Stack spacing={0}>
            <Tooltip label={_.capitalize(asset.title)} hasArrow>
              <Text noOfLines={1} fontSize="md" fontWeight="semibold">
                {_.capitalize(asset.title)}
              </Text>
            </Tooltip>
          </Stack>
        </HStack>
        <HStack align="flex-start">
          <Tooltip label={"Initial data"} placement="top" hasArrow>
            <Circle size="21px" bg={"blue.500"} color="white">
              <MdTextSnippet fontSize="14px" />
            </Circle>
          </Tooltip>
          <Stack spacing="0">
            <Tooltip label={_.capitalize(asset.initialData)} hasArrow>
              <Text as="span" fontSize="md">
                {asset.initialData ? _.capitalize(asset.initialData) : "None"}
              </Text>
            </Tooltip>
          </Stack>
        </HStack>
        <HStack align="flex-start">
          <Tooltip label={"Quantity"} placement="top" hasArrow>
            <Box>
              <Icon as={AiOutlineNumber} boxSize="5" color={"#526BD8"} />
            </Box>
          </Tooltip>
          <Stack spacing={0}>
            <Tooltip label={asset.quantity} hasArrow>
              <Text fontSize="md" as="span">
                {asset.quantity ? asset.quantity : "None"}
              </Text>
            </Tooltip>
          </Stack>
        </HStack>
        <HStack spacing="2" align="flex-start">
          <Tooltip label="File type" placement="top" hasArrow>
            <Box>
              <Icon as={MdSupervisedUserCircle} boxSize="6" color={"#6A30DE"} />
            </Box>
          </Tooltip>
          <Stack spacing={0}>
            <Text fontSize="sm">
              <Tooltip
                label={
                  asset.outputFileType
                    ? asset.outputFileType
                        .map((type) => _.upperCase(type))
                        .toString()
                    : "None"
                }
                hasArrow
              >
                <Text as="span">
                  {asset.outputFileType
                    ? asset.outputFileType
                        .map((type) => _.upperCase(type))
                        .toString()
                    : "None"}
                </Text>
              </Tooltip>
            </Text>
          </Stack>
        </HStack>

        <IconButton
          initial={false}
          as={motion.div}
          cursor="pointer"
          variants={{
            open: {
              transform: "rotate(0deg)",
              transition: { ease: [0.75, 0, 0.25, 0.5] },
            },
            closed: { transform: "rotate(180deg)" },
          }}
          animate={nav.isOpen ? "open" : "closed"}
          fontSize="20px"
          size="md"
          variant="ghost"
          icon={<TiArrowSortedUp />}
          aria-label="Toggle menu"
          {...nav.getButtonProps()}
        />
      </HStack>
    </Flex>
  );
};

export default AssetRow;
