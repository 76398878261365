import { theme } from "@chakra-ui/pro-theme";
import { extendTheme } from "@chakra-ui/react";
import { customInputTheme } from "./customInputTheme";
import { customTextareaTheme } from "./customTextareaTheme";

export const chakraCustomTheme = extendTheme(
  {
    colors: { ...theme.colors, brand: theme.colors.blue },
    components: {
      Input: customInputTheme,
      Textarea: customTextareaTheme,
    },
  },
  theme
);
