import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC, useState, useEffect } from "react";

interface ConfirmationPopoverProps {
  onConfirm: () => void;
  confirmationText?: string;
  children: React.ReactElement<ButtonProps>;
}
const ConfirmationPopover: FC<ConfirmationPopoverProps> = ({
  children,
  onConfirm,
  confirmationText,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const CONFIRM_TEXT = confirmationText || "delete";
  const { isOpen, onClose, onToggle } = useDisclosure();

  useEffect(() => {
    return () => {
      setInputValue("");
      onClose();
    };
  }, []);

  const handleClose = () => {
    setInputValue("");
  };

  return (
    <Box as="span">
      <Popover isOpen={isOpen} onClose={handleClose}>
        <PopoverTrigger>
          {React.cloneElement(children as React.ReactElement<ButtonProps>, {
            onClick: onToggle,
          })}
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton onClick={onClose} />
            <PopoverHeader>Are you absolutely sure ?</PopoverHeader>
            <PopoverBody>
              <FormControl
                as="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  setInputValue("");
                  onClose();
                  onConfirm();
                }}
              >
                <FormLabel fontWeight="semibold">
                  Please type "{CONFIRM_TEXT}" word to confirm.
                </FormLabel>
                <Stack>
                  <Input
                    size="sm"
                    placeholder="Type here"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.currentTarget.value)}
                  />
                  <Button
                    isDisabled={inputValue !== CONFIRM_TEXT}
                    colorScheme="red"
                    w="full"
                    type="submit"
                  >
                    Confirm
                  </Button>
                </Stack>
              </FormControl>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};

export default ConfirmationPopover;
