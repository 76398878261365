import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "authUser",
  initialState: {
    _id: null,
    email: null,
    token: null,
    role: null,
    firstName: null,
    lastName: null,
    avatar: null,
  },
  reducers: {
    changeAuthUser: (state, action) => {
      state._id = action.payload._id;
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.avatar = action.payload.avatar;
    },
    changeEmail: (state, action) => {
      state.email = action.payload;
    },
    changeToken: (state, action) => {
      state.token = action.payload;
    },
    changeRole: (state, action) => {
      state.role = action.payload;
    },
    changeFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    changeLastName: (state, action) => {
      state.lastName = action.payload;
    },
    changeAvatar: (state, action) => {
      state.avatar = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeAuthUser,
  changeEmail,
  changeToken,
  changeRole,
  changeFirstName,
  changeLastName,
  changeAvatar,
} = userSlice.actions;

export default userSlice.reducer;
