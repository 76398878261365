import {
  As,
  Avatar,
  HStack,
  Icon,
  Text,
  Stack,
  Badge,
  Tag,
  Wrap,
} from "@chakra-ui/react";
import getStatusColorScheme from "utils/getStatusColorScheme";

interface NavButtonProps {
  avatar: string;
  icon: As;
  label: string;
  active: boolean;
  email?: string;
  onClick?: () => void;
  count?: number;
  company?: string;
  badge?: number | string;
  isPaused?: boolean;
  isGuest?: boolean;
}

const Item = (props: NavButtonProps) => {
  const {
    avatar,
    company,
    icon,
    label,
    active,
    email,
    onClick,
    isPaused,
    badge,
    isGuest,
  } = props;
  return (
    <HStack
      _hover={{ bg: active ? "#d0e7fc" : "#d0e7fc6b" }}
      bg={active ? "#d0e7fc" : "transparent"}
      p="2"
      rounded="md"
      alignItems="start"
      w="full"
      overflow="hidden"
      textOverflow="ellipsis"
      onClick={onClick}
      userSelect="none"
      cursor="pointer"
    >
      {avatar !== "" ? (
        <Avatar size="sm" name={label} src={avatar} />
      ) : (
        <Icon as={icon} boxSize="6" color={active ? "blue.500" : "#48494A"} />
      )}
      <Stack spacing={0} w="full" overflow="hidden">
        <HStack justify="space-between" w="full">
          <Text
            textOverflow="ellipsis"
            overflow="hidden"
            color={active ? "#3181CE" : "#3C4151"}
            fontWeight="medium"
            fontSize="sm"
            noOfLines={2}
          >
            {company || label}
          </Text>
          {!isPaused && !!badge ? (
            <Badge
              overflow="hidden"
              size="sm"
              variant="solid"
              colorScheme="blue"
            >
              {props.badge}
            </Badge>
          ) : null}
        </HStack>
        {!!email ? (
          <Text
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            color="GrayText"
            fontSize="xs"
          >
            {email}
          </Text>
        ) : null}
        <Wrap pt="1">
          {!!isGuest ? (
            <Badge
              w="max"
              size="sm"
              rounded="md"
              variant="solid"
              colorScheme="blue"
            >
              Guest
            </Badge>
          ) : null}
          {!!isPaused ? (
            <Tag size="sm" colorScheme={getStatusColorScheme("paused")}>
              Paused
            </Tag>
          ) : null}
        </Wrap>
      </Stack>
    </HStack>
  );
};

export default Item;
