// interface IRoutes {
//   [propName: string]: string;
// }

// export const ROUTES: IRoutes = {
export const ROUTES = {
  // Dashboard Routes
  DASHBOARD: "/",
  ACCOUNT: "/account",
  STAFF: "/staff",
  CLIENT: "/client",
  DELETED_PROJECTS: "/deleted",
  ZOMBIE_TASKS: "/zombieTask",
  // Auth
  LOGIN_IN: "/login",
};
