import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Stack,
  StackDivider,
  Text,
  useColorModeValue as colorMode,
} from "@chakra-ui/react";
import ProjectNav from "components/ui/misc/ProjectNav";
import UserNav from "components/ui/misc/UserNav";
import useFetchUsers from "hooks/useFetchUsers";
import useStaffStore from "hooks/useStaffStore";
import useUserStore from "hooks/useUserStore";
import { FC } from "react";
import { IStaff } from "types/staff";
import { IUser } from "types/user";

type UserRole = IUser["role"];
type StaffRole = IStaff["role"];
interface Props {
  role: UserRole | StaffRole;
}
const WorkProgress: FC<Props> = ({ role }) => {
  const { user } = useUserStore();
  const { staffMember } = useStaffStore();

  const STAFF_ROLES: StaffRole[] = ["staff", "admin"];
  const USER_ROLES: UserRole[] = ["customer", "guest"];

  const isStaff = STAFF_ROLES.includes(role as StaffRole);
  const isUser = USER_ROLES.includes(role as UserRole);

  if ((isUser && !user) || (isStaff && !staffMember)) return null;
  return (
    <AccordionItem border="none">
      <h2>
        <AccordionButton
          bg="gray.50"
          border="1px"
          borderColor="gray.300"
          rounded="md"
        >
          <Box flex="1" textAlign="left">
            <Text>Work Progress</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb="4" px="2" rounded="lg">
        <Box as="section" py={{ base: "2", md: "4" }}>
          <Box
            bg="bg-surface"
            boxShadow={colorMode("sm", "sm-dark")}
            borderRadius="lg"
            p={{ base: "4", md: "6" }}
          >
            <Stack spacing="5" divider={<StackDivider />}>
              {isStaff ? <RenderStaffWorkProgress /> : null}
              {isUser ? <RenderClientWorkProgress /> : null}
            </Stack>
          </Box>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

function RenderStaffWorkProgress() {
  const { clients } = useFetchUsers();
  const { staffMember } = useStaffStore();

  if (!staffMember) return null;
  return (
    <Stack spacing="5" divider={<StackDivider />}>
      <Stack spacing="1">
        <Text fontSize="lg" fontWeight="medium">
          Clients
        </Text>
        <Text fontSize="sm" color="muted">
          List of clients that staff is working for
        </Text>
      </Stack>

      {clients.filterByAssignee(staffMember?._id || "").length > 0 ? (
        clients
          .filterByAssignee(staffMember?._id || "")
          .map((client) => <UserNav key={client._id} user={client} />)
      ) : (
        <Alert status="warning">
          <AlertIcon />
          {staffMember.projects.length === 0
            ? "This client doesn't have projects"
            : "Seems like not staff member is working for this"}
        </Alert>
      )}
    </Stack>
  );
}

function RenderClientWorkProgress() {
  const { clients } = useFetchUsers();
  const { user } = useUserStore();

  if (!user) return null;
  return (
    <Stack spacing="5" divider={<StackDivider />}>
      <Stack spacing="1">
        <Text fontSize="lg" fontWeight="medium">
          Projects
        </Text>
        <Text fontSize="sm" color="muted">
          List of projects that staff is working on
        </Text>
      </Stack>

      {clients.getAllProjectsWithAssignee(user?._id).length > 0 ? (
        clients
          .getAllProjectsWithAssignee(user?._id)
          .map((project) => (
            <ProjectNav key={project._id} project={project} user={user} />
          ))
      ) : (
        <Alert status="warning">
          <AlertIcon />
          {user.projects.length === 0
            ? "This client doesn't have projects."
            : "Seems like not staff member is working for this client."}
        </Alert>
      )}
    </Stack>
  );
}

export default WorkProgress;
