import {
  Avatar,
  Box,
  Button,
  Container,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import Loader from "components/ui/common/Loader";
import useAuth from "hooks/useAuthStore";
import useFetchStaffs from "hooks/useFetchStaff";
import useProjectStore from "hooks/useProjectStore";
import useSettingStore from "hooks/useSettingStore";
import { useEffect, useState } from "react";
import { assignProjectToStaffService } from "services/projectServices";
import getFullName from "utils/getFullName";
import { CheckboxCard, CheckboxCardGroup } from "./Checkbox";

export default () => {
  const { staff, isLoading: loading } = useFetchStaffs();
  const { token } = useAuth();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const { changeProjectAssignee, project } = useProjectStore();
  const { changeShouldShowStaffAssignee } = useSettingStore();
  const [isLoading, setIsLoading] = useState(false);

  // For adding default assignee
  useEffect(() => {
    if (!project) return;

    const defaultStaffMember = staff.defaultStaffMember();
    if (!defaultStaffMember) {
      setSelectedItems(project.assignee);
      return;
    }

    const isDefaultAssignee = project.assignee.find(
      (assignee) => assignee === defaultStaffMember?._id
    );

    setSelectedItems(
      !isDefaultAssignee
        ? [defaultStaffMember?._id, ...project.assignee]
        : [...project.assignee]
    );
  }, [project]);

  const handlePressNextButton = async () => {
    if (!project) return;

    setIsLoading(true);
    await assignProjectToStaffService(project._id, selectedItems, {
      authToken: token,
    });
    setIsLoading(false);

    changeProjectAssignee(selectedItems);
    changeShouldShowStaffAssignee(false);
  };

  if (!!loading) return <Loader />;

  return (
    <Box as="section" bg="bg-surface" py={{ base: "2", md: "4" }}>
      <Container maxW="lg">
        <CheckboxCardGroup
          onChange={(values) => setSelectedItems(values)}
          spacing="3"
          value={selectedItems}
        >
          {staff.data.map((staffMember) => (
            <CheckboxCard key={staffMember._id} value={staffMember._id}>
              <HStack>
                <Avatar src={staffMember?.profile?.avatar} size="sm" />
                <Stack spacing={0}>
                  <Text color="emphasized" fontWeight="semibold" fontSize="sm">
                    {getFullName(staffMember.firstName, staffMember.lastName)}
                  </Text>
                  <Text color="muted" fontSize="sm">
                    {staffMember.email}
                  </Text>
                </Stack>
              </HStack>
            </CheckboxCard>
          ))}
        </CheckboxCardGroup>
        <Button
          colorScheme="blue"
          mt="4"
          w="full"
          isDisabled={selectedItems.length === 0}
          isLoading={isLoading}
          onClick={handlePressNextButton}
        >
          Next
        </Button>
      </Container>
    </Box>
  );
};
