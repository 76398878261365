/////////////////////
// Desired Assets Card
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { BoxProps } from "@chakra-ui/react";

// Layout and Section Imports
import DesireAssetsBoxes from "./DesireAssetsBoxes";
import Card from "components/layout/Card";
import CardBody from "components/layout/Card/CardBody";
import CardHeader from "components/layout/Card/CardHeader";

// Data Imports

// Context API

// Interfaces
interface Props {
  assets: any;
  styles: BoxProps;
  projectId?: string;
  readOnly?: boolean;
}

// Functions
const DesiredAssetsCard: FC<Props> = ({ assets, styles, readOnly }) => {
  return (
    <Card {...styles}>
      <CardHeader title="Assets Selected" />
      <CardBody>
        {/* Selected Assets Options */}
        <DesireAssetsBoxes assets={assets} readOnly={readOnly} />
      </CardBody>
    </Card>
  );
};

export default DesiredAssetsCard;
