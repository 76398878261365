import { Route, Routes } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import AppView from "./components/layout/AppView";
import Login from "./pages/Login";
import RequireAuthLayout from "./components/layout/RequireAuthLayout";
import { ROUTES } from "./constants/routes";
import CreateStaffPage from "./components/ui/pages/CreateStaffPage";
import ClientPage from "components/ui/pages/ClientPage";
import DeletedProjectsPage from "components/ui/pages/DeletedProjectsPage";
import ZombieTasksPage from "components/ui/pages/ZombieTasksPage";

function App() {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN_IN} element={<Login />} />
      <Route element={<RequireAuthLayout />}>
        <Route
          path={ROUTES.DASHBOARD}
          element={
            <AppLayout
            // title='Client Dashboard'
            />
          }
        >
          <Route path={ROUTES.DASHBOARD} element={<AppView />} />
          <Route path={ROUTES.STAFF} element={<CreateStaffPage />} />
          <Route path={ROUTES.CLIENT} element={<ClientPage />} />
          <Route
            path={ROUTES.DELETED_PROJECTS}
            element={<DeletedProjectsPage />}
          />
          <Route path={ROUTES.ZOMBIE_TASKS} element={<ZombieTasksPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
