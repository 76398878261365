/////////////////////
// Auth Service
// HC is just a crypto generated string which tells the react component that the payment is done or canceled
// to show up the modal when a user click cancel on stripe payment page or subscribed as stripe page is
// totally stateless so we have to query out the params
/////////////////////

// Basic Imports
import axios from "axios";
import { API_URL } from "../utils/site-credentials";
import _ from "lodash";
import { IStaffForm } from "types/forms";

// Design Imports

// Layout and Section Imports

// Data Imports

// Functions

// Custom Hooks and Services

// Interfaces
export type Task = {
  id: string;
  name: string;
  description: string;
  status: string;
  process: string;
  parentId: string;
  assignee: string;
  projectId: string;
};

export type Staff = {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  password: string;
};

// Functions
export const getClientAccountDetails = (authToken?: string) =>
  axios.get(`${API_URL}/users`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

//Getting task by project id
export const getTasksByProjectID = (projectId?: string, authToken?: string) =>
  axios.get(`${API_URL}/projects/${projectId}/tasks`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

//Getting staff members
export const getStaffMembers = (authToken?: string) =>
  axios.get(`${API_URL}/admin/staffs`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const getAssignee = (
  assigneeIds: string[] | string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  staff: any
): Partial<{ firstName: string; lastName: string; _id: string }> => {
  if (typeof assigneeIds === "string") {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return staff.find((staffMember: any) => staffMember._id === assigneeIds);
  }

  const filteredStaffMember = _.intersectionWith(
    staff,
    assigneeIds,
    (staffMember: Partial<{ _id: string }>, assigneeId: string) => {
      if (staffMember._id === assigneeId) return true;

      return false;
    }
  );
  return filteredStaffMember[0];
};

//Update Subtask in storage
export const handleUpdateSubTaskinStore = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allTask: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) => {
  //Logic to seprate subtask
  for (let i = 0; i < allTask.length; i++) {
    if (allTask[i].subTask) {
      for (let j = 0; j < allTask[i].subTask.length; j++) {
        if (allTask[i].subTask[j]._id === data._id) {
          allTask[i].subTask[j] = data;
          break;
        }
      }
      for (let j = 0; j < allTask[i].subTask.length; j++) {
        if (
          allTask[i]._id === data.parentId &&
          !(allTask[i].subTask[j]._id === data._id)
        ) {
          //Case of adition in subtask
          allTask[i].subTask.push(data);
          break;
        }
      }
    }
  }
  action(allTask);
};

export async function createTask(
  { name, description, status, projectId }: Task,
  token: string
) {
  try {
    const { data } = await axios.post(
      `${API_URL}/tasks`,
      {
        name,
        description,
        status,
        projectId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export async function deleteTaskFromDB(id: string, token: string) {
  try {
    const { data } = await axios.delete(`${API_URL}/tasks/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export async function createStaff(
  { firstName, lastName, email, password }: IStaffForm,
  token: string
) {
  try {
    const { data } = await axios.post(
      `${API_URL}/users`,
      {
        firstName,
        lastName,
        role: "staff",
        email,
        password,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}
