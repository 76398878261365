/////////////////////
// Output Card UI used on Output Step of New Project Wizard
/////////////////////

// Basic Imports
import { useState, useEffect } from "react";

// Design Imports
import { Button, FlexProps, HStack, IconButton, Stack } from "@chakra-ui/react";

// Layout and Section Imports
import { ToggleButton } from "components/ui/common/ToggleButton";
import { ToggleButtonGroup } from "components/ui/common/ToggleButtonGroup";
import RenderCameraCaptureLocation from "./CameraCaptureLocation";
import RenderQuantity from "./Quantity";
import RenderOutputFile from "./OutputFile";
import RenderQuality from "./Quality";
import RenderSourceFile from "./SourceFile";

// Data Imports
import { ActionDataProps, LocationProps } from "data/projectWizardData";
import _ from "lodash";
import { OUTPUT_MAX_QUANTITY } from "constants/projectWizard";
import Card from "components/layout/Card";
import CardBody from "components/layout/Card/CardBody";
import CardHeader from "components/layout/Card/CardHeader";
import useProjectStore from "hooks/useProjectStore";
import useAuth from "hooks/useAuthStore";
import { updateProjectService } from "services/projectServices";
import removeMongoDbIds from "utils/removeMongoDbIds";
import { FaEdit } from "react-icons/fa";
import useCustomToast from "hooks/useCustomToast";
import { CgClose } from "react-icons/cg";

// Interfaces
interface OutputCardProps extends FlexProps {
  id: string;
  title: string;
  data: ActionDataProps;
  selectedData?: any;
  readOnly?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChangeState?: (value: boolean) => void;
}

export interface Item {
  label: string;
  value: string;
}

// Functions
export const OutputCardForSteps = ({
  title,
  data,
  selectedData,
}: OutputCardProps) => {
  const [cameraCaptureQuantity, setCameraCaptureQuantity] = useState(
    selectedData?.quantity || "1"
  ); // This quantity will decide how many camera captured locations has to be selected
  const [selected, setSelected] = useState(selectedData);
  const [isEditState, setIsEditState] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const { project, changeProject } = useProjectStore();
  const { token } = useAuth();
  const { errorToast } = useCustomToast();
  const [isOutputUpdating, setIsOutputUpdating] = useState(false);

  // If readOnly then it will work
  useEffect(() => {
    // if (!readOnly) return;
    setSelected(selectedData);
    setCameraCaptureQuantity(selectedData.quantity);
  }, [selectedData]);

  useEffect(() => {
    setSelected({ ...selected, quantity: cameraCaptureQuantity });
  }, [cameraCaptureQuantity]);

  useEffect(() => {
    const isEqual = _.isEqual(selected, selectedData);
    setIsChanged(!isEqual);
  }, [selected, selectedData]);

  const handleEdit = async () => {
    if (!project) return;

    const filteredOutputIndex = project?.selectedOutputs.findIndex(
      (output) => output.title === selected.title
    );

    if (filteredOutputIndex === -1) {
      errorToast({ title: "Your selectedOutput ID got changed again" });
      return;
    }

    const clonedSelectedOutputs = project ? [...project.selectedOutputs] : [];
    clonedSelectedOutputs[filteredOutputIndex] = selected;

    setIsOutputUpdating(true);
    const { data, error } = await updateProjectService(
      project._id,
      { selectedOutputs: removeMongoDbIds(clonedSelectedOutputs) },
      token,
      true
    );
    setIsOutputUpdating(false);

    if (!!error) {
      errorToast({
        title: "Something went wrong while updating selected output",
      });
      return;
    }

    changeProject(data.data.project);
  };

  const readOnly = !isEditState;
  return (
    <Card>
      <CardHeader
        headingStyle={{ fontSize: "18" }}
        title={title}
        SaveButton={
          isEditState ? (
            <HStack>
              <IconButton
                size="sm"
                aria-label="cancel"
                icon={<CgClose size={20} />}
                onClick={() => {
                  setIsEditState(false);
                  setSelected(selectedData);
                }}
              />
              {!!isChanged && (
                <Button
                  size="sm"
                  colorScheme="blue"
                  isDisabled={!isChanged}
                  onClick={handleEdit}
                  isLoading={isOutputUpdating}
                >
                  Save
                </Button>
              )}
            </HStack>
          ) : (
            <Button
              size="sm"
              leftIcon={<FaEdit />}
              onClick={() => setIsEditState(true)}
            >
              Edit
            </Button>
          )
        }
      />
      <CardBody>
        <Stack spacing={4}>
          {/* ONlY FOR 2D FLOOR PLAN */}
          {selected?.initialData && (
            <ToggleButtonGroup
              readOnly={readOnly}
              value={selected.initialData}
              isAttached
              variant="outline"
              aria-label="Source File type"
              onChange={(value) => {
                if (!!readOnly) return;
                setSelected({ ...selected, initialData: value });
              }}
            >
              {data.initialData &&
                data.initialData.map((option: any, idx: number) => (
                  <ToggleButton
                    key={option}
                    value={option}
                    id={idx.toString()}
                  />
                ))}
            </ToggleButtonGroup>
          )}

          <RenderOutputFile
            readOnly={readOnly}
            title="Output Filetype"
            data={data.outputFileType}
            defaultValues={selected?.outputFileType}
            onSelect={(value: string[]) => {
              if (!!readOnly) return;
              setSelected({ ...selected, outputFileType: value });
            }}
          />
          <RenderQuality
            readOnly={readOnly}
            selected={selected?.imageSize}
            title="Image Size"
            defaultValue={selected?.imageSize}
            options={data.imageSize}
            onSelect={(value: string) => {
              if (!!readOnly) return;
              setSelected({ ...selected, imageSize: value });
            }}
          />
          <RenderSourceFile
            readOnly={readOnly}
            title="Source File"
            defaultValue={selected?.sourceFile}
            selected={selected?.sourceFile}
            options={data.sourceFile}
            onSelect={(value: string) => {
              if (!!readOnly) return;
              setSelected({ ...selected, sourceFile: value });
            }}
          />
          <RenderQuantity
            min={1}
            readOnly={readOnly}
            max={OUTPUT_MAX_QUANTITY} // Depends on camera capture options
            value={cameraCaptureQuantity}
            defaultValue={selected.quantity}
            onChange={(value: string) => {
              if (!!readOnly) return;
              setCameraCaptureQuantity(value);
            }}
          />
          <RenderCameraCaptureLocation
            readOnly={readOnly}
            title="Camera capture locations"
            selectedLocations={selected.cameraCaptureLocations.map(
              (l: string) => ({
                label: _.capitalize(l),
                value: l,
              })
            )}
            locations={data.cameraCaptureLocations}
            noOfMaxSelections={cameraCaptureQuantity}
            onSelect={(locations: LocationProps[]) => {
              if (!!readOnly) return;
              const formatLocationSchema = locations.map((l) => l.value);
              setSelected({
                ...selected,
                cameraCaptureLocations: formatLocationSchema,
              });
            }}
          />
        </Stack>
      </CardBody>
    </Card>
  );
};
