/////////////////////
// Supporting Document Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC, useEffect, useState } from "react";

// Design Imports
import UploadedFiles from "./UploadedFiles";
import Card from "components/layout/Card";
import CardHeader from "components/layout/Card/CardHeader";
import CardBody from "components/layout/Card/CardBody";
import useAuth from "hooks/useAuthStore";
import { fetchAttachmentsByProjectId } from "services/attachmentsService";
import { Text } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props {
  projectId: string;
  showHeader?: boolean;
}

// Functions
const SupportingDocumentsStep: FC<Props> = ({ projectId, showHeader }) => {
  const [allAttachments, setAllAttachments] = useState<any[]>([]);
  const { token } = useAuth();

  useEffect(() => {
    getAsyncData();
    async function getAsyncData() {
      const { data, error } = await fetchAttachmentsByProjectId(
        projectId || "",
        token
      );

      if (!!error) return;
      setAllAttachments(data.data.attachments);
    }
  }, []);

  return (
    <Card>
      {!!showHeader && (
        <CardHeader
          title="Supporting Documents"
          description="Form for Uploading your Supporting Documents. (i.e. Specifications,
          architect drawings, concept images, etc."
        />
      )}
      <CardBody>
        {allAttachments.length > 0 ? (
          <UploadedFiles
            supportingDocuments={allAttachments}
            showDeleteButton={false}
          />
        ) : (
          <Text textAlign="center">No attachment uploaded...</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default SupportingDocumentsStep;
