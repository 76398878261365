import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import TurnTileWidget from "components/TurnsTile/TurnTile";
import { useState } from "react";
import useAuthForm from "../../hooks/useLoginForm";

const LoginForm = () => {
  const { user, setEmail, setPassword, handleSignin } = useAuthForm();
  const [verified, setVerified] = useState(false);
  return (
    <Container
      minW="sm"
      minH="md"
      py={{ base: "0", sm: "8" }}
      px={{ base: "4", sm: "10" }}
      bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
      boxShadow={{ base: "none", sm: "0px 8px 20px rgb(0 0 0 / 3%)" }}
      borderRadius={{ base: "none", sm: "xl" }}
      border="1px solid"
      borderColor="gray.200"
    >
      <Stack spacing="8" as="form" onSubmit={handleSignin}>
        <Stack spacing="6" align="center">
          <Stack spacing="3" textAlign="center">
            <Heading size="xs" fontWeight={"bold"}>
              Admin Login
            </Heading>
            <Text as="p">
              {" "}
              Hey, Enter your details to get sign in <br></br> to your account
            </Text>
          </Stack>
        </Stack>
        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                placeholder="Enter Email"
                id="email"
                type="email"
                value={user.email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                placeholder="Password"
                id="password"
                type="password"
                value={user.password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </FormControl>
          </Stack>
          <TurnTileWidget setVerified={setVerified} />
          <Stack spacing="6">
            <Button
              variant="primary"
              type="submit"
              isLoading={user.isLoading}
              isDisabled={!verified}
            >
              Sign in
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default LoginForm;
