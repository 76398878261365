/////////////////////
// Style Card UI used on Style Step of New Project Wizard
/////////////////////

// Basic Imports
import { FC, useEffect, useState } from "react";

// Design Imports
import { Badge, Box, Stack, Text, Textarea } from "@chakra-ui/react";

// Layout and section imports
import SubStyleCard from "components/ui/common/StyleCardForSteps/SubStyleCardForSteps";
import { useProject } from "context/project/ProjectProvider";

// Data Imports
import { StyleCardProps } from "data/projectWizardData/styleCardData";
import { filter_selected_from_selectedStyles } from "utils/projectWizardUtils";

// Interfaces
interface Props {
  id: string;
  title: string;
  options: StyleCardProps[];
  selectedOptions?: any;
  onChange?: any;
  onSelected?: any;
  showSelectedOnly?: boolean;
  isReadOnly?: boolean;
}

const StyleCardsForSteps: FC<Props> = ({
  id,
  title,
  options,
  onChange,
  showSelectedOnly,
  isReadOnly,
}) => {
  const { selectedStyles, onAddSelectedStyles } = useProject();
  const [additionalInformation, setAdditionalInformation] = useState("");

  useEffect(() => {
    const targetStyle = selectedStyles.find((style: any) => style.title === title);

    if (targetStyle?.additionalStyleInformation) {
      setAdditionalInformation(targetStyle?.additionalStyleInformation);
    }


  }, [selectedStyles]);

  const handleAddAdditionalInformation = () => {
    const updatedSelectedStyles = [...selectedStyles];

    // Target style/asset
    const targetStyle = selectedStyles.find((style: any) => style.title === title);
    const targetStyleIndex = selectedStyles.findIndex(
      (style: any) => style.title === title
    );

    updatedSelectedStyles[targetStyleIndex] = {
      ...targetStyle,
      additionalStyleInformation: additionalInformation,
    };
    onAddSelectedStyles(updatedSelectedStyles);
  };

  if (title === "Other")
    return (
      <Textarea
        readOnly={isReadOnly}
        onBlur={handleAddAdditionalInformation}
        value={additionalInformation}
        my="4"
        placeholder="Please let us know what you would like us to attempt and we will get back to you. "
        onChange={(e) => {
          const value = e.currentTarget.value;
          setAdditionalInformation(value);
        }}
      />
    );

  return (
    <>
      <Stack spacing={2} mt="2">
        <Box as="section" py="12">
          {options.map((option: StyleCardProps, idx: number) => {
            if (option.items.length === 0) return null;
            return (
              <SubStyleCard
                key={idx.toString()}
                isReadOnly={isReadOnly}
                option={option}
                cardType={option.input}
                styleMetaData={options}
                showSelectedOnly={showSelectedOnly}
                selectedItem={
                  selectedStyles.length > 0
                    ? filter_selected_from_selectedStyles({
                        selectedStyles,
                        assetKey: title,
                        currOption: option,
                      })
                    : []
                }
                onChange={(value: any) => {
                  onChange({ asset: { id, title }, ...value });
                }}
              />
            );
          })}
        </Box>

        <Box
          maxW={{ base: "xl", md: "7xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Box textAlign="center" mb="10">
            <Badge mt="8" px="3" py="1" variant="solid" colorScheme="blue">
              Additional style information
            </Badge>
            <Text maxW="2xl" mx="auto" my="3">
              Please inform us of any other specifics regarding the design and
              creation of your asset.
            </Text>

            <Textarea
              readOnly={isReadOnly}
              onBlur={handleAddAdditionalInformation}
              value={additionalInformation}
              mt="6"
              placeholder="Please inform us of any other specifics regarding the design and creation of your asset."
              onChange={(e) => {
                const value = e.currentTarget.value;
                setAdditionalInformation(value);
              }}
            />
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default StyleCardsForSteps;
