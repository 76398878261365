import { Box, BoxProps, Button, Flex, Stack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import getFullName from "utils/getFullName";
import FormInputField from "components/forms/ReactHookForm/FormInputField";
import FormPopoverSelectField from "components/forms/ReactHookForm/FormPopoverSelectField";
import useTaskStages from "hooks/useTaskStages";
import { ITask } from "types/task";
import { ISelectedOutputs } from "types/project";
import { defaultValuesProps } from "components/section/Tasks/TaskDrawer";
import { IStaff } from "types/staff";

export type TaskFormProps = Pick<
  ITask,
  "name" | "description" | "stage" | "assignee"
> & {
  outputId: ITask["outputId"];
};

interface Props {
  styleProps?: BoxProps;
  defaultValues?: Partial<defaultValuesProps>;
  staff: IStaff[];
  // eslint-disable-next-line no-unused-vars
  submit: (formData: TaskFormProps) => void;
  action: "update" | "create";
  updateLoading?: boolean;
  addLoading?: boolean;
  onDelete?: () => void;
  deleteLoading?: boolean;
  submitLoading?: boolean;
  assetTitle: string;
  allAssets?: ISelectedOutputs[];
}
interface YupSchemaProps {
  isAssetAvailable: boolean;
}

// Validation Schema
const schema = ({ isAssetAvailable }: YupSchemaProps) =>
  yup.object().shape({
    name: yup.string().required().label("Name"),
    description: yup.string().required().label("Description"),
    assignee: yup.string().required().label("Assignee"),
    stage: yup.string().required().label("Stage"),
    ...(isAssetAvailable
      ? { outputId: yup.string().required().label("Asset") }
      : {}), // Dynamic: only show this isAssetAvailable is true
  });

const SubtaskAction = ({
  styleProps,
  defaultValues,
  staff,
  submit,
  action = "create",
  submitLoading,
  onDelete,
  deleteLoading,
  assetTitle,
  allAssets,
}: Props) => {
  const stages = useTaskStages();
  // const methods = useForm<ITask>({
  const methods = useForm<TaskFormProps>({
    defaultValues: { ...defaultValues, assignee: defaultValues?.assigneeId },
    resolver: yupResolver(
      schema({
        isAssetAvailable: allAssets && allAssets?.length > 0 ? true : false,
      })
    ),
  });
  const { handleSubmit } = methods;

  function renderStageList(assetTitle: string) {
    if (assetTitle.includes("2D")) return stages.floorPlan2D;
    if (assetTitle.includes("3D")) return stages.floorPlan3D;
    return stages.others;
  }
  return (
    <FormProvider {...methods}>
      <Box
        as="form"
        bg="bg-surface"
        borderRadius="lg"
        {...styleProps}
        w="full"
        onSubmit={handleSubmit(submit)}
      >
        <Stack
          spacing="5"
          px={{ base: "4", md: "4" }}
          py={{ base: "5", md: "6" }}
        >
          <FormInputField
            name="name"
            label="Title"
            variant="input"
            placeholder="Name for subtask"
          />
          <FormInputField
            name="description"
            label="Description"
            variant="textarea"
            placeholder="Description for the task"
          />
          <FormPopoverSelectField
            label="Assign to"
            name="assignee"
            list={staff.map((staffMember: IStaff) => ({
              id: staffMember._id,
              label: getFullName(
                staffMember?.firstName || "",
                staffMember?.lastName || ""
              ),
              description: staffMember.email,
              avatar: staffMember.profile.avatar,
            }))}
          />
          {allAssets && allAssets.length > 0 ? (
            <FormInputField
              name="outputId"
              label="Asset"
              variant="select"
              placeholder="Select asset"
              list={allAssets.map((asset) => ({
                label: asset.title,
                value: asset._id,
              }))}
            />
          ) : null}

          <FormInputField
            name="stage"
            label="Stage"
            variant="select"
            placeholder="Select stage"
            list={renderStageList(assetTitle).map((stage) => ({
              label: stage.label,
              value: stage.label,
            }))}
          />
        </Stack>
        <Flex
          direction="row-reverse"
          py="4"
          justify="space-between"
          px={{ base: "4", md: "6" }}
        >
          <Button type="submit" variant="primary" isLoading={submitLoading}>
            {action == "update" ? "Update" : "Save"}
          </Button>
          {action == "update" && (
            <Button
              colorScheme="red"
              isLoading={deleteLoading}
              onClick={() => {
                if (onDelete) {
                  onDelete();
                }
              }}
            >
              Delete
            </Button>
          )}
        </Flex>
      </Box>
    </FormProvider>
  );
};

export default SubtaskAction;
