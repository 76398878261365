import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createStaffMember,
  deleteStaffMember,
  fetchAllStaffService,
  updateStaffMember as updateStaffMemberService,
} from "services/staffService";
import { STAFF_KEY } from "settings/keys/react-query";
import useAuth from "./useAuthStore";
import useCustomToast from "./useCustomToast";
import useProjectStore from "./useProjectStore";
import { IStaffForm } from "types/forms";
import { IStaff } from "types/staff";
import useStaffStore from "./useStaffStore";
import staffFuzzySearch from "utils/fuzzy-search/staffFuzzySearch";

const useFetchStaffs = () => {
  const { token } = useAuth();
  const { staffMember: staffMemberStore, updateStaffMember } = useStaffStore();
  const { successToast, errorToast } = useCustomToast();
  const queryClient = useQueryClient();
  const { project, changeProject } = useProjectStore();
  const { data, isLoading, isSuccess, ...restQueryResponse } = useQuery<
    IStaff[]
  >(
    [STAFF_KEY], // TODO: May be we need to add "token" as key
    async () => {
      const { staff, error } = await fetchAllStaffService(token || "");
      if (!!error) {
        throw new Error("");
      }
      return staff || ([] as IStaff[]);
    },
    {
      enabled: !!token,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      onSuccess: (updatedStaff) => {
        const _staffMember = updatedStaff.find(
          (updatedStaffMember) =>
            updatedStaffMember._id === staffMemberStore?._id
        );
        if (!_staffMember) return;

        const updatedProject = staffMemberStore?.projects.find(
          (pr) => pr._id === project?._id
        );
        changeProject(updatedProject || null);
        updateStaffMember(_staffMember);
      },
    }
  );

  // Handlers
  const updateStaffMutationQuery = useMutation({
    mutationFn: async (formData: IStaffForm) => {
      const { error } = await updateStaffMemberService(
        formData.id,
        { ...formData },
        token
      );

      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }
    },
    onSuccess: () => {
      successToast({ title: "Staff member updated." });
      queryClient.invalidateQueries([STAFF_KEY]);
    },
  });

  const createStaffMutationQuery = useMutation({
    mutationFn: async (formData: Omit<IStaffForm, "id">) => {
      const { error } = await createStaffMember(formData, token);

      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }
    },
    onSuccess: () => {
      successToast({ title: "Staff member created." });
      queryClient.invalidateQueries([STAFF_KEY]);
    },
  });

  const deleteStaffMutationQuery = useMutation({
    mutationFn: async (staffMemberId: string) => {
      const { error } = await deleteStaffMember(staffMemberId, token || "");

      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }
    },
    onSuccess: async () => {
      successToast({ title: "Staff member deleted." });
      queryClient.invalidateQueries([STAFF_KEY]);
    },
  });

  const staff = data || [];
  return {
    staff: {
      data: staff,
      defaultStaffMember: () =>
        staff.find(
          (staffMember) => staffMember.email === "eric.leung@homerender.io"
        ),
      search: (query: string) => staffFuzzySearch(staff, query),
      filterByIds: (ids: string[]) =>
        _.intersectionWith(
          staff,
          ids,
          (member: IStaff, id: string) => member._id === id
        ),
    },
    updateStaffMutationQuery,
    createStaffMutationQuery,
    deleteStaffMutationQuery,
    isLoading,
    isSuccess,
    ...restQueryResponse,
  };
};

export default useFetchStaffs;
