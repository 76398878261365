import { Box, Flex, Img, Heading } from "@chakra-ui/react";
import useClientStore from "hooks/useClientStore";
import useProjectStore from "hooks/useProjectStore";
import * as React from "react";
import noSlection from "../../../assets/img/noproject.jpg";

const Status = () => {
  const { client } = useClientStore();
  const { project } = useProjectStore();
  const [message, setMessage] = React.useState(
    "Select client and project to start"
  );
  React.useEffect(() => {
    if (!client && !project) {
      setMessage("Select client and project to start");
    } else if (!client && project) {
      setMessage("Select client to start");
    } else {
      setMessage("Select project to start");
    }
  }, [client, project]);
  return (
    <Flex flex="1" justify="center" align="center" direction="column">
      <Box>
        <Img src={noSlection} alt="None" height="220px" />
      </Box>
      <Heading as="h4" size="sm">
        {message}
      </Heading>
    </Flex>
  );
};

export default Status;
