import { Alert, AlertDescription, AlertIcon, VStack } from "@chakra-ui/react";
import StaffCheckboxes from "components/section/CheckboxCardGroup/StaffCheckboxes";
import AssetRowWithTasks from "components/ui/Row/AssetRowWithTasks";
import useFetchStaffs from "hooks/useFetchStaff";
import useProjectStore from "hooks/useProjectStore";
import useSettingStore from "hooks/useSettingStore";
import _ from "lodash";
import React, { FC } from "react";
import { IAssetWithTasks, ITask } from "types/task";
interface Props {
  tasks: ITask[];
}
const AssetsWithTasks: FC<Props> = ({ tasks }) => {
  const { project } = useProjectStore();
  const selectedOutputs = project ? project["selectedOutputs"] : [];
  const [assetTasks, setAssetTasks] = React.useState<IAssetWithTasks[]>([]);
  const { tabIndex } = useSettingStore();
  const { staff } = useFetchStaffs();
  const { shouldShowStaffAssignee } = useSettingStore();

  React.useEffect(() => {
    if (selectedOutputs.length === 0 && tasks.length === 0) {
      return;
    } // INFO: To avoid infinite rerendering
    const outputsWithTasks = _.map(selectedOutputs, (output) => {
      const filteredTasks = tasks.filter(
        (task) => task.outputId === output._id
      );
      return { ...output, tasks: filteredTasks };
    });
    setAssetTasks(outputsWithTasks);
  }, [tasks, selectedOutputs]);

  if (assetTasks.length === 0 && tabIndex === 1 && !!project)
    return (
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription>
          This project doesn't have any selected output.
        </AlertDescription>
      </Alert>
    );

  if (!project) return null;
  if (!!shouldShowStaffAssignee || project.assignee.length === 0)
    return <StaffCheckboxes />;
  return (
    <VStack spacing="4" w="full">
      {assetTasks.map((asset: IAssetWithTasks) => {
        return (
          <AssetRowWithTasks
            key={asset.title}
            asset={asset}
            staff={staff.filterByIds(project.assignee)}
          />
        );
      })}
    </VStack>
  );
};

export default AssetsWithTasks;
