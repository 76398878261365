/////////////////////
// Require Auth Layout
// Anything with this layout require login.
/////////////////////

// Basic Imports
//import useAuth from "hooks/useAuth";

import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { RootState, store } from "../../redux/store";
import { ERRORS } from "../../constants/toastMessages";
import { RESET_ACTION } from "../../redux/actions";
import useCustomToast from "../../hooks/useCustomToast";

// Design Imports

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
const RequireAuthLayout = () => {
  const { errorToast } = useCustomToast();
  const user = useSelector((state: RootState) => state.authUser);
  const location = useLocation();
  const parseJwt = (token: any) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  //If token is expired log out the user
  if (user?.token) {
    const decodedJwt = parseJwt(user?.token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      errorToast({ title: ERRORS.INVALID_TOKEN });
      store.dispatch(RESET_ACTION);
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }
  // TEMP: Uncomment it when backend is up
  if (!user?.token)
    return <Navigate to="/login" state={{ from: location }} replace />;
  return <Outlet />;
};

export default RequireAuthLayout;
