import FuzzySearch from "fuzzy-search";
import { IStaff } from "types/staff";
import { removeWhiteSpace } from "utils/string";

export default function staffFuzzySearch(
  users: IStaff[],
  searchQuery: string
): IStaff[] {
  const usersWithFullName = users.map((user) => ({
    ...user,
    fullName: `${user.firstName} ${user.lastName}`,
  }));

  return new FuzzySearch(usersWithFullName, [
    "firstName",
    "lastName",
    "fullName",
    "email",
    "profile.company",
  ]).search(removeWhiteSpace(searchQuery));
}
