import {
  Box,
  Stack,
  StackDivider,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC } from "react";

interface Props {
  appUpdates: {
    value: boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (value: boolean) => void;
  };
  newsLetters: {
    value: boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (value: boolean) => void;
  };
  promotional: {
    value: boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (value: boolean) => void;
  };
}
const Communications: FC<Props> = ({
  appUpdates,
  newsLetters,
  promotional,
}) => {
  return (
    <Box as="section" py={{ base: "2", md: "4" }}>
      <Box
        bg="bg-surface"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        borderRadius="lg"
        p={{ base: "4", md: "6" }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              Notifications
            </Text>
            <Text fontSize="sm" color="muted">
              Receive notifications from HomeRender
            </Text>
          </Stack>
          <NotificationOption
            type="Application Notifications"
            description="Receive email for application/project updates"
            isActive={appUpdates.value}
            onChange={appUpdates.onChange}
          />
          <NotificationOption
            type="Email Newsletter"
            description="Receive email for promotions."
            isActive={newsLetters.value}
            onChange={newsLetters.onChange}
          />
          <NotificationOption
            type="Promotional Emails"
            description="Receive email for promotions."
            isActive={promotional.value}
            onChange={promotional.onChange}
          />
        </Stack>
      </Box>
    </Box>
  );
};

function NotificationOption({
  type,
  description,
  isActive,
  isDisabled = false,
  onChange,
}: any) {
  return (
    <Stack justify="space-between" direction="row" spacing="4">
      <Stack spacing="0.5" fontSize="sm">
        <Text color="emphasized" fontWeight="medium">
          {type}
        </Text>
        <Text color="muted">{description}</Text>
      </Stack>
      <Switch
        onChange={(e) => onChange(e.target.checked)}
        isChecked={isActive}
        isDisabled={isDisabled}
      />
    </Stack>
  );
}

export default Communications;
