import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import useFetchStaffs from "hooks/useFetchStaff";
import useProjectStore from "hooks/useProjectStore";
import { FC } from "react";
import { ITask } from "types/task";
import RenderTaskRow from "./TaskRow";

interface Props {
  tasks: ITask[];
}
const ZombieTasks: FC<Props> = ({ tasks }) => {
  const { staff } = useFetchStaffs();
  const { project } = useProjectStore();
  const selectedOutputs = project ? project["selectedOutputs"] : [];

  if (tasks.length === 0)
    return (
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription>There is no zombie task</AlertDescription>
      </Alert>
    );
  return (
    <>
      {tasks.map((task) => (
        <RenderTaskRow
          key={task._id}
          allAssets={
            selectedOutputs.length > 0
              ? selectedOutputs
              : task.projectId?.selectedOutputs
          }
          staff={staff.data}
          task={{
            _id: task._id,
            assignee: task.assignee,
            description: task.description || "",
            name: task.name,
            projectId: task.projectId,
            stage: task.stage,
            status: task.status,
          }}
        />
      ))}
    </>
  );
};

export default ZombieTasks;
