import {
  AvatarGroup,
  Box,
  Circle,
  Flex,
  HStack,
  Stack,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { FC } from "react";
import { BiTask } from "react-icons/bi";
import { IUser } from "types/user";
import { IProject } from "types/project";
import getStatusColorScheme from "utils/getStatusColorScheme";
import useFetchUsers from "hooks/useFetchUsers";
import TooltipAvatar from "../common/TooltipAvatar";
import getFullName from "utils/getFullName";
import { useDispatch } from "react-redux";
import { changeProject } from "redux/reducers/projectReducer";
import { changeAddTask } from "redux/reducers/settingsReducer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import useSettingStore from "hooks/useSettingStore";
import useClientStore from "hooks/useClientStore";

interface Props {
  project: IProject;
  user: IUser;
}
const ProjectNav: FC<Props> = ({ project, user }) => {
  const { staff } = useFetchUsers();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateClient: updateClientStore } = useClientStore();
  const { changeTabIndex } = useSettingStore();

  const redirectToDahsboard = async ({ project, user }: Props) => {
    navigate(ROUTES.DASHBOARD, { replace: true });
    updateClientStore(user);
    changeTabIndex(1);
    dispatch(changeProject(project));
    dispatch(changeAddTask(false));
  };

  return (
    <Box px="4" overflow="hidden" rounded="md">
      <Flex align="center" justify="space-between" height="3rem" w="full">
        <HStack spacing="6" w="full" justify="space-between">
          <HStack flex="1" spacing="2" align="flex-start">
            <Tooltip label={"Asset"} placement="top" hasArrow>
              <Circle size="21px" bg={"#27AF5B"} color="white">
                <BiTask fontSize="14px" />
              </Circle>
            </Tooltip>
            <Stack spacing={0}>
              <HStack
                cursor="pointer"
                onClick={() => {
                  redirectToDahsboard({ project, user });
                }}
              >
                <Text noOfLines={1} fontSize="md" fontWeight="semibold">
                  {project.name}
                </Text>
                <Tag colorScheme={getStatusColorScheme(project.status)}>
                  {_.capitalize(project.status)}
                </Tag>
              </HStack>
            </Stack>
          </HStack>

          <AvatarGroup size="sm" fontSize="10">
            {staff.filterByIds(project.assignee).map((staffMember: IUser) => (
              <TooltipAvatar
                key={staffMember._id}
                name={getFullName(staffMember.firstName, staffMember.lastName)}
                src={staffMember.profile.avatar}
              />
            ))}
          </AvatarGroup>
        </HStack>
      </Flex>
    </Box>
  );
};

export default ProjectNav;
