import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from "@chakra-ui/react";
import SubtaskAction, {
  TaskFormProps,
} from "components/ui/tasks/SubtaskAction";
import { FC } from "react";
import { ISelectedOutputs } from "types/project";
import { IStaff } from "types/staff";
import { ITask } from "types/task";

export type defaultValuesProps = Pick<
  ITask,
  "name" | "description" | "status" | "stage"
> & { assigneeId: ITask["assignee"] };
interface Props extends DrawerProps {
  defaultValues: Partial<defaultValuesProps>;
  title: string;
  staff: IStaff[];
  // eslint-disable-next-line no-unused-vars
  onSubmit: (formData: TaskFormProps) => void;
  submitLoading?: boolean;
  onDelete?: () => void;
  deleteLoading?: boolean;
  action?: "update" | "create";
  allAssets?: ISelectedOutputs[];
}

const TaskDrawer: FC<Omit<Props, "children">> = ({
  defaultValues,
  onClose,
  isOpen,
  title,
  staff,
  onSubmit,
  submitLoading,
  onDelete,
  deleteLoading,
  action = "create",
  allAssets,
}) => {
  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen} size={"md"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          {action === "create" ? "Adding" : "Editing"} task for {title}
        </DrawerHeader>
        <DrawerBody>
          <SubtaskAction
            staff={staff}
            allAssets={allAssets}
            assetTitle={title}
            submit={onSubmit}
            submitLoading={submitLoading}
            action={action}
            defaultValues={defaultValues}
            onDelete={onDelete}
            deleteLoading={deleteLoading}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TaskDrawer;
