import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    // fontFamily: "mono", // change the font family
    // color: "red.500", // change the input text color
  },
  addon: {},
  element: {},
});
const outlineVariant = definePartsStyle({
  // define the part you're going to style
  field: {
    border: "1px solid red",
  },
});
const errorVariant = definePartsStyle({
  // define the part you're going to style
  field: {
    border: "1px solid red",
    _focus: {
      boxShadow: "0px 0px 0px 1px red",
    },
  },
});

export const customInputTheme = defineMultiStyleConfig({
  // defaultProps: {},
  baseStyle,
  variants: { outline: outlineVariant, error: errorVariant },
});
